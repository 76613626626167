import React, { useEffect, useState } from 'react'
import inventoryApi from '../api/inventoryApi';
import moment from 'moment';

const PdfGenerator = (props) => {
    let temp = props?.data;
    const [homeCbr, setHomeCbr] = useState({});
    const [cbrWorker, setCbrWorker] = useState({});
    let toggleVar;

    if (temp.servicearea) {
        toggleVar = true;
        console.log('home based Cbr');
    } else {
        toggleVar = false;
        console.log('cbr worker report');
    }

    useEffect(() => {
        if (toggleVar == true) {
            fetchHomeBasedCbr();
        } else {
            fetchCbrWorkerReport();
        }
    }, [props.refresh]);

    const fetchHomeBasedCbr = async () => {
        let result = await inventoryApi.fetchHomeBasedCbrById(temp.id);
        console.log('pdf generator result >>>> ', result.servicearea);
        setHomeCbr(result);
    }

    const fetchCbrWorkerReport = async () => {
        let result = await inventoryApi.fetchCbrWorkerReportById(temp.id);
        console.log('pdf generator result >>>> ', result.cbrfieldarea);
        setCbrWorker(result);
    }


    return (
        <div>
            {toggleVar === true ?
                (
                    <div
                        className="d-flex justify-content-center border"
                        style={{ fontSize: "10px" }}
                    >
                        <div className="App container mt-5" >
                            <html>
                                <head>
                                    <meta charset="UTF-8" />
                                </head>
                                <body>
                                    <table style={{ width: "100%" }}>
                                        <tr>
                                            <td style={{ border: "None", textAlign: "center" }}>
                                                <h6>Home Based CBR</h6>
                                            </td>
                                        </tr>
                                    </table>

                                    {/* .......................................Upper Form............................................ */}
                                    <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
                                        <tr>
                                            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
                                                Service Area : {homeCbr.servicearea}
                                            </td>
                                            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                Child's Name : {homeCbr.name}
                                            </td>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                Child's Age : {homeCbr.age}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                C.B.R. Worker : {homeCbr.cbrworker}
                                            </td>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                Time : {homeCbr.time}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                Date : {homeCbr.date}
                                            </td>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                Day : {homeCbr.day}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                Current extension of the child during the program : {homeCbr.chidextension}
                                            </td>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                            </td>
                                        </tr>
                                    </table>
                                    {/* .........................................8 Item.................................................. */}

                                    <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
                                        <thead>
                                            <tr style={{ border: "1px solid gray", height: "30px" }}>
                                                <th>According to target area</th>
                                                <th>Work done by C.B.R. worker</th>
                                                <th>Materials used</th>
                                                <th>Instructions for Parents</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    <b>Personal :</b>
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.personalwork_1}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.personalmaterials_1}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.personalinstructions}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.personalwork_2}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.personalmaterials_2}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.personalwork_3}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                    {homeCbr.personalmaterials_3}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.personalwork_4}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.personalmaterials_4}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.personalwork_5}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.personalmaterials_5}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.personalwork_6}

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.personalmaterials_6}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.personalwork_7}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.personalmaterials_7}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.personalwork_8}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.personalmaterials_8}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.personalwork_9}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.personalmaterials_9}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "5px solid gray",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.personalwork_10}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.personalmaterials_10}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>

                                            <tr style={{ marginTop: "2px" }}>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    <b>Social :</b>
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialwork_1}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialmaterials_1}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialinstructions}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialwork_2}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialmaterials_2}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialwork_3}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialmaterials_3}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialwork_4}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialmaterials_4}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialwork_5}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialmaterials_5}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialwork_6}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialmaterials_6}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialwork_7}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialmaterials_7}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialwork_8}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialmaterials_8}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialwork_9}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialmaterials_9}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "5px solid gray",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialwork_10}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.socialmaterials_10}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    <b>Business :</b>
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businesswork_1}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businessmaterials_1}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businessinstructions}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businesswork_2}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businessmaterials_2}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businesswork_3}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businessmaterials_3}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businesswork_4}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businessmaterials_4}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businesswork_5}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businessmaterials_5}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businesswork_6}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businessmaterials_6}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businesswork_7}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businessmaterials_7}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businesswork_8}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businessmaterials_8}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businesswork_9}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businessmaterials_9}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "5px solid gray",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businesswork_10}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.businessmaterials_10}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>

                                            <tr style={{ marginTop: "2px" }}>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    <b>Educational :</b>
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalwork_1}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalmaterials_1}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalinstructions}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalwork_2}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalmaterials_2}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalwork_3}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalmaterials_3}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalwork_4}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalmaterials_4}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalwork_5}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalmaterials_5}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalwork_6}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalmaterials_6}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalwork_7}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalmaterials_7}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalwork_8}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalmaterials_8}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalwork_9}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalmaterials_9}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "5px solid gray",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalwork_10}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.educationalmaterials_10}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    <b>Entertainment :</b>
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentwork_1}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentmaterials_1}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentinstructions}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentwork_2}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentmaterials_2}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentwork_3}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentmaterials_3}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentwork_4}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentmaterials_4}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentwork_5}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentmaterials_5}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentwork_6}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentmaterials_6}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentwork_7}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentmaterials_7}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentwork_8}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentmaterials_8}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentwork_9}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentmaterials_9}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "5px solid gray",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentwork_10}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.entertainmentmaterials_10}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>

                                            <tr style={{ marginTop: "2px" }}>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    <b>Community Inclusion :</b>
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusionwork_1}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusionmaterials_1}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusioninstructions}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusionwork_2}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                    {homeCbr.communityinclusionmaterials_2}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusionwork_3}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusionmaterials_3}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusionwork_4}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusionmaterials_4}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusionwork_5}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusionmaterials_5}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusionwork_6}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusionmaterials_6}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusionwork_7}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusionmaterials_7}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusionwork_8}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusionmaterials_8}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusionwork_9}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusionmaterials_9}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "5px solid gray",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusionwork_10}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusionmaterials_10}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    <b>Language and communication :</b>
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.languagecommunicationwork_1}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.languagecommunicationmaterials_1}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.languagecommunicationinstructions}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.languagecommunicationwork_2}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.languagecommunicationmaterials_2}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.languagecommunicationwork_3}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.languagecommunicationmaterials_3}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.languagecommunicationwork_4}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.languagecommunicationmaterials_4}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.languagecommunicationwork_5}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.languagecommunicationmaterials_5}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.communityinclusionmaterials_6}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.languagecommunicationmaterials_6}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.languagecommunicationwork_7}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.languagecommunicationmaterials_7}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.languagecommunicationwork_8}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.languagecommunicationmaterials_8}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.languagecommunicationwork_9}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.languagecommunicationmaterials_9}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "5px solid gray",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.languagecommunicationwork_10}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.languagecommunicationmaterials_10}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>

                                            <tr style={{ marginTop: "2px" }}>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    <b>Visitor :</b>
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitorwork_1}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitormaterials_1}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitorinstructions}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitorwork_2}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitormaterials_2}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitorwork_3}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitormaterials_3}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitorwork_4}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitormaterials_4}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitorwork_5}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitormaterials_5}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitorwork_6}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitormaterials_6}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitorwork_7}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitormaterials_7}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitorwork_8}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitormaterials_8}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitorwork_9}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitormaterials_9}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    borderLeft: "5px solid gray", borderTop: "None", borderBottom: "5px solid gray",
                                                    height: "30px"
                                                }}>

                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitorwork_10}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>
                                                    {homeCbr.visitormaterials_10}
                                                </td>
                                                <td style={{ border: "1px solid gray", height: "30px" }}>

                                                </td>
                                            </tr>


                                        </tbody>
                                    </table>
                                    {/* ...........................................Footer Form..................................................... */}
                                    <table style={{ width: "100%", fontSize: "10px", justifyContent: "center" }}>
                                        <tr>
                                            <td style={{ border: "1px solid gray", height: "30px", width: "33%" }}>
                                                Next Program : {homeCbr.nextprogram}
                                            </td>
                                            <td style={{ border: "1px solid gray", height: "30px", width: "33%" }}>
                                                Guardian Signature : {homeCbr.guardiansign}
                                            </td>
                                            <td style={{ border: "1px solid gray", height: "30px", width: "33%" }}>
                                                Signature C.B.R. Worker : {homeCbr.cbrworkersign}
                                            </td>
                                        </tr>
                                    </table>
                                </body>
                            </html>
                        </div>
                    </div>
                )
                :
                (
                    <div className="App container mt-5">
                        <html>

                            <head>
                                <meta charset="UTF-8" />
                            </head>

                            <body>
                                <table style={{ width: "100%" }}>
                                    <tr>
                                        <td style={{ border: "None", textAlign: "center" }}>
                                            <h6>CBR Worker Report</h6>
                                        </td>
                                    </tr>
                                </table>
                                {/* ...........................................Upper Form................................... */}
                                <table style={{ width: "100%", fontSize: "10px", justifyContent: "center" }}>
                                    <tr>
                                        <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
                                            Name of CBR Worker : {cbrWorker.name}
                                        </td>
                                        <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
                                            Name Of Project : {cbrWorker.projectname}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            DOB : {moment(cbrWorker.cbrdate).format("YYYY-MM-DD")}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            CBR Filed Area : {cbrWorker.cbrfieldarea}
                                        </td>
                                    </tr>
                                </table>
                                {/* ..............................................10 Items Form...................................... */}
                                <table style={{ width: "100%", fontSize: "10px", justifyContent: "center" }}>
                                    <tr>
                                        <td><b>1. Children Enrollement</b></td>
                                    </tr>
                                </table>
                                <table style={{ width: "100%", fontSize: "10px", justifyContent: "center" }}>

                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>1.1 Total No. of CBR children</th>
                                            <th>1.2 MI</th>
                                            <th>1.3 LI</th>
                                            <th>1.4 Speech Program</th>
                                            <th>1.5 Physio Program</th>
                                            <th>1.6 Phycho Program</th>
                                            <th>1.7 Vocational Training</th>
                                            <th>1.8 Education</th>
                                            <th>1.9 LiveliHood</th>
                                            <th>1.10 Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                <b>Male :</b>
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_1m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_2m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_3m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_4m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_5m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_6m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_7m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_8m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_9m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_10m}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                <b>Female :</b>
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_1f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_2f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_3f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_4f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_5f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_6f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_7f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_8f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_9f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_10f}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                <b>Total :</b>
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_1t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_2t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_3t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_4t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_5t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_6t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_7t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_8t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_9t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._1_10t}
                                            </td>
                                        </tr>
                                        {/* <br></br> */}
                                    </tbody>
                                </table>
                                {/* .......................2............. */}
                                <table style={{ width: "100%", fontSize: "10px", justifyContent: "center" }}>
                                    <tr>
                                        <td><b>2. Disability Wise Break Up</b></td>
                                    </tr>
                                </table>
                                <table style={{ width: "100%", fontSize: "10px", justifyContent: "center" }}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>2.1 ID</th>
                                            <th>2.2 CP</th>
                                            <th>2.3 Autistic</th>
                                            <th>2.4 VI</th>
                                            <th>2.5 HI</th>
                                            <th>2.6 MD</th>
                                            <th>2.7 DB</th>
                                            <th>2.8 LD</th>
                                            <th>2.9 PH</th>
                                            <th>2.10 Other</th>
                                            <th>2.11 Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                <b>Male :</b>
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_1m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_2m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_3m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_4m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_5m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_6m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_7m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_8m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_9m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_10m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_11m}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                <b>Female :</b>
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_1f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_2f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_3f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_4f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_5f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_6f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_7f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_8f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_9f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_10f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_11f}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                <b>Total :</b>
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_1t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_2t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_3t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_4t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_5t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_6t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_7t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_8t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_9t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_10t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._2_11t}
                                            </td>
                                        </tr>
                                        <br></br>
                                    </tbody>
                                </table>
                                {/* .......................3............. */}
                                <table style={{ width: "100%", fontSize: "10px", justifyContent: "center" }}>
                                    <tr>
                                        <td><b>3. Social Security details till month</b></td>
                                    </tr>
                                </table>
                                <table style={{ fontSize: "10px", justifyContent: "center" }}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>3.1 Total Enrollement</th>
                                            <th>3.2 Disability Certificate</th>
                                            <th>3.3 Rail Pass</th>
                                            <th>3.4 Buss Pass</th>
                                            <th>3.5 UID Card</th>
                                            <th>3.6 Adhar Card</th>
                                            <th>3.7 Pention Start/Submission</th>
                                            <th>3.8 Nirmaya</th>
                                            <th>3.9 Other Specific</th>
                                            <th>3.10 Other Specify</th>
                                            <th>3.11 Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                <b>Male :</b>
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_1m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_2m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_3m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_4m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_5m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_6m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_7m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_8m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_9m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_10m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_11m}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                <b>Female :</b>
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_1f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_2f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_3f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_4f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_5f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_6f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_7f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_8f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_9f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_10f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_11f}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                <b>Total :</b>
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_1t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_2t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_3t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_4t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_5t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_6t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_7t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_8t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_9t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_10t}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._3_11t}
                                            </td>
                                        </tr>
                                        <br></br>
                                    </tbody>
                                </table>
                                {/* .......................4............. */}
                                <table style={{ width: "100%", fontSize: "10px", justifyContent: "center" }}>
                                    <tr>
                                        <td><b>4. Services to the children / Adults</b></td>
                                    </tr>
                                </table>
                                <table style={{ fontSize: "10px", justifyContent: "center" }}>
                                    <thead>
                                        <tr>
                                            <th>4.1 Total no.of planned services</th>
                                            <th>4.2 Actual No. of services</th>
                                            <th>4.3 Total no. of children given 1 visit in the month</th>
                                            <th>4.4 Total no.of children given 2 visit in the month</th>
                                            <th>
                                                4.5 Total no. of children given more than 2 visit in the month
                                            </th>
                                            <th>
                                                4.6 Total no. of children who did not get any services during the
                                                month
                                            </th>
                                            <th>
                                                4.7 Physisotherepist visit/no. of children visited during the
                                                month
                                            </th>
                                            <th>
                                                4.8 Speech teacher visits/no. of children visited during the month
                                            </th>
                                            <th>
                                                4.9 Psychologist visits/no. of children visited during the month
                                            </th>
                                            <th>4.10 Visit by any other officers/guest attach visit report</th>
                                            <th>4.11 Remark</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._4_1}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._4_2}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._4_3}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._4_4}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._4_5}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._4_6}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._4_7}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._4_8}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._4_9}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._4_10}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._4_11}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* .......................5............. */}
                                <table style={{ width: "100%", fontSize: "10px", justifyContent: "center" }}>
                                    <tr>
                                        <td><b>5.Stack holder contact during the month for support CBR Children</b></td>
                                    </tr>
                                </table>
                                <table style={{ fontSize: "10px", justifyContent: "center" }}>
                                    <thead>
                                        <tr>
                                            <th>5.1 AWW</th>
                                            <th>5.2 ANW</th>
                                            <th>5.3 School Teachers</th>
                                            <th>5.4 PRIs/ward parshad</th>
                                            <th>5.5 Doctors</th>
                                            <th>5.6 Employers</th>
                                            <th>5.7 Other (Specify)</th>
                                            <th>5.8 Other (Specify)</th>
                                            <th>5.9 Other (Specify)</th>
                                            <th>5.10 Other (Specify)</th>
                                            <th>5.11 Remark</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._5_1}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._5_2}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._5_3}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._5_4}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._5_5}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._5_6}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._5_7}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._5_8}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._5_9}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._5_10}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._5_11}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* .......................6............. */}
                                <table style={{ width: "100%", fontSize: "10px", justifyContent: "center" }}>
                                    <tr>
                                        <td><b>6.Assessment Done</b></td>
                                    </tr>
                                </table>
                                <table style={{ fontSize: "10px", width: "100%", justifyContent: "center" }}>
                                    <thead>
                                        <tr >
                                            <th style={{ fontSize: "10px", justifyContent: "center" }}>6.1 FACP</th>
                                            <th style={{ fontSize: "10px", justifyContent: "center" }}>6.2 Physio</th>
                                            <th style={{ fontSize: "10px", justifyContent: "center" }}>6.3 Speech</th>
                                            <th style={{ fontSize: "10px", justifyContent: "center" }}>6.4 Psycho</th>
                                            <th style={{ fontSize: "10px", justifyContent: "center" }}> 6.5 Vision</th>
                                            <th style={{ fontSize: "10px", justifyContent: "center" }}>6.6 Hearing</th>
                                        </tr>
                                    </thead>
                                </table>
                                <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginTop: "6px" }}>
                                    <thead>
                                        <tr >
                                            <th>Target</th>
                                            <th>Done</th>
                                            <th>Target</th>
                                            <th>Done</th>
                                            <th>Target</th>
                                            <th>Done</th>
                                            <th>Target</th>
                                            <th>Done</th>
                                            <th>Target</th>
                                            <th>Done</th>
                                            <th>Target</th>
                                            <th>Done</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>{cbrWorker._6_1t}</td>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>{cbrWorker._6_1d}</td>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>{cbrWorker._6_2t}</td>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>{cbrWorker._6_2d}</td>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>{cbrWorker._6_3t}</td>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>{cbrWorker._6_3d}</td>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>{cbrWorker._6_4t}</td>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>{cbrWorker._6_4d}</td>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>{cbrWorker._6_5t}</td>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>{cbrWorker._6_5d}</td>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>{cbrWorker._6_6t}</td>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>{cbrWorker._6_6d}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* ...............7.............. */}
                                <table style={{ width: "100%", fontSize: "10px", justifyContent: "center" }}>
                                    <tr>
                                        <td><b>7. New Identification during the month</b></td>
                                    </tr>
                                </table>
                                <table style={{ fontSize: "10px",  justifyContent: "center" }}>
                                    <thead>
                                        <tr>
                                            <th>7.1 Name</th>
                                            <th>7.2 Father Name</th>
                                            <th>7.3 Age</th>
                                            <th>7.4 Sex</th>
                                            <th>7.5 DOB</th>
                                            <th>7.6 Disability</th>
                                            <th>7.7 Address</th>
                                            <th>7.8 Date of Identification</th>
                                            <th>7.9 Contact Number</th>
                                            <th>7.10 Adhar No.</th>
                                            <th>7.11 Remark</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_1_1}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_2_1}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_3_1}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_4_1}
                                            </td>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                {cbrWorker._7_5_1}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_6_1}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_7_1}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_8_1}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_9_1}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_10_1}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_11_1}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_1_2}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_2_2}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_3_2}
                                            </td>

                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_4_2}
                                            </td>


                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                {cbrWorker._7_5_2}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_6_2}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_7_2}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_8_2}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_9_2}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_10_2}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_11_2}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_1_3}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_2_3}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_3_3}
                                            </td>

                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_4_3}
                                            </td>


                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                {cbrWorker._7_5_3}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_6_3}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_7_3}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_8_3}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_9_3}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_10_3}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_11_3}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_1_4}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_2_4}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_3_4}
                                            </td>

                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_4_4}
                                            </td>


                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                {cbrWorker._7_5_4}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_6_4}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_7_4}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_8_4}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_9_4}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_10_4}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_11_4}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_1_5}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_2_5}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_3_5}
                                            </td>

                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_4_5}
                                            </td>


                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                {cbrWorker._7_5_5}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_6_5}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_7_5}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_8_5}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_9_5}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_10_5}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_11_5}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_1_6}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_2_6}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_3_6}
                                            </td>

                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_4_6}
                                            </td>


                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                {cbrWorker._7_5_6}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_6_6}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_7_6}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_8_6}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_9_6}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_10_6}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_11_6}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_1_7}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_2_7}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_3_7}
                                            </td>

                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_4_7}
                                            </td>


                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                {cbrWorker._7_5_7}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_6_7}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_7_7}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_8_7}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_9_7}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_10_7}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_11_7}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_1_8}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_2_8}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_3_8}
                                            </td>

                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_4_8}
                                            </td>


                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                {cbrWorker._7_5_8}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_6_8}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_7_8}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_8_8}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_9_8}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_10_8}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_11_8}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_1_9}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_2_9}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_3_9}
                                            </td>

                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_4_9}
                                            </td>


                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                {cbrWorker._7_5_9}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_6_9}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_7_9}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_8_9}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_9_9}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_10_9}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_11_9}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_1_10}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_2_10}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_3_10}
                                            </td>

                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_4_10}
                                            </td>


                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                {cbrWorker._7_5_10}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_6_10}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_7_10}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_8_10}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_9_10}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_10_10}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._7_11_10}
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                                {/* ...............8.............. */}
                                <table style={{ width: "100%", fontSize: "10px", justifyContent: "center" }}>
                                    <tr>
                                        <td><b>8.Drop Out Detail</b></td>
                                    </tr>
                                </table>
                                <table style={{ fontSize: "10px", justifyContent: "center" }}>
                                    <thead>
                                        <tr>
                                            <th>8.1 Name</th>
                                            <th>8.2 Father Name</th>
                                            <th>8.3 Age</th>
                                            <th>8.4 Sex</th>
                                            <th>8.5 DOB</th>
                                            <th>8.6 Disability</th>
                                            <th>8.7 UID</th>
                                            <th>8.8 Date of Dropout</th>
                                            <th>8.9 Reason of Dropout</th>
                                            <th>8.10 Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_1_1}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_2_1}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_3_1}
                                            </td>

                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_4_1}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_5_1}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_6_1}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_7_1}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_8_1}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_9_1}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_10_1}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_1_2}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_2_2}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_3_2}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_4_2}
                                            </td>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                {cbrWorker._8_5_2}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_6_2}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_7_2}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_8_2}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_9_2}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_10_2}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_1_3}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_2_3}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_3_3}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_4_3}
                                            </td>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                {cbrWorker._8_5_3}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_6_3}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_7_3}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_8_3}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_9_3}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_10_3}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_1_4}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_2_4}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_3_4}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_4_4}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_5_4}
                                            </td>

                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                {cbrWorker._8_6_4}
                                            </td>

                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_7_4}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_8_4}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_9_4}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_10_4}
                                            </td>
                                          
                                        </tr>

                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_1_5}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_2_5}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_3_5}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_4_5}
                                            </td>
                                            <td style={{ border: "1px solid gray", height: "30px" }}>
                                                {cbrWorker._8_5_5}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_6_5}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_7_5}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_8_5}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_9_5}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._8_10_5}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* ...............9.............. */}
                                <table style={{ width: "100%", fontSize: "10px", justifyContent: "center" }}>
                                    <tr>
                                        <td><b>9. Detail of assisstive Device</b></td>
                                    </tr>
                                </table>
                                <table style={{ fontSize: "10px", justifyContent: "center" }}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>9.1 Wheel Chair</th>
                                            <th>9.2 Calipers</th>
                                            <th>9.3 Hearing Aids</th>
                                            <th>9.4 Tri Cycle</th>
                                            <th>9.5 Hang gripper</th>
                                            <th>9.6 Neck Belt</th>
                                            <th>9.7 Soft Ball</th>
                                            <th>9.8 Any other (Specify)</th>
                                            <th>9.9 Any other (Specify)</th>
                                            <th>9.10 Any other (Specify)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                <b>Male :</b>
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_1m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_2m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_3m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_4m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_5m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_6m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_7m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_8m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_9m}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_10m}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                <b>Female :</b>
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_1f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_2f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_3f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_4f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_5f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_6f}
                                            </td>

                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_7f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_8f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_9f}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_10f}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                <b>Supported By</b>
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_1s}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_2s}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_3s}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_4s}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_5s}
                                            </td>

                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_6s}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_7s}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_8s}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_9s}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._9_10s}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* ...............10.............. */}
                                <table style={{ width: "100%", fontSize: "10px", justifyContent: "center" }}>
                                    <tr>
                                        <td><b>10. Travel Detail</b></td>
                                    </tr>
                                </table>
                                <table style={{ fontSize: "10px", justifyContent: "center" }}>
                                    <thead>
                                        <tr>
                                            <th>10.1 Total KM travelled in the last month</th>
                                            <th>10.2 Total Petrol used in last month</th>
                                            <th>10.3 Average KM</th>
                                            <th>10.4 Total KM travelled in current month</th>
                                            <th>10.5 Total Petrol used in current month</th>
                                            <th>10.6 Average/KM</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._10_1}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._10_2}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._10_3}
                                            </td>

                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._10_4}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._10_5}
                                            </td>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._10_6}
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                                {/* ...............11.............. */}
                                <table style={{ fontSize: "10px", justifyContent: "center", width: "100%" }}>
                                    <thead>
                                        <th>
                                            11. Donation/Community contribution raised during the month
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._11}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* ...............12.............. */}
                                <table style={{ fontSize: "10px", justifyContent: "center", width: "100%" }}>
                                    <thead>
                                        <th>
                                            12. Any Problem/Challenge occured during the month
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._12}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* ...............13.............. */}
                                <table style={{ fontSize: "10px", justifyContent: "center", width: "100%" }}>
                                    <thead>
                                        <th>
                                            13.Strategy used to resolve the problem/Challenge
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._13}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* ...............14.............. */}
                                <table style={{ fontSize: "10px", justifyContent: "center", width: "100%" }}>
                                    <thead>
                                        <th>
                                            14.Achievement During the month
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ border: "1px solid gray", height: "30px" }}
                                            >
                                                {cbrWorker._14}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* .......................................Upper Form............................................ */}
                                <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            Signature of the Incharge : {cbrWorker.signofincharge}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            Signature of Speech pathologist/Teacher : {cbrWorker.signofcbrworker}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            Name of Incharge : {cbrWorker.inchargename}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            Date : {cbrWorker.date}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            Note. Report Should be Submitted by the last day of every month : {cbrWorker.note}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            Contact No. : {cbrWorker.contactno}
                                        </td>
                                    </tr>
                                </table>
                            </body>

                        </html>
                    </div>
                )
            }

        </div >
    )
}

export default PdfGenerator;
