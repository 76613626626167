import React, { useEffect, useState } from "react";
import inventoryApi from "../api/inventoryApi";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { name } from "pubsub-js";
import vsmsData from "../constants/VSMS_Score.json";
 


export default function WorkaviourPdf() {
    const [workInfo, setWorkInfo] = useState({});
  return (
    <div>
      
      <table style={{ width: "100%" }}>
        <tr>
          <td style={{ border: "None", textAlign: "center" }}>
            {/* <h5>{studentInfo["servicecategoryname"]}</h5>
            <h6>{studentInfo.location}</h6> */}
            <h6>Rajasthan </h6>
          </td>
        </tr>
      </table>
      <hr />

      <table style={{ width: "100%" }}>
        <tr>
          <td style={{ border: "None", textAlign: "center" }}>
            <h6>Work Behaviour Report </h6>
          </td>
        </tr>
      </table>
      <hr />
      <table>
       
        <tr>
        <td style={{ width: "50px", border: "None" }}></td>
          <td style={{   border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b>Areas</b></h4>
          </td>
          <td style={{ width: "250px", border: "None" }}></td>

          <td style={{  border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b>Total</b></h4>
          </td>
          <td style={{ width: "120px", border: "None" }}></td>

          <td style={{  border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b>Date</b></h4>
          </td>
          <td style={{ width: "200px", border: "None" }}></td>
        </tr>
          
      </table>

      <table style={{ fontSize: "15px" }}>
        <tr>
          <td style={{width: "50%", border: "1px solid gray",height: "30px" }}>Physical appearance  </td>
          <td style={{width: "25%", border: "1px solid gray",height: "30px" }}>10*3   </td>
          <td style={{width: "25%", border: "1px solid gray",height: "30px" }}>Value : {workInfo.picklist} </td>
        </tr>
        <tr>
          <td style={{width: "50%", border: "1px solid gray",height: "30px" }}>Personal interaction  </td>
          <td style={{width: "25%", border: "1px solid gray",height: "30px" }}>10*3   </td>
          <td style={{width: "25%", border: "1px solid gray",height: "30px" }}>Value : {workInfo.picklist} </td>
        </tr>
        <tr>
          <td style={{width: "50%", border: "1px solid gray",height: "30px" }}>Regularity & Punctuality  </td>
          <td style={{width: "25%", border: "1px solid gray",height: "30px" }}>10*3   </td>
          <td style={{width: "25%", border: "1px solid gray",height: "30px" }}>Value : {workInfo.picklist} </td>
        </tr>
        <tr>
          <td style={{width: "50%", border: "1px solid gray",height: "30px" }}>Communicational/spcial mannrs  </td>
          <td style={{width: "25%", border: "1px solid gray",height: "30px" }}>10*3   </td>
          <td style={{width: "25%", border: "1px solid gray",height: "30px" }}>Value : {workInfo.picklist} </td>
        </tr>
        <tr>
          <td style={{width: "50%", border: "1px solid gray",height: "30px" }}>Quality & Quantity aspects </td>
          <td style={{width: "25%", border: "1px solid gray",height: "30px" }}>10*3   </td>
          <td style={{width: "25%", border: "1px solid gray",height: "30px" }}>Value : {workInfo.picklist} </td>
        </tr>
      </table>

    </div>
  )
}

