import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import * as constants from "../constants/CONSTANT";

const StudentAssessmentEdit = (prop) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [staffMember, setStaffMember] = useState([]);

  const [assessment, setAssessment] = useState(
    location.state ? location.state : {}
  );
  const [isEditable, setIsEditable] = useState(true);
  const [studentassessment, setStudentassessment] = useState({});

  console.log(assessment);
  const [checkedBoxes, setCheckedBoxes] = useState([]);

  let requireddocumentsArray;
  let checkboxes;
  let isChecked = true;


  useEffect(() => {
    async function init() {
      const result = await inventoryApi.fetchStaffMember();
      console.log(result)
      if (result) {
        setStaffMember(result);

      } else {
        setStaffMember([]);

      }
    }
    init();


  }, []);

  useEffect(() => {
    if (assessment?.studentid) {
      setStudentassessment(assessment);
      if (assessment.requireddocuments != null) {
        console.log("========= On update: use effect ===========");
        requireddocumentsArray = assessment.requireddocuments.split(",");
        console.log("requireddocumentsArray===>>>", requireddocumentsArray);

        checkboxes = document.querySelectorAll('input[type="checkbox"]');
        console.log("checkboxes===>>>", checkboxes);

        checkboxes.forEach((checkbox) => {
          console.log('checkbox.value===>>>', checkbox.value);
          if (requireddocumentsArray.includes(checkbox.value)) {
            console.log('=====***********************=====');
            checkbox.checked = true;
            setCheckedBoxes((prevState) => [...prevState, checkbox.value]);
            //setStudentassessment(assessment);
          }
        });
        //       setStudentassessment(assessment);
      }
    }
  }, []);

  const handleChange = (e) => {
    console.log("is checked", e.target.checked);
    if (e.target.checked) {
      console.log("========Checked=========");
      setCheckedBoxes((prevState) => [...prevState, e.target.value]);
      return;
    } else if (e.target.checked == false) {
      console.log("========Un-checked=========");
      setCheckedBoxes((prevState) => prevState.filter((box) => box !== e.target.value));
      return;
    }
    console.log('===================dadasad==========');
    setStudentassessment({ ...studentassessment, [e.target.name]: e.target.value });
    console.log(studentassessment);
  };

  const handleSubmit = async (e) => {
    console.log(checkedBoxes.join(","));


    studentassessment.requireddocuments = checkedBoxes.join(",");
    console.log("state enter in handle submit ", assessment);
    e.preventDefault();
    let result = {};
    if (studentassessment.id) {
      result = await inventoryApi.savePreAssessment(studentassessment);
      navigate(`/students/${studentassessment.studentid}`, {
        state: studentassessment,
      });
    } else {
      delete studentassessment.success;
      delete studentassessment.message;
      studentassessment.studentid = assessment.id;
      result = await inventoryApi.createPreAssessment(studentassessment);
      if (result) {
        navigate(`/students/${result.studentid}`, { state: result });
      }
    }
  };

  const handleCancel = (e) => {
    if (studentassessment.id) {
      navigate(`/students/${studentassessment.studentid}`, { state: studentassessment });
    } else {
      navigate(`/students/${assessment.id}`, { state: assessment });
    }

  };

  return (
    <div>
      <Container>
        <Row className="view-form-header align-items-center mx-2">
          <Col lg={3}>
            <h5>Edit Assessment Info</h5>
          </Col>
          <Col lg={9} className="d-flex justify-content-end">
            {isEditable && <Button className="btn-sm mx-2" onClick={handleSubmit}>
              Save
            </Button>}
            <Button className="btn-sm" variant="danger" onClick={handleCancel}>
              Cancel
            </Button>
          </Col>
        </Row>
        <fieldset disabled={!isEditable}>
          <div className="assessments">
            <Row >
              <Col
                lg={8}
                className="section-header mx-4 my-3"
                style={{ width: "94%" }}
              >
                Form Part D Assessment
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label className="form-view-label form-label my-2">
                    Motor (Fine Motor/Gross Motor)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="motor"
                    value={studentassessment.motor}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label className="form-view-label my-2">
                    Self Help(ADL)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="selfhelp"
                    value={studentassessment.selfhelp}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label className="form-view-label form-label">
                    Communication (Verbal / Non Verbal)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="communication"
                    value={studentassessment.communication}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label className="form-view-label">Academics</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="academics"
                    value={studentassessment.academics}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label className="form-view-label form-label">
                    Socialization
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="socialization"
                    value={studentassessment.socialization}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label className="form-view-label">
                    Education Status
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="educationstatus"
                    value={studentassessment.educationstatus}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label className="form-view-label form-label">
                    Vocational Status
                  </Form.Label>
                  <Form.Control
                   
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label className="form-view-label form-label">
                    Co- Mobility
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="comobality"
                    value={studentassessment.comobality}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label className="form-view-label">
                    Primary Diagnosis
                  </Form.Label>
                  <Form.Select name="primarydiagnosis" onChange={handleChange} value={studentassessment.primarydiagnosis}>
                    <option value="">-- Select --</option>
                    <option value="Blindness">Blindness</option>
                    <option value="Low-vision">Low-vision</option>
                    <option value="Leprosy Cured Persons">Leprosy Cured Persons</option>
                    <option value="Hearing Impairment">Hearing Impairment</option>
                    <option value="Locomotor Disability">Locomotor Disability</option>
                    <option value="Mental Illness">Mental Illness</option>
                    <option value="Autism Spectrum Disorder">Autism Spectrum Disorder</option>
                    <option value="Cerebral Palsy">Cerebral Palsy</option>
                    <option value="Muscular Dystrophy">Muscular Dystrophy</option>
                    <option value="Chronic Neurological Conditions">Chronic Neurological Conditions</option>
                    <option value="Specific Learning Disabilities">Specific Learning Disabilities</option>
                    <option value="Multiple Sclerosis">Multiple Sclerosis</option>
                    <option value="Speech and Language Disability">Speech and Language Disability</option>
                    <option value="Thalassemia">Thalassemia</option>
                    <option value="Hemophilia">Hemophilia</option>
                    <option value="Sickle Cell Disease">Sickle Cell Disease</option>
                    <option value="Multiple Disabilities including Deaf-blindness">Multiple Disabilities including Deaf-blindness</option>
                    <option value="Acid Attack Victims">Acid Attack Victims</option>
                    <option value="Parkinson's disease">Parkinson's Disease</option>
                    <option value="Intellectual Disability">Intellectual Disability</option>
                    <option value="Hard of hearing">Hard of hearing</option>


                  </Form.Select>
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3">
                  <Form.Label className="form-view-label">Severity</Form.Label>
                  {/* {
                  // <select
                  //   class="form-select"
                  //   name="severity"
                  //   onChange={handleChange}

                  // >
                  //   <option value=" ">----Select Severity------</option>
                  //   <option value=" ">Severity 1</option>
                  //   <option value=" ">Severity 2</option>
                  // </select>
                } */}
                  <Form.Select name="severity" onChange={handleChange} value={studentassessment.severity}>
                    <option value="">-- Select --</option>
                    <option value="Mild">Mild</option>
                    <option value="Modred">Modred</option>
                    <option value="Sevear">Sevear</option>
                    <option value="Profound">Profound</option>
                  </Form.Select>


                </Form.Group>
              </Col>

              {localStorage.getItem('userrole') === 'ADMIN' || localStorage.getItem('userrole') === 'SUPER_ADMIN' ? (
                <Col lg={6}>
                  <Form.Group className="mx-3">
                    <Form.Label className="form-view-label">Refer To</Form.Label>
                    <Form.Select
                      aria-label="Enter Select Type"
                      name="referto"
                      value={studentassessment.referto}
                      onChange={handleChange}
                    >
                      <option value=''>--Select--</option>
                      {staffMember && staffMember.map((item, index) => (
                        <option value={item.id} key={item.id}>{item.username}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              ) : null}
              <Form.Group className="mx-3">
                <Form.Label className="form-view-label my-3">
                  Document Required
                </Form.Label>

                <Row md={4}>
                  <Col>
                    <Form.Check
                      inline
                      label="Disability Certificate"
                      name="requireddocuments"
                      type="checkbox"
                      value="Disability Certificate"
                      id={`inline-checkbox-1`}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col xs={6}>
                    {" "}
                    <Form.Check
                      inline
                      label="Birth Certificate"
                      name="requireddocuments"
                      type="checkbox"
                      value="Birth Certificate"
                      id={`inline-checkbox-2`}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      inline
                      label="UDID Registation"
                      name="requireddocuments"
                      type="checkbox"
                      value="UDID Registation"
                      id={`inline-checkbox-3`}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      inline
                      label="Adhar Card"
                      name="requireddocuments"
                      type="checkbox"
                      value="Adhar Card"
                      id={`inline-checkbox-4`}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>

                <Row md={4}>
                  <Col>
                    <Form.Check
                      inline
                      label="Pention Status"
                      name="requireddocuments"
                      type="checkbox"
                      value="Pention Status"
                      id={`inline-checkbox-5`}
                      onChange={handleChange}
                    />
                  </Col>

                  <Col>
                    <Form.Check
                      inline
                      label="Astha Card"
                      name="requireddocuments"
                      type="checkbox"
                      value="Astha Card"
                      id={`inline-checkbox-7`}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      inline
                      label="Niramaya Enrollement"
                      name="requireddocuments"
                      type="checkbox"
                      value="Niramaya Enrollement"
                      id={`inline-checkbox-8`}
                      onChange={handleChange}
                    />
                  </Col>

                  <Col>
                    <Form.Check
                      inline
                      label="Current Child Photo"
                      name="requireddocuments"
                      type="checkbox"
                      value="Current Child Photo"
                      id={`inline-checkbox-9`}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>

                <Row md={4}>

                  <Col>
                    <Form.Check
                      inline
                      label="Rail Pass"
                      name="requireddocuments"
                      type="checkbox"
                      value="Rail Pass"
                      id={`inline-checkbox-9`}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      inline
                      label="Bus Pass"
                      name="requireddocuments"
                      type="checkbox"
                      value="Bus Pass"
                      id={`inline-checkbox-9`}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      inline
                      label="Parents Consent Form"
                      name="requireddocuments"
                      type="checkbox"
                      value="Parents Consent Form"
                      id={`inline-checkbox-9`}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col xs={6}>
                    <Form.Check
                      inline
                      label="5-10 Sec.vedio clip of child"
                      name="requireddocuments"
                      type="checkbox"
                      value="5-10 Sec.vedio clip of child"
                      id={`inline-checkbox-10`}
                      onChange={handleChange}
                    />
                  </Col>
                  {/* <p>Checked boxes: {checkedBoxes.join(", ")}</p> */}
                </Row>
              </Form.Group>
            </Row>
          </div>
        </fieldset>
      </Container>
    </div>
  );
};

export default StudentAssessmentEdit;
