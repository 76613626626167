import React, { useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import PubSub from 'pubsub-js';


const ServiceAreaEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // const [show, setShow] = React.useState(false);

  const [serviceArea, setServiceArea] = useState(location.state ? location.state : {});
  const [validated, setValidated] = useState(false);


  const handleCancel = () => {
    navigate("/serviceareas/");
  };

  const handleChange = (e) => {
    console.log(e.target.value)
    setServiceArea({ ...serviceArea, [e.target.name]: e.target.value });
  }

  const handleSubmit = async () => {

    if (serviceArea && serviceArea?.name) {
      setValidated(false);
      if (serviceArea.id) {
        console.log('====edit-====');
        const result = await inventoryApi.saveServiceArea(serviceArea);
        if (result.success) {
          console.log('if result true');
          PubSub.publish('RECORD_SAVED_TOAST', {title: 'Record Saved', message: 'Student record saved successfully'});

          navigate(`/serviceareas/`, { state: serviceArea });
        }
      }
      else {
        // setShow(true);

        const result = await inventoryApi.createServiceArea(serviceArea);
        if (result) {
          PubSub.publish('RECORD_SAVED_TOAST', {title: 'Record Saved', message: 'Student record saved successfully'});
          navigate(`/serviceareas/`, { state: result });
        }
      }
    } else {
      setValidated(true);
    }
  }





return (
  <Container className="view-form">
    <Row>
      <Col></Col>
      <Col lg={8}>
      <Form
            noValidate
            validated={validated}
            className="mt-3"
            onSubmit={handleSubmit}
          >
          <Row className="view-form-header align-items-center">
            <Col lg={3}>

              <>
                Service Area

              </>

            </Col>
            <Col lg={9} className="d-flex justify-content-end">
              <Button className="btn-sm mx-2" onClick={handleSubmit} >
                Save
              </Button>
              <Button
                className="btn-sm"
                variant="danger"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Form.Group className="mx-3" controlId="BasicName">
                <Form.Label
                  className="form-view-label"
                >
                  Name
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="name"
                  placeholder="Enter Service Area"
                  value={serviceArea.name}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                    Please provide a valid name.
                  </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mx-3" controlId="BasicLocation">
                <Form.Label
                  className="form-view-label"
                >
                  Location / City
                </Form.Label>
                <Form.Control
                  
                  type="text"
                  name="location"
                  placeholder="Enter location"
                  value={serviceArea.location}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                    Please provide a valid location.
                  </Form.Control.Feedback>


              </Form.Group>
            </Col>
          </Row>
          <Row>
          </Row>
          <Row>
            <Col lg={6}>
              <Form.Group className="mx-3" controlId="BasicArea">
                <Form.Label
                  className="form-view-label"
                >
                  Service Area
                </Form.Label>
                <Form.Select name="servicearea" onChange={handleChange} value={serviceArea.servicearea} required>
                  <option value="">-- Select --</option>
                  <option value="School">School</option>
                  <option value="CBR">CBR</option>
                  <option value="Day Care">Day Care</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    Please provide a valid service area.
                  </Form.Control.Feedback>


              </Form.Group>
            </Col>

          </Row>
        </Form>
      </Col>
      <Col></Col>
    </Row>
  </Container>
);
};

export default ServiceAreaEdit;
