import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import PubSub from "pubsub-js";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import * as constants from "../constants/CONSTANT";
import CaseHistory from "./CaseHistory";
import WorkaviourPdf from "./WorkaviourPdf";


const WorkerBehaviour = () => {
  const location = useLocation();
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [formEnable, setFormEnable] = useState(true);
  const [workerData, setWorkerData] = useState(location.state ? location.state : {});
  const [editForm, setEditForm] = useState(false);
  const navigate = useNavigate();
  const [editFor, setEditFor] = useState(false);
  const [nameChild, setNameChild] = useState([]);
  const [showDetails, setshowDetails] = useState(false);
  const [sumofsection, setsumofsection] = useState({});
  const [sumofsection2, setsumofsection2] = useState({});
  
  const [total,setTotal] = useState(0);
  
useEffect(() => {
    let total = 0;
    for (let key in sumofsection) {
        total+= + sumofsection[key];
        
    }
    setTotal(total);
}, [sumofsection]);


  useEffect(() => {
    if (location.state === null) {
      setFormEnable(false);
      setEditForm(true);
    }
  }, []);

  useEffect(() => {
    async function init() {
      const result = await inventoryApi.fetchStudents();

      console.log("fetchStudents", result);
      if (result) {
        setNameChild(result);
        //setRegFormList(result);
      } else {
        setNameChild([]);
        //setRegFormList([]);
      }
    }
    init();
  }, []);


  const handleChange = (e) => {
    const { name, value, id } = e.target;
    console.log('target',name, value, id )
        setsumofsection((prevWorkerData) => ({
            ...prevWorkerData,
            [name]: value,
        }))
    
   
}
   
  console.log('sumofsection',sumofsection)

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    console.log("====Outrue======");
    if (workerData.id) {
      console.log("====edit-====");
      const result = await inventoryApi.saveCmhWorkerReport(workerData);
      if (result.errors) {
        setShowError(true);
        setErrorMessage(result.errors);
        return;
      }
      if (result.success) {
        console.log("if result true");
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: " Record update successfully",
        });
        //navigate(`/cbrworkerreport/${workerData.id}`, { state: result });
        setFormEnable(true);
        // setDuplicateWorkerData({id : ''});
        setEditForm(false);
      }
    } else {
      console.log("create call", workerData);
      const result = await inventoryApi.createWorkBehaviour(workerData);
      console.log("result", result);
      if (result) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record create successfully",
        });
       // navigate(`/cbrcmhworkerreports/`, { state: result });
      }
      if (result.errors) {
        setShowError(true);
        setErrorMessage(result.errors);
        return;
      }
    }
  };
  const handleEdit = () => {
    setFormEnable(false);
    // setDuplicateWorkerData({id : ''});
    setEditForm(true);
  };

  const handleCancel = () => {
    navigate(`/cbrcmhworkerreports/`);
  };

  const pdfRow = () => {

    setshowDetails(true)
    //console.log('if pdf row call')
    const pdfTable = document.getElementById("divToPrint");
    //console.log("pdf table", pdfTable);
    var html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize: true,
    });
    //console.log('html', html)
    const documentDefinition = {
      content: html, defaultStyle: {
        font: 'arialunicodems1'
      }
    };
    //console.log('documentDefinition', documentDefinition)
    //pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.fonts = {
      arialunicodems1: {
        normal: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
        bold: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
      },


      // download default Roboto font from cdnjs.com
      Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
      }
    }
    //console.log(pdfMake.vfs)
    pdfMake.createPdf(documentDefinition).open();
  };

  return (
    <Container>
      <Row>
        <Col lg={12} className="mx-4">
          <Form
            className="mt-4"
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
          >
            <Row className="view-form-header align-items-center">
              <Col lg={8}>WORK BEHAVIOUR ASSESMENT CHECKLIST </Col>
              {editForm === false && (
                <Col lg={4} className="d-flex justify-content-end">
                  <Button
                    className="btn-sm mx-2"
                    variant="primary"
                    onClick={handleEdit}
                  >
                    {" "}
                    <i class="fa-regular fa-pen-to-square"></i>{" "}
                  </Button>
                </Col>
              )}
              {editForm === true && (
                <Col lg={4} className="d-flex justify-content-end">
                    <Button
                    className="btn-sm mx-2"
                    type="submit"
                    onClick={() => { pdfRow() }}
                  >
                    {" "}
                    Pdf{" "}
                  </Button>
                  <Button
                    className="btn-sm mx-2"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {" "}
                    Save{" "}
                  </Button>
                  <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={handleCancel}
                  >
                    {" "}
                    Cancel{" "}
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
        </Col>
        <Col />
      </Row>
      <Col lg={12} className="section-header my-4 mx-4">
        1. Physical appearance
      </Col>
      <fieldset disabled={formEnable}>
        <Row lg={12}>
        
          <Col lg={6}>
            <Form.Group className="mx-4 " controlId="formBasicDate">
              <Form.Label className="form-view-label">Date</Form.Label>
              <Form.Control
                type="date"
                required
                name="cmhdate"
                value={moment(workerData.cmhdate).format("YYYY-MM-DD")}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          <Col lg={12} className="my-4 mx-4"></Col>
          <div id="Physical_appearance">
          <Col>
            <table className="table">
              <tbody>
                <tr>
                  <td></td>

                  <td>1.</td>

                  <td>Wears proper dresss</td>

                  <td className="picklist">
                    <Form.Group className="mx-3" controlId="physical_appearance">
                      <Form.Select 
                        className="form-control"
                        name=" physical_appearance_1"
                        onChange={handleChange}
                        value={workerData.physical_appearance_1}
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>2.</td>

                  <td>Dress is washed and pressed</td>

                  <td>
                    <Form.Group className="mx-3" controlId="physical_appearance">
                      <Form.Select
                        className="form-control"
                        name=" physical_appearance_2"
                        onChange={handleChange}
                        value={workerData.physical_appearance_2}
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>3.</td>

                  <td>Hair is combed</td>

                  <td>
                    <Form.Group className="mx-3" controlId="physical_appearance">
                      <Form.Select
                        className="form-control"
                        name="physical_appearance_3"
                        onChange={handleChange}
                        value={workerData.physical_appearance_3 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>4.</td>

                  <td>Shave regularly/maintains mentrual hygiene</td>

                  <td>
                    <Form.Group className="mx-3" controlId="physical_appearance">
                      <Form.Select
                        className="form-control"
                        name="physical_appearance_4"
                        onChange={handleChange}
                        value={workerData.physical_appearance_4}
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>5.</td>

                  <td>Keeps finger nails neatly</td>

                  <td>
                    <Form.Group className="mx-3" controlId="physical_appearance">
                      <Form.Select
                        className="form-control"
                        name="physical_appearance_5"
                        onChange={handleChange}
                        value={workerData.physical_appearance_5 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>6.</td>

                  <td>Takes care of toile needs</td>

                  <td>
                    <Form.Group className="mx-3" controlId="physical_appearance">
                      <Form.Select
                        className="form-control"
                        name="physical_appearance_6"
                        onChange={handleChange}
                        value={workerData.physical_appearance_6 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>7.</td>

                  <td>Takes clean food</td>

                  <td>
                    <Form.Group className="mx-3" controlId="physical_appearance">
                      <Form.Select
                        className="form-control"
                        name="physical_appearance_7"
                        onChange={handleChange}
                        value={workerData.physical_appearance_7 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>8.</td>

                  <td>Follow mealtime manners</td>

                  <td>
                    <Form.Group className="mx-3" controlId="physical_appearance">
                      <Form.Select
                        className="form-control"
                        name="physical_appearance_8"
                        onChange={handleChange}
                        value={workerData.physical_appearance_8 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>9.</td>

                  <td>Seeks assistance while taking medicine</td>

                  <td>
                    <Form.Group className="mx-3" controlId="physical_appearance">
                      <Form.Select
                        className="form-control"
                        name="physical_appearance_9"
                        onChange={handleChange}
                        value={workerData.physical_appearance_9 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>10.</td>

                  <td>Avoid smoking</td>

                  <td>
                    <Form.Group className="mx-3" controlId="physical_appearance">
                      <Form.Select
                     
                        className="form-control"
                        name="physical_appearance_10"
                        onChange={handleChange}
                        value={workerData.physical_appearance_10 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td></td>

                  <td>
                    <b>Total</b>
                  </td>

                  <td>
                    <Form.Group className="mx-3" controlId="formBasicname">
                      <Form.Control
                       disabled
                        type="text"
                        name="name"
                        placeholder="Total data"
                        value={total}
                        //onChange={handleChange}
                      />
                    </Form.Group>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          </div>
          <div id="PersonalIntrection">
          <Col lg={12} className="section-header my-4 mx-4">
            2. Personal interaction
          </Col>
          <Col>
            <table className="table">
              <tbody>
                <tr>
                  <td></td>

                  <td>1.</td>

                  <td>Respects supervisor</td>

                  <td className="picklist">
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="personal_interaction_1"
                        onChange={handleChange}
                        value={workerData.personal_interaction_1 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>

                <tr>
                  <td></td>

                  <td>2.</td>

                  <td>Cooperates with coworkers</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="personal_interaction_2"
                        onChange={handleChange}
                        value={workerData.personal_interaction_2 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>3.</td>

                  <td>Controls emotions</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="personal_interaction_3"
                        onChange={handleChange}
                        value={workerData.personal_interaction_3 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>4.</td>

                  <td>Requests help if necessary</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="personal_interaction_4"
                        onChange={handleChange}
                        value={workerData.personal_interaction_4 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>5.</td>

                  <td>Avoids quarrels</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="personal_interaction_5"
                        onChange={handleChange}
                        value={workerData.personal_interaction_5 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>6.</td>

                  <td>Maintains appropriate sex behaviour</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="personal_interaction_6"
                        onChange={handleChange}
                        value={workerData.personal_interaction_6 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>7.</td>

                  <td>Respects others belongings</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="personal_interaction_7"
                        onChange={handleChange}
                        value={workerData.personal_interaction_7  }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>8.</td>

                  <td>Takes care of personal belongings</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="personal_interaction_8"
                        onChange={handleChange}
                        value={workerData.personal_interaction_8  }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>

                <tr>
                  <td></td>

                  <td></td>

                  <td>
                    <b>Total</b>
                  </td>

                  <td>
                    <Form.Group className="mx-3" controlId="formBasicname">
                      <Form.Control
                       disabled
                        type="text"
                        name="name"
                        placeholder="Total data"
                        value={total}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          </div>
          <div id = 'regularityAndPunchality'>
          <Col lg={12} className="section-header my-4 mx-4">
            3. Regularity and punctuality
          </Col>
          <Col>
            <table className="table">
              <tbody>
                <tr>
                  <td></td>

                  <td>1.</td>

                  <td>Comes to work daily</td>

                  <td className="picklist">
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-regularity_punctuality_1"
                        name="comes_daily "
                        onChange={handleChange}
                        value={workerData.regularity_punctuality_1  }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>2.</td>

                  <td>Reaches work place on time</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="regularity_punctuality_2"
                        onChange={handleChange}
                        value={workerData.regularity_punctuality_2}
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>3.</td>

                  <td>Attends to arrival routines</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="regularity_punctuality_3"
                        onChange={handleChange}
                        value={workerData.regularity_punctuality_3  }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>4.</td>

                  <td>If late. follows job site rules</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="regularity_punctuality_4"
                        onChange={handleChange}
                        value={workerData.regularity_punctuality_4  }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>5.</td>

                  <td>Says politely the reason for late-coming</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="regularity_punctuality_5"
                        onChange={handleChange}
                        value={workerData.regularity_punctuality_5  }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>6.</td>

                  <td>Informs when takes cave</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="regularity_punctuality_6"
                        onChange={handleChange}
                        value={workerData.regularity_punctuality_6  }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>7.</td>

                  <td>Comes back to work place after break</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="regularity_punctuality_7"
                        onChange={handleChange}
                        value={workerData.regularity_punctuality_7  }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>8.</td>

                  <td>Utilizes the break time appropriately</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="regularity_punctuality_8"
                        onChange={handleChange}
                        value={workerData.regularity_punctuality_8 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>9.</td>

                  <td>Continues work till closing time</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="regularity_punctuality_9"
                        onChange={handleChange}
                        value={workerData.regularity_punctuality_9 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>10.</td>

                  <td>Follows the departure routine</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="regularity_punctuality_10"
                        onChange={handleChange}
                        value={workerData.regularity_punctuality_10 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td></td>

                  <td>
                    <b>Total</b>
                  </td>

                  <td>
                    <Form.Group className="mx-3" controlId="formBasicname">
                      <Form.Control
                      disabled
                        type="text"
                        name="name"
                        placeholder="Total data"
                        value={workerData.name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </td>
                </tr>
              </tbody>
            </table>
            
          </Col>
          </div>
          <div id='communicationSocialMannner'>
            
          
          <Col lg={12} className="section-header my-4 mx-4">
            4. Communication/Social Manners
          </Col>
          <Col>
            <table className="table">
              <tbody>
                <tr>
                  <td></td>

                  <td>1.</td>

                  <td>Follows instructions</td>

                  <td className="picklist">
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="social_manner_1"
                        onChange={handleChange}
                        value={workerData.social_manner_1  }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>2.</td>

                  <td>Communicates needs</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="social_manner_2"
                        onChange={handleChange}
                        value={workerData.social_manner_2 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>3.</td>

                  <td>Avoids unnecessary talking</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="social_manner_3"
                        onChange={handleChange}
                        value={workerData.social_manner_3 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>4.</td>

                  <td>Uses telephone when necessary</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="social_manner_4"
                        onChange={handleChange}
                        value={workerData.social_manner_4 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>5.</td>

                  <td>Uses "Sorry, Thank you Please properly</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="social_manner_5"
                        onChange={handleChange}
                        value={workerData.social_manner_5}
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>6.</td>

                  <td>Maintains eye contact while talking</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="social_manner_6"
                        onChange={handleChange}
                        value={workerData.social_manner_6 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>7.</td>

                  <td>Avoids shouting during work</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="social_manner_7"
                        onChange={handleChange}
                        value={workerData.social_manner_7 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>8.</td>

                  <td>Asks relevant questions</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="social_manner_8"
                        onChange={handleChange}
                        value={workerData.social_manner_8 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>9.</td>

                  <td>Avoids unnecessary complaints</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="social_manner_9"
                        onChange={handleChange}
                        value={workerData.social_manner_9 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>10.</td>

                  <td>Accepts corrections</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="social_manner_10"
                        onChange={handleChange}
                        value={workerData.social_manner_10 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>11.</td>

                  <td>Works in a group without disturbing</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="social_manner_11"
                        onChange={handleChange}
                        value={workerData.social_manner_11}
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>12.</td>

                  <td>Joins social activities in the work place</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                      
                        className="form-control"
                        name="social_manner_12"
                        onChange={handleChange}
                        value={workerData.social_manner_12 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td></td>

                  <td>
                    <b>Total</b>
                  </td>

                  <td>
                    <Form.Group className="mx-3" controlId="formBasicname">
                      <Form.Control
                       disabled
                        type="text"
                        name="name"
                        placeholder="Total data"
                        value={workerData.name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          </div>
          <div id= 'qualityAndQuantity'>
          <Col lg={12} className="section-header my-4 mx-4">
            5. Quality and Quantity Aspects of Work
          </Col>
          <Col>
            <table className="table">
              <tbody>
                <tr>
                  <td></td>

                  <td>1.</td>

                  <td>Shows improvement in quality of work</td>

                  <td className="picklist">
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="quality_quantity_1"
                        onChange={handleChange}
                        value={workerData.quality_quantity_1  }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>2.</td>

                  <td>Works satisfactorily</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="quality_quantity_2"
                        onChange={handleChange}
                        value={workerData.quality_quantity_2 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>3.</td>

                  <td>Reports work problems</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="quality_quantity_3"
                        onChange={handleChange}
                        value={workerData.quality_quantity_3}
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>4.</td>

                  <td>Increases speed of work</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="quality_quantity_4"
                        onChange={handleChange}
                        value={workerData.quality_quantity_4 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>5.</td>

                  <td>Uses tools safely</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="quality_quantity_5"
                        onChange={handleChange}
                        value={workerData.quality_quantity_5 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>6.</td>

                  <td>Leaves tools and products in place</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="quality_quantity_6"
                        onChange={handleChange}
                        value={workerData.quality_quantity_6 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>7.</td>

                  <td>Avoids stealing things from work area</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="quality_quantity_7"
                        onChange={handleChange}
                        value={workerData.quality_quantity_7}
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>8.</td>

                  <td>Keeps work area clean</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="quality_quantity_8"
                        onChange={handleChange}
                        value={workerData.quality_quantity_8 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>9.</td>

                  <td>Reports missing/broken items</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="quality_quantity_9"
                        onChange={handleChange}
                        value={workerData.quality_quantity_9 }
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td>10.</td>

                  <td>Continues or stops work as per instruction</td>

                  <td>
                    <Form.Group className="mx-3">
                      <Form.Select
                        className="form-control"
                        name="quality_quantity_10"
                        onChange={handleChange}
                        value={workerData.quality_quantity_10}
                      >
                        <option value="">-- Scorling System --</option>
                        <option value="3">Always</option>
                        <option value="2">Often</option>
                        <option value="1">Rare</option>
                        <option value="0">Never</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td></td>

                  <td></td>

                  <td>
                    <b>Total</b>
                  </td>

                  <td>
                    <Form.Group className="mx-3" controlId="formBasicname">
                      <Form.Control
                      disabled
                        type="text"
                        name="name"
                        placeholder="Total data"
                        value={workerData.name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          </div>
        </Row>
        <div className="App container mt-5" style={{ display: 'none' }}>
            <div id="divToPrint">
              <html>
                <head>
                  <meta charset="UTF-8" />
                </head>
                <body>
                  <WorkaviourPdf data={workerData} />
                </body>
              </html>
            </div>
          </div>
      </fieldset>
    </Container>
  );
};

export default WorkerBehaviour;
