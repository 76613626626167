export const API_BASE_URL = `${window.location.protocol}//${window.location.hostname}/ibs`;
export const SERVER_BASE_URL = `${window.location.protocol}//${window.location.hostname}`;

//export const API_BASE_URL = "http://localhost:3003";
//export const SERVER_BASE_URL = "http://localhost:3000";
//export const API_BASE_URL = "https://api.ibirdsservices.com/ibs"; 
    export const MODIFY_ALL = 'MODIFY_ALL';
    export const VIEW_STUDENT  = 'VIEW_STUDENT';
    export const EDIT_STUDENT  = 'EDIT_STUDENT';
    export const EDIT_PARENT = 'EDIT_PARENT';
    export const EDIT_ASSESMENT = 'EDIT_ASSESMENT';
    export const DELETE_STUDENT  = 'DELETE_STUDENT';
    export const VIEW_STAFF = 'VIEW_STAFF';
    export const EDIT_STAFF = 'EDIT_STAFF'; 
    export const DELETE_STAFF= 'DELETE_STAFF'; 
    export const VIEW_ALL  = 'VIEW_ALL';
    export const VIEW_SERVICE = 'VIEW_SERVICE';
    export const CREATE_SERVICE = 'CREATE_SERVICE';
    export const EDIT_SERVICE  = 'EDIT_SERVICE';
    export const DELETE_SERVICE  = 'DELETE_SERVICE';
    export const VIEW_ADMIN = 'VIEW_ADMIN';
    export const EDIT_ADMIN = 'EDIT_ADMIN';
    export const DELETE_ADMIN = 'DELETE_ADMIN';
    export const VIEW_REPORT = 'VIEW_REPORT';
    export const VIEW_ASSESSMENT = 'VIEW_ASSESSMENT';
    export const EDIT_ASSESSMENT = 'EDIT_ASSESSMENT';
    export const VIEW_QUESTION = 'VIEW_QUESTION';
    export const EDIT_QUESTION = 'EDIT_QUESTION';
    export const VIEW_ATTENDANCE = 'VIEW_ATTENDANCE';
    export const VIEW_IEP = 'VIEW_IEP';
    export const EDIT_IEP = 'EDIT_IEP';
    export const EDIT_SCORE = 'EDIT_SCORE';
//FILE TYPES
export const PDF = 'pdf';
export const DOC = 'doc'; 
export const DOCX = 'docx';
export const XLS = 'xls';
export const XLSX = 'xlsx';
export const CSV = 'csv';
export const PNG = 'png';
export const JPG = 'jpg';
export const JPEG = 'jpeg';
