import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormCheck, Row, Table, Div, Container, Alert } from "react-bootstrap";
import inventoryApi from "../api/inventoryApi";
import { ShimmerTable } from "react-shimmer-effects";
import ListGroup from 'react-bootstrap/ListGroup';

import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PubSub from 'pubsub-js';

import {
    DatatableWrapper,
    TableBody,
    TableHeader

} from 'react-bs-datatable';

const AssignStudents = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [questions, setQuestions] = useState(location.state ? location.state : {});
    console.log('questions enter in assign student', questions)
    const [IEPlist, setIEPlist] = useState([]);
    let [filterObj, setFilterObj] = useState({});
    const [showError, setShowError] = useState(false);


    // console.log(questions)
    const [body, setBody] = useState();
    const [assessmentstudents, setSelectedItems] = useState([]);
    const [next, setnext] = useState(true);
    const [assessmentList, setassessmentList] = useState([]);
    const [StudentIds, setStudentIds] = useState([]);
    const [regFormList, setRegFormList] = useState();

    useEffect(() => {
        //handleSave();
        async function init() {
            //console.log('questions.allstudents:', questions.allstudents);
            if(!questions.assessmentstudents){
            const result = await inventoryApi.fetchStudents();
            console.log('student list' , result);
            let studentActive = result.filter(item => item.status === 'Active');
            if (studentActive) {
                setBody(result);
                setRegFormList(studentActive);
                //questions.allstudents = result;
            } else {
                setBody([]);
                setRegFormList(studentActive);
            }

        }else{
            setBody(questions.allstudents);
            setRegFormList(questions.allstudents);
            setSelectedItems(questions.assessmentstudents);
        }
    }
        init();
        fetchIEP()
        fetchAssessments();
    }, []);

    useEffect(() => {
        doFilterData();
      }, [filterObj]);

      const onFilterSelection = (event) => {
        setFilterObj({...filterObj, [event.target.name] : event.target.value});
    
     }
    
     const doFilterData = (event) => {
        if(body){
      let tempData = body.filter((item) => {
        if(!filterObj.servicearea || filterObj.servicearea === '' || item.servicearea === filterObj.servicearea){
            return item;
        }
      });
      
     tempData = tempData.filter((item) =>{
        if(!filterObj.status || filterObj.status === '' || item.status === filterObj.status){
            return item;
        }
      });
    
     
    
      setRegFormList(tempData);
    }
    
     }
    const fetchAssessments = async (e) => {
        const result = await inventoryApi.fetchAssessments();
        if (result) {
            console.log('result', result)
            //let tempBody = result.map((data, index) => ({ ...data, index: index + 1 }));
            setassessmentList(result);
        } else {
            setassessmentList([]);
        }
    }

    const fetchIEP = async (e) => {
        const result = await inventoryApi.fetchIEPs();
        if (result) {
            console.log('result', result)
            //let tempBody = result.map((data, index) => ({ ...data, index: index + 1 }));
            setIEPlist(result);
        } else {
            setIEPlist([]);
        }
    }





    const header = [
        {
            title: '#', prop: 'id',
            cell: (row) => (
                <input
                    type="checkbox"
                    to={row.id}
                    name={row.id}
                    checked={row.checked}
                    onChange={(e) => handleValueChange(e, row)}
                />
            ),

        },
        { title: 'Students', prop: 'name', isFilterable: true },


    ];

    // Note that the fields are all using the `prop` field of the headers.
    const labels = {
        beforeSelect: " "
    }



    const handleSave = async (e) => {
        if(!questions.assessmentstudents || questions.assessmentstudents.length === 0){
            setShowError(true);
            return;
        }
        console.log('if handle save call')
        let result = {};
        // delete questions.allstudents;
        // delete questions.allquestions;
        if(questions.category === 'PHYSIO'){
            console.log('if PHYSIO call')
            let qResult = await inventoryApi.fetchQuestionsByCategory(questions.category);
            console.log('qResult',qResult) 
            if(qResult){
                questions.assessmentquestions = qResult;

            }
         
        }
            console.log("questions ===> ", questions);
            result = await inventoryApi.createAssessments(questions);
            console.log("create result", result);
            if (result) {

                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record create successfully' });
                navigate(`/assessments/${result.id}`, { state: result });

            


        }
       

    }

    const handlePrevious = (e) => {
        navigate('/assessments/e/assignquestion',  { state: questions});
        console.log(next)
    }

    const handleValueChange = (e, item) => {
        const { name, checked } = e.target;

      
       let tempSelection = body.filter((item) =>{
        if(item.id === name){
          item['checked'] = checked;
          return item;
        }
        return item;
      });

      tempSelection = tempSelection.filter((item) =>{
        if(item.checked){
          return item;
        }
      });

      console.log('tempSelection:', tempSelection);
      setSelectedItems(tempSelection);
      setQuestions({ ...questions, assessmentstudents: tempSelection});
        /*
        
        if (e.target && e.target.name && e.target.value) {
            const { name, value } = e.target;
            setQuestions({ ...questions, [name]: value });
            console.log(questions);
        } else {
            let temp = [...assessmentstudents];
            //console.log(e.target.checked);
            if (e.target.checked) {
                setStudentIds((prevState) => [...prevState, item.id])
                setQuestions({ ...questions, assessmentstudents: [...StudentIds, item.id] });
                setSelectedItems((prevState) => [...prevState, item]);
                temp.push(item);
            } else {
                const filteredArray = assessmentstudents.filter((data) => data !== item);
                setQuestions({ ...questions, assessmentstudents: filteredArray });
                setSelectedItems(filteredArray);
            }

        }*/
    }

    const onFilterStatus = (event) => {
        if (event.target.value === '') {
          setBody(regFormList);
        } else {
          setBody(
            regFormList.filter((data) => (data.status || '').toLowerCase() === (event.target.value || '').toLowerCase())
          );
        }
      };

      const handleRemoveSelected = (id)=>{
        let tempSelection = assessmentstudents.filter((item) =>{
          if(item.id === id){
            item['checked'] = false;
          }else
            return item;
        });
        
        setSelectedItems(
          tempSelection
        );
        
        setQuestions({ ...questions, assessmentstudents: tempSelection});
      }

    return (
        <Container>

            <Row >
                <Col lg={12}  >
                    <ListGroup className="listGroup">
                        <ListGroup.Item
                            as="li"
                            style={{ backgroundColor: "#1A293B", color: "white" }}

                        >
                            <b>Select Students</b><b className="float-end">Step 3 of 3</b>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>

            <Row className=" mx-2 my-2">
            <Col className="mx-3 col-lg-3 ">
                    <Form.Group className="mx-3 mt-4" controlId="formBasicStatus">

                    <Form.Select aria-label="Enter status" name="servicearea" onChange={onFilterSelection}>
                                        <option value="">--Service Area--</option>
                                        <option value="School">School</option>
                                        <option value="CBR">CBR</option>
                                    </Form.Select>
                    </Form.Group>
                </Col>
                {/* <Col className="mx-3 col-lg-3 ">
                    <Form.Group className="mx-3 mt-4" controlId="formBasicStatus">

                        <Form.Select aria-label="Enter status" name="status" onChange={onFilterSelection}>
                        <option value="">---Select----</option>
                            <option value="Active">Active</option>
                            
                            {/* <option value="Inactive">Inactive</option>
                            <option value="Rejected">Rejected</option>
                            <option value="Dropout">Dropout</option> 
                        </Form.Select>
                    </Form.Group>
                </Col> */}
            </Row>
            <Row className="m-2">
     {showError ? <Alert show={showError} variant="danger" className="error-alert">Select atleast one student</Alert> : ''}
     </Row>
            <Row className="g-0">
                <Col lg={6} className="p-lg-2">
                    {regFormList ?
                        <DatatableWrapper body={regFormList} headers={header} selected={assessmentstudents} paginationOptionsProps={{
                        }}>

                            <Table striped className="data-table my-3 childtable">
                                <TableHeader />


                                <TableBody >

                                </TableBody>


                            </Table>

                        </DatatableWrapper> : ''}
                </Col>

                {next ?
                    <Col lg={6} style={{ paddingTop: "17px" }} >

                        <ListGroup>
                            <ListGroup.Item as="li" className = "listItem"><b  style={{color: 'white'}}>Selected Students</b> </ListGroup.Item>
                            {assessmentstudents?.map((item, index) => (
                                <ListGroup.Item as="li" key={item.id} style={{ height: '35px' }}>

                                    <button className='btn-sm mx-2' style={{ borderStyle: 'none' }} variant='danger' onClick={() => {

handleRemoveSelected(item.id)
                                        
                                    }}><i class="fa-solid fa-xmark text-danger"></i></button>
                                    {item.name}</ListGroup.Item>

                            ))}

                        </ListGroup>
                    </Col>
                    : null}

                <Row className="justify-content-center my-4">
                    <Col className="col-lg-2">
                        <Button variant="primary" onClick={handlePrevious} className="mx-2">
                            Previous
                        </Button>
                        <Button variant="primary" onClick={handleSave} >
                            Save
                        </Button>
                    </Col>
                </Row>
            </Row>
        </Container>


    )



}

export default AssignStudents
