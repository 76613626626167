import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import PubSub from "pubsub-js";
import moment from 'moment';
import { useNavigate } from 'react-router-dom'
import PdfGenerator from "./PdfGenerator";
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import * as constants from "../constants/CONSTANT";
import { controllers } from "chart.js";


const CbrWorkerReportEdit = () => {
  const location = useLocation();
  const [errorShow, setErrorShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [refresh, setRefresh] = useState();

  // const [errorMessage, setErrorMessage] = useState('');
  // const [showError, setShowError] = useState(false);
  const [formEnable, setFormEnable] = useState(true);
  const [workerData, setWorkerData] = useState(location.state ? location.state : {});
  const [editForm, setEditForm] = useState(false);
  const navigate = useNavigate();

  const arr1_1 = ['_1_1m', '_1_2m', '_1_3m', '_1_4m', '_1_5m', '_1_6m', '_1_7m', '_1_8m', '_1_9m'];
  const arr1_2 = ['_1_1f', '_1_2f', '_1_3f', '_1_4f', '_1_5f', '_1_6f', '_1_7f', '_1_8f', '_1_9f'];
  const arr2_1 = ['_2_1m', '_2_2m', '_2_3m', '_2_4m', '_2_5m', '_2_6m', '_2_7m', '_2_8m', '_2_9m', '_2_10m'];
  const arr2_2 = ['_2_1f', '_2_2f', '_2_3f', '_2_4f', '_2_5f', '_2_6f', '_2_7f', '_2_8f', '_2_9f', '_2_10f'];
  const arr3_1 = ['_3_1m', '_3_2m', '_3_3m', '_3_4m', '_3_5m', '_3_6m', '_3_7m', '_3_8m', '_3_9m', '_3_10m'];
  const arr3_2 = ['_3_1f', '_3_2f', '_3_3f', '_3_4f', '_3_5f', '_3_6f', '_3_7f', '_3_8f', '_3_9f', '_3_10f'];

  useEffect(() => {                          //called when it get rendered
    if (location.state === null) {
      setFormEnable(false);
      setEditForm(true);
    }
  }, []);



  const handleChange = (e) => {
    //setWorkerData({ ...workerData, [e.target.name]: e.target.value });
    let data = { ...workerData, [e.target.name]: e.target.value }
    let _1_1Row = sumOfRow(arr1_1, data);
    let _1_2Row = sumOfRow(arr1_2, data);
    let _2_1Row = sumOfRow(arr2_1, data);
    let _2_2Row = sumOfRow(arr2_2, data);
    let _3_1Row = sumOfRow(arr3_1, data);
    let _3_2Row = sumOfRow(arr3_2, data);
    let _1TableColSum = sumOfCol(arr1_1, arr1_2, data);
    let _2TableColSum = sumOfCol(arr2_1, arr2_2, data);
    let _3TableColSum = sumOfCol(arr3_1, arr3_2, data);
    setWorkerData({ ...workerData, [e.target.name]: e.target.value, ['_1_10m']: _1_1Row, ['_1_10f']: _1_2Row, ['_2_11m']: _2_1Row, ['_2_11f']: _2_2Row, ['_3_11m']: _3_1Row, ['_3_11f']: _3_2Row, ['_1_1t']: _1TableColSum[0], ['_1_2t']: _1TableColSum[1], ['_1_3t']: _1TableColSum[2], ['_1_4t']: _1TableColSum[3], ['_1_5t']: _1TableColSum[4], ['_1_6t']: _1TableColSum[5], ['_1_7t']: _1TableColSum[6], ['_1_8t']: _1TableColSum[7], ['_1_9t']: _1TableColSum[8], ['_1_10t']: (_1_1Row + _1_2Row), ['_2_1t']: _2TableColSum[0], ['_2_2t']: _2TableColSum[1], ['_2_3t']: _2TableColSum[2], ['_2_4t']: _2TableColSum[3], ['_2_5t']: _2TableColSum[4], ['_2_6t']: _2TableColSum[5], ['_2_7t']: _2TableColSum[6], ['_2_8t']: _2TableColSum[7], ['_2_9t']: _2TableColSum[8], ['_2_10t']: _2TableColSum[9], ['_2_11t']: (_2_1Row + _2_2Row), ['_3_1t']: _3TableColSum[0], ['_3_2t']: _3TableColSum[1], ['_3_3t']: _3TableColSum[2], ['_3_4t']: _3TableColSum[3], ['_3_5t']: _3TableColSum[4], ['_3_6t']: _3TableColSum[5], ['_3_7t']: _3TableColSum[6], ['_3_8t']: _3TableColSum[7], ['_3_9t']: _3TableColSum[8], ['_3_10t']: _3TableColSum[9], ['_3_11t']: (_3_1Row + _3_2Row) });
  };

  const sumOfRow = (arr, data) => {
    let sum = 0;
    for (let item of arr) {
      for (let [key, value] of Object.entries(data)) {
        if (item == key) {
          sum += Number(value);
        }
      }
    }
    return sum;
  }

  const sumOfCol = (arr1, arr2, data) => {
    let resultArr = [];
    arr1.forEach((item, index) => {
      let sum = 0;
      let var1; let var2;
      for (let [key, value] of Object.entries(data)) {
        if (item == key) {
          var1 = value;
        }
      }
      for (let [key, value] of Object.entries(data)) {
        if (arr2[index] == key) {
          var2 = value;
        }
      }
      sum += Number(var1) + Number(var2);
      resultArr.push(sum)
      // console.log('sum >>> ', sum);
    })
    return resultArr
  }

  const checkRequredFields = () => {
    if (
      workerData.name && workerData.name.trim() !== ""
      &&
      workerData.projectname && workerData.projectname.trim() !== ""
      &&
      workerData.cbrfieldarea && workerData.cbrfieldarea.trim() !== ""
      &&
      workerData.cbrdate && workerData.cbrdate.trim() !== ""
      &&
      workerData.signofincharge && workerData.signofincharge.trim() !== ""
      &&
      workerData.signofcbrworker && workerData.signofcbrworker.trim() !== ""
      &&
      workerData.inchargename && workerData.inchargename.trim() !== ""
      &&
      workerData.date && workerData.date.trim() !== ""
      &&
      workerData.contactno && workerData.contactno.trim() !== ""
    ) {
      return false;
    }
    return true;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (checkRequredFields()) {
      console.log('====true======');
      setValidated(true);
      setErrorShow(true);
      return;
    }
    // console.log("CbrWorkerReportData", workerData);
    if (workerData.id) {
      console.log("====edit-====");
      setValidated(false);
      const result = await inventoryApi.saveCbrWorkerReport(workerData);
      if (result.errors) {
        // setShowError(true);
        // setErrorMessage(result.errors);
        return;
      }
      if (result.success) {
        console.log('if result true');
        PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record update successfully' });
        //navigate(`/cbrworkerreport/${workerData.id}`, { state: result });
        setFormEnable(true);
        setErrorShow(false);
        // setDuplicateWorkerData({id : ''});
        setEditForm(false);
      }
    } else {
      const result = await inventoryApi.createCbrWorkerReport(workerData);
      if (result) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record create successfully",
        });
        navigate(`/cbrworkerreport/`, { state: result });
      }
      if (result.errors) {
        // setShowError(true);
        // setErrorMessage(result.errors);
        return;
      }
    }
  };
  const handleEdit = () => {
    setFormEnable(false);
    // setDuplicateWorkerData({id : ''});
    setEditForm(true);
  }

  const handleCancel = () => {
    navigate(`/cbrworkerreport/`);
  };

  const pdfMaker = () => {


    // setshowDetails(true)
    //console.log('if pdf row call')
    const pdfTable = document.getElementById("divToPrint");
    //console.log("pdf table", pdfTable);
    var html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize: true,
    });
    console.log('html', html)
    const documentDefinition = {
      content: html, defaultStyle: {
        font: 'arialunicodems1'
      }
    };
    //console.log('documentDefinition', documentDefinition)
    //pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.fonts = {
      arialunicodems1: {
        normal: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
        bold: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
      },


      // download default Roboto font from cdnjs.com
      Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
      }
    }
    //console.log(pdfMake.vfs)
    pdfMake.createPdf(documentDefinition).open();
  };


  return (

    <Container>
      <Row>
        <Alert variant="danger" show={errorShow} className="error-alert my-2"><i class="fa-solid fa-triangle-exclamation"></i> Please fill all required values</Alert>
        <Col lg={12} className="mx-4">
          <Form
            className="mt-4"
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
          >
            <Row className="view-form-header align-items-center">
              <Col lg={6}>CBR Worker Report</Col>
              {editForm === false && (
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-sm mx-2" variant="primary" onClick={() => { pdfMaker() }}>
                    <i class="fa-solid fa-download ml-2 mr-4" style={{ color: '#ffffff' }} ></i>
                    <b>CBR Worker Report</b>
                  </Button>
                  <Button className="btn-sm mx-2" variant="primary" onClick={handleEdit}> <i class="fa-regular fa-pen-to-square"></i> </Button>
                </Col>)}
              {editForm === true && (
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-sm mx-2" type="submit" onClick={handleSubmit}> Save </Button>
                  <Button className="btn-sm" variant="danger" onClick={handleCancel} > Cancel </Button>
                </Col>)
              }

            </Row>
          </Form>
        </Col>
        <Col />
      </Row>
      <fieldset disabled={formEnable}>
        <Row lg={12}>
          <Col lg={6}>
            <Form.Group className="mx-4 my-4" controlId="formBasicname">
              <Form.Label className="form-view-label">
                Name of CBR Worker
              </Form.Label>
              <Form.Control
                required
                type="text"
                name="name"
                placeholder="Enter Name"
                value={workerData.name}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-4 my-4" controlId="formBasicProjectName">
              <Form.Label className="form-view-label">Name Of Project</Form.Label>
              <Form.Control
                required
                type="text"
                name="projectname"
                placeholder="Enter Project Name"
                value={workerData.projectname}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-4 " controlId="formBasicDate">
              <Form.Label className="form-view-label">Date</Form.Label>
              <Form.Control
                type="date"
                required
                name="cbrdate"
                value={moment(workerData.cbrdate).format('YYYY-MM-DD')}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mx-2 " controlId="formBasicFiledArea">
              <Form.Label className="form-view-label">CBR Filed Area</Form.Label>
              <Form.Control
                type="text"
                required
                name="cbrfieldarea"
                placeholder="Enter Field Area"
                value={workerData.cbrfieldarea}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={12} className="section-header my-4 mx-4">
            1. Children Enrollement
          </Col>
          <Col>
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    1.1 <br></br> Total No. of CBR children
                  </th>
                  <th>
                    1.2 <br></br> MI
                  </th>
                  <th>
                    1.4 <br></br> LI
                  </th>
                  <th>
                    1.4 <br></br>Speech Program
                  </th>
                  <th>
                    1.5 <br></br> Physio Program
                  </th>
                  <th>
                    1.6 <br></br>Phycho Program
                  </th>
                  <th>
                    1.7 <br></br> Vocational Training{" "}
                  </th>
                  <th>
                    1.8 <br></br> Education
                  </th>
                  <th>
                    1.9 <br></br> LiveliHood
                  </th>
                  <th>
                    1.10 <br></br> Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Male</td>
                  <td>
                    <input type="number" name="_1_1m" value={workerData._1_1m} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="number" name="_1_2m" value={workerData._1_2m} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="number" name="_1_3m" value={workerData._1_3m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_1_4m" value={workerData._1_4m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_1_5m" value={workerData._1_5m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_1_6m" value={workerData._1_6m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_1_7m" value={workerData._1_7m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_1_8m" value={workerData._1_8m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_1_9m" value={workerData._1_9m} className="form-control" onChange={handleChange} />
                  </td>
                  <td className="form-control" style={{ height: "33px", marginTop: "4px" }}>
                    {workerData._1_10m}
                  </td>
                </tr>

                <tr>
                  <td>Female</td>
                  <td>
                    <input type="number" name="_1_1f" value={workerData._1_1f} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="number" name="_1_2f" value={workerData._1_2f} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="number" name="_1_3f" value={workerData._1_3f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_1_4f" value={workerData._1_4f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_1_5f" value={workerData._1_5f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_1_6f" value={workerData._1_6f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_1_7f" value={workerData._1_7f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_1_8f" value={workerData._1_8f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_1_9f" value={workerData._1_9f} className="form-control" onChange={handleChange} />
                  </td>
                  <td className="form-control" style={{ height: "33px", marginTop: "4px" }}>
                    {workerData._1_10f}
                  </td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>
                    <input type="number" name="_1_2t" value={workerData._1_1t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_1_2t" value={workerData._1_2t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_1_3t" value={workerData._1_3t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_1_4t" value={workerData._1_4t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_1_5t" value={workerData._1_5t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_1_6t" value={workerData._1_6t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_1_7t" value={workerData._1_7t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_1_8t" value={workerData._1_8t} disabled={true} className="form-control" />
                  </td>
                  <td>
                    <input type="number" name="_1_9t" value={workerData._1_9t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_1_10t" value={workerData._1_10t} className="form-control" disabled={true} />
                  </td>

                </tr>
              </tbody>
            </table>
          </Col>
          <Col lg={12} className="section-header my-4 mx-4">
            2. Disability Wise Break Up
          </Col>

          <Col>
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    2.1<br></br> ID
                  </th>
                  <th>
                    2.2 <br></br> CP
                  </th>
                  <th>
                    2.3 <br></br> Autistic
                  </th>
                  <th>
                    2.4 <br></br> VI
                  </th>
                  <th>
                    2.5<br></br> HI
                  </th>
                  <th>
                    2.6 <br></br>MD
                  </th>
                  <th>
                    2.7 <br></br> DB
                  </th>
                  <th>
                    2.8 <br></br> LD
                  </th>
                  <th>
                    2.9 <br></br> PH
                  </th>
                  <th>
                    2.10 <br></br>Other
                  </th>
                  <th>
                    2.11 <br></br>Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Male</td>
                  <td>
                    <input type="number" name="_2_1m" value={workerData._2_1m} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="number" name="_2_2m" value={workerData._2_2m} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="number" name="_2_3m" value={workerData._2_3m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_2_4m" value={workerData._2_4m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_2_5m" value={workerData._2_5m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_2_6m" value={workerData._2_6m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_2_7m" value={workerData._2_7m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_2_8m" value={workerData._2_8m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_2_9m" value={workerData._2_9m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_2_10m" value={workerData._2_10m} className="form-control" onChange={handleChange} />
                  </td>
                  {/* <td>
                    <input type="number" name="_2_11m" value={workerData._2_11m} className="form-control" onChange={handleChange} />
                  </td> */}
                  <td className="form-control" style={{ height: "33px", marginTop: "4px" }}>
                    {workerData._2_11m}
                  </td>
                </tr>

                <tr>
                  <td>Female</td>
                  <td>
                    <input type="number" name="_2_1f" value={workerData._2_1f} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="number" name="_2_2f" value={workerData._2_2f} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="number" name="_2_3f" value={workerData._2_3f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_2_4f" value={workerData._2_4f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_2_5f" value={workerData._2_5f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_2_6f" value={workerData._2_6f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_2_7f" value={workerData._2_7f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_2_8f" value={workerData._2_8f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_2_9f" value={workerData._2_9f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_2_10f" value={workerData._2_10f} className="form-control" onChange={handleChange} />
                  </td>
                  {/* <td>
                    <input type="number" name="_2_11f" value={workerData._2_11f} className="form-control" onChange={handleChange} />
                  </td> */}
                  <td className="form-control" style={{ height: "33px", marginTop: "4px" }}>
                    {workerData._2_11f}
                  </td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>
                    <input type="number" name="_2_1t" value={workerData._2_1t} className="form-control" disabled={true} />{" "}
                  </td>
                  <td>
                    <input type="number" name="_2_2t" value={workerData._2_2t} className="form-control" disabled={true} />{" "}
                  </td>
                  <td>
                    <input type="number" name="_2_3t" value={workerData._2_3t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_2_4t" value={workerData._2_4t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_2_5t" value={workerData._2_5t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_2_6t" value={workerData._2_6t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_2_7t" value={workerData._2_7t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_2_8t" value={workerData._2_8t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_2_9t" value={workerData._2_9t} className="form-control" disabled={true} />
                  </td>

                  <td>
                    <input type="number" name="_2_10t" value={workerData._2_10t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_2_11t" value={workerData._2_11t} className="form-control" disabled={true} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col lg={12} className="section-header my-4 mx-4">
            3. Social Security details till month
          </Col>
          <Col>
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    3.1<br></br> Total Enrollement
                  </th>
                  <th>
                    3.2 <br></br> Disability Certificate
                  </th>
                  <th>
                    3.3 <br></br> Rail Pass
                  </th>
                  <th>
                    3.4 <br></br> Buss Pass
                  </th>
                  <th>
                    3.5<br></br> UID Card
                  </th>
                  <th>
                    3.6 <br></br>Adhar Card
                  </th>
                  <th>
                    3.7 <br></br> Pention Start/Submission
                  </th>
                  <th>
                    3.8 <br></br> Nirmaya{" "}
                  </th>
                  <th>
                    3.9 <br></br> Other Specific
                  </th>
                  <th>
                    3.10 <br></br>Other Specify
                  </th>
                  <th>
                    3.11 <br></br>Total
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Male</td>
                  <td>
                    <input type="number" name="_3_1m" value={workerData._3_1m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_3_2m" value={workerData._3_2m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_3_3m" value={workerData._3_3m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_3_4m" value={workerData._3_4m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_3_5m" value={workerData._3_5m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_3_6m" value={workerData._3_6m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_3_7m" value={workerData._3_7m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_3_8m" value={workerData._3_8m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_3_9m" value={workerData._3_9m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_3_10m" value={workerData._3_10m} className="form-control" onChange={handleChange} />
                  </td>
                  {/* <td>
                    <input type="number" name="_3_11m" value={workerData._3_11m} className="form-control" onChange={handleChange} />
                  </td> */}
                  <td className="form-control" style={{ height: "33px", marginTop: "4px" }}>
                    {workerData._3_11m}
                  </td>
                </tr>

                <tr>
                  <td>Female</td>
                  <td>
                    <input type="number" name="_3_1f" value={workerData._3_1f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_3_2f" value={workerData._3_2f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_3_3f" value={workerData._3_3f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_3_4f" value={workerData._3_4f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_3_5f" value={workerData._3_5f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_3_6f" value={workerData._3_6f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_3_7f" value={workerData._3_7f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_3_8f" value={workerData._3_8f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_3_9f" value={workerData._3_9f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_3_10f" value={workerData._3_10f} className="form-control" onChange={handleChange} />
                  </td>
                 
                  <td className="form-control" style={{ height: "33px", marginTop: "4px" }}>
                    {workerData._3_11f}
                  </td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>
                    <input type="number" name="_3_1t" value={workerData._3_1t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_3_2t" value={workerData._3_2t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_3_3t" value={workerData._3_3t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_3_4t" value={workerData._3_4t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_3_5t" value={workerData._3_5t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_3_6t" value={workerData._3_6t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_3_7t" value={workerData._3_7t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_3_8t" value={workerData._3_8t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_3_9t" value={workerData._3_9t} className="form-control" disabled={true} />
                  </td>

                  <td>
                    <input type="number" name="_3_10t" value={workerData._3_10t} className="form-control" disabled={true} />
                  </td>
                  <td>
                    <input type="number" name="_3_11t" value={workerData._3_11t} className="form-control" disabled={true} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col lg={12} className="section-header my-4 mx-4">
            4. Services to the children / Adults
          </Col>
          <Col>
            <table className="table">
              <thead>
                <tr>
                  <th>
                    4.1<br></br> Total no.of planned services
                  </th>
                  <th>
                    4.2 <br></br>Actual No. of services
                  </th>
                  <th>
                    4.3 <br></br>Total no. of children given 1 visit in the month{" "}
                  </th>
                  <th>
                    4.4 <br></br>Total no.of children given 2 visit in the month
                  </th>
                  <th>
                    4.5<br></br>Total no. of children given more than 2 visit in
                    the month{" "}
                  </th>
                  <th>
                    4.6 <br></br>Total no. of children who did not get any
                    services during the month
                  </th>
                  <th>
                    4.7 <br></br> Physisotherepist visit/no. of children visited
                    during the month
                  </th>
                  <th>
                    4.8 <br></br> Speech teacher visits/no. of children visited
                    during the month{" "}
                  </th>
                  <th>
                    4.9 <br></br> Psychologist visits/no. of children visited
                    during the month{" "}
                  </th>
                  <th>
                    4.10 <br></br>Visit by any other officers/guest attach visit
                    report{" "}
                  </th>
                  <th>
                    4.11 <br></br>Remark
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input type="number" name="_4_1" value={workerData._4_1} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="number" name="_4_2" value={workerData._4_2} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="number" name="_4_3" value={workerData._4_3} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_4_4" value={workerData._4_4} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_4_5" value={workerData._4_5} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_4_6" value={workerData._4_6} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_4_7" value={workerData._4_7} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_4_8" value={workerData._4_8} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_4_9" value={workerData._4_9} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_4_10" value={workerData._4_10} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_4_11" value={workerData._4_11} className="form-control" onChange={handleChange} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>

          <Col lg={12} className="section-header my-4 mx-4">
            5.Stack holder contact during the month for support CBR Children
          </Col>
          <Col>
            <table className="table">
              <thead>
                <tr>
                  <th>
                    5.1<br></br> AWW{" "}
                  </th>
                  <th>
                    5.2 <br></br>ANW
                  </th>
                  <th>
                    5.3 <br></br>School Teachers
                  </th>
                  <th>
                    5.4 <br></br>PRIs/ward parshad
                  </th>
                  <th>
                    5.5<br></br>Doctors
                  </th>
                  <th>
                    5.6 <br></br>Employers
                  </th>
                  <th>
                    5.7 <br></br> Other (Specify)
                  </th>
                  <th>
                    5.8 <br></br> Other (Specify){" "}
                  </th>
                  <th>
                    5.9 <br></br> Other (Specify){" "}
                  </th>
                  <th>
                    5.10 <br></br>Other (Specify)
                  </th>
                  <th>
                    5.11 <br></br>Remark
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input type="number" name="_5_1" value={workerData._5_1} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="number" name="_5_2" value={workerData._5_2} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="number" name="_5_3" value={workerData._5_3} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_5_4" value={workerData._5_4} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_5_5" value={workerData._5_5} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_5_6" value={workerData._5_6} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_5_7" value={workerData._5_7} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_5_8" value={workerData._5_8} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_5_9" value={workerData._5_9} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_5_10" value={workerData._5_10} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="number" name="_5_11" value={workerData._5_11} className="form-control" onChange={handleChange} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col lg={12} className="section-header my-4 mx-4">
            6.Assessment Done
          </Col>
          <Col>
            <table className="table">
              <thead>
                <tr>
                  <th>
                    6.1 <br></br> FACP<td>Target</td>
                  </th>
                  <th>
                    <td>Done</td>
                  </th>
                  <th>
                    6.2 <br></br> Physio <td>Target</td>
                  </th>
                  <th>
                    <td>Done</td>
                  </th>
                  <th>
                    6.3 <br></br> Speech <td>Target</td>
                  </th>
                  <th>
                    <td>Done</td>
                  </th>
                  <th>
                    6.4 <br></br> Psycho <td>Target</td>
                  </th>
                  <th>
                    <td>Done</td>
                  </th>
                  <th>
                    6.5 <br></br> Vision <td>Target</td>
                  </th>
                  <th>
                    <td>Done</td>
                  </th>
                  <th>
                    6.6 <br></br> Hearing <td>Target</td>
                  </th>
                  <th>
                    <td>Done</td>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                <tr>
                  {/* <td>
                  <input
                    type="number" className="form-control" onChange={handleChange} />{" "}
                </td> */}

                  <td>
                    <input type="number" name="_6_1t" value={workerData._6_1t} className="form-control" onChange={handleChange} />
                  </td>
                  <td>

                    <input type="number" name="_6_1d" value={workerData._6_1d} className="form-control" onChange={handleChange} />
                  </td>
                  <td>

                    <input type="number" name="_6_2t" value={workerData._6_2t} className="form-control" onChange={handleChange} />
                  </td>
                  <td>

                    <input type="number" name="_6_2d" value={workerData._6_2d} className="form-control" onChange={handleChange} />
                  </td>
                  <td>

                    <input type="number" name="_6_3t" value={workerData._6_3t} className="form-control" onChange={handleChange} />
                  </td>
                  <td>

                    <input type="number" name="_6_3d" value={workerData._6_3d} className="form-control" onChange={handleChange} />
                  </td>
                  <td>

                    <input type="number" name="_6_4t" value={workerData._6_4t} className="form-control" onChange={handleChange} />
                  </td>
                  <td>

                    <input type="number" name="_6_4d" value={workerData._6_4d} className="form-control" onChange={handleChange} />
                  </td>
                  <td>

                    <input type="number" name="_6_5t" value={workerData._6_5t} className="form-control" onChange={handleChange} />
                  </td>
                  <td>

                    <input type="number" name="_6_5d" value={workerData._6_5d} className="form-control" onChange={handleChange} />
                  </td>
                  <td>

                    <input type="number" name="_6_6t" value={workerData._6_6t} className="form-control" onChange={handleChange} />
                  </td>
                  <td>

                    <input type="number" name="_6_6d" value={workerData._6_6d} className="form-control" onChange={handleChange} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col lg={12} className="section-header my-4 mx-4">
            7. New Identification during the month
          </Col>
          <Col>
            <table className="table">
              <thead>
                <tr>
                  <th>
                    7.1 <br></br> Name
                  </th>
                  <th>
                    7.2 <br></br> Father Name
                  </th>
                  <th>
                    7.4 <br></br> Age
                  </th>
                  <th>
                    7.4 <br></br> Sex
                  </th>
                  <th>
                    7.5 <br></br> DOB
                  </th>
                  <th>
                    7.6 <br></br> Disability
                  </th>
                  <th>
                    7.7 <br></br> Address
                  </th>
                  <th>
                    7.8 <br></br> Date of Identification
                  </th>
                  <th>
                    7.9 <br></br> Contact Number{" "}
                  </th>
                  <th>
                    7.10 <br></br> Adhar No.
                  </th>
                  <th>
                    7.11 <br></br> Remark
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input type="text" name="_7_1_1" value={workerData._7_1_1} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_7_2_1" value={workerData._7_2_1} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input
                      type="number" name="_7_3_1" value={workerData._7_3_1} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_4_1" value={workerData._7_4_1} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_7_5_1" value={moment(workerData._7_5_1).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_6_1" value={workerData._7_6_1} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_7_1" value={workerData._7_7_1} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="date" name="_7_8_1" value={moment(workerData._7_8_1).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_9_1" value={workerData._7_9_1} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_10_1" value={workerData._7_10_1} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_11_1" value={workerData._7_11_1} className="form-control" onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type="text" name="_7_1_2" value={workerData._7_1_2} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_7_2_2" value={workerData._7_2_2} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input
                      type="number" name="_7_3_2" value={workerData._7_3_2} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_4_2" value={workerData._7_4_2} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_7_5_2" value={moment(workerData._7_5_2).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_6_2" value={workerData._7_6_2} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_7_2" value={workerData._7_7_2} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_7_8_2" value={moment(workerData._7_8_2).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_9_2" value={workerData._7_9_2} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_10_2" value={workerData._7_10_2} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_11_2" value={workerData._7_11_2} className="form-control" onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type="text" name="_7_1_3" value={workerData._7_1_3} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_7_2_3" value={workerData._7_2_3} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input
                      type="number" name="_7_3_3" value={workerData._7_3_3} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_4_3" value={workerData._7_4_3} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_7_5_3" value={moment(workerData._7_5_3).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_6_3" value={workerData._7_6_3} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_7_3" value={workerData._7_7_3} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_7_8_3" value={moment(workerData._7_8_3).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_9_3" value={workerData._7_9_3} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_10_3" value={workerData._7_10_3} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_11_3" value={workerData._7_11_3} className="form-control" onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type="text" name="_7_1_4" value={workerData._7_1_4} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_7_2_4" value={workerData._7_2_4} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input
                      type="number" name="_7_3_4" value={workerData._7_3_4} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_4_4" value={workerData._7_4_4} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_7_5_4" value={moment(workerData._7_5_4).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_6_4" value={workerData._7_6_4} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_7_4" value={workerData._7_7_4} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_7_8_4" value={moment(workerData._7_8_4).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_9_4" value={workerData._7_9_4} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_10_4" value={workerData._7_10_4} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_11_4" value={workerData._7_11_4} className="form-control" onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type="text" name="_7_1_5" value={workerData._7_1_5} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_7_2_5" value={workerData._7_2_5} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input
                      type="number" name="_7_3_5" value={workerData._7_3_5} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_4_5" value={workerData._7_4_5} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_7_5_5" value={moment(workerData._7_5_5).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_6_5" value={workerData._7_6_5} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_7_5" value={workerData._7_7_5} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_7_8_5" value={moment(workerData._7_8_5).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_9_5" value={workerData._7_9_5} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_10_5" value={workerData._7_10_5} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_11_5" value={workerData._7_11_5} className="form-control" onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type="text" name="_7_1_6" value={workerData._7_1_6} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_7_2_6" value={workerData._7_2_6} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input
                      type="number" name="_7_3_6" value={workerData._7_3_6} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_4_6" value={workerData._7_4_6} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_7_5_6" value={moment(workerData._7_5_6).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_6_6" value={workerData._7_6_6} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_7_6" value={workerData._7_7_6} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_7_8_6" value={moment(workerData._7_8_6).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_9_6" value={workerData._7_9_6} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_10_6" value={workerData._7_10_6} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_11_6" value={workerData._7_11_6} className="form-control" onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type="text" name="_7_1_7" value={workerData._7_1_7} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_7_2_7" value={workerData._7_2_7} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input
                      type="number" name="_7_3_7" value={workerData._7_3_7} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_4_7" value={workerData._7_4_7} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_7_5_7" value={moment(workerData._7_5_7).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_6_7" value={workerData._7_6_7} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_7_7" value={workerData._7_7_7} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_7_8_7" value={moment(workerData._7_8_7).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_9_7" value={workerData._7_9_7} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_10_7" value={workerData._7_10_7} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_11_7" value={workerData._7_11_7} className="form-control" onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type="text" name="_7_1_8" value={workerData._7_1_8} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_7_2_8" value={workerData._7_2_8} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input
                      type="number" name="_7_3_8" value={workerData._7_3_8} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_4_8" value={workerData._7_4_8} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_7_5_8" value={moment(workerData._7_5_8).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_6_8" value={workerData._7_6_8} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_7_8" value={workerData._7_7_8} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_7_8_8" value={moment(workerData._7_8_8).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_9_8" value={workerData._7_9_8} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_10_8" value={workerData._7_10_8} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_11_8" value={workerData._7_11_8} className="form-control" onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type="text" name="_7_1_9" value={workerData._7_1_9} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_7_2_9" value={workerData._7_2_9} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input
                      type="number" name="_7_3_9" value={workerData._7_3_9} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_4_9" value={workerData._7_4_9} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_7_5_9" value={moment(workerData._7_5_9).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_6_9" value={workerData._7_6_9} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_7_9" value={workerData._7_7_9} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_7_8_9" value={moment(workerData._7_8_9).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_9_9" value={workerData._7_9_9} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_10_9" value={workerData._7_10_9} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_11_9" value={workerData._7_11_9} className="form-control" onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type="text" name="_7_1_10" value={workerData._7_1_10} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_7_2_10" value={workerData._7_2_10} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input
                      type="number" name="_7_3_10" value={workerData._7_3_10} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_4_10" value={workerData._7_4_10} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_7_5_10" value={moment(workerData._7_5_10).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_6_10" value={workerData._7_6_10} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_7_10" value={workerData._7_7_10} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_7_8_10" value={moment(workerData._7_8_10).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_9_10" value={workerData._7_9_10} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_10_10" value={workerData._7_10_10} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_7_11_10" value={workerData._7_11_10} className="form-control" onChange={handleChange} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col lg={12} className="section-header my-4 mx-4">
            8.Drop Out Detail
          </Col>
          <Col>
            <table className="table">
              <thead>
                <tr>
                  <th>
                    8.1 <br></br> Name
                  </th>
                  <th>
                    8.2 <br></br> Father Name
                  </th>
                  <th>
                    8.3<br></br> Age
                  </th>
                  <th>
                    8.4<br></br> Sex
                  </th>
                  <th>
                    8.5 <br></br> DOB
                  </th>
                  <th>
                    8.6 <br></br> Disability
                  </th>
                  <th>
                    8.7 <br></br> UID
                  </th>
                  <th>
                    8.8 <br></br> Date of Dropout{" "}
                  </th>
                  <th>
                    8.9 <br></br> Reason of Dropout
                  </th>
                  <th>
                    8.10 <br></br> Remarks
                  </th>
                  {/* <th>
                  8.11 <br></br> Remarks
                </th> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input type="text" name="_8_1_1" value={workerData._8_1_1} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_8_2_1" value={workerData._8_2_1} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_8_3_1" value={workerData._8_3_1} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_8_4_1" value={workerData._8_4_1} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_8_5_1" value={moment(workerData._8_5_1).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_8_6_1" value={workerData._8_6_1} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_8_7_1" value={workerData._8_7_1} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input
                      type="date" name="_8_8_1" value={moment(workerData._8_8_1).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_8_9_1" value={workerData._8_9_1} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_8_10_1" value={workerData._8_10_1} className="form-control" onChange={handleChange} />
                  </td>

                </tr>
                <tr>
                  <td>
                    <input type="text" name="_8_1_2" value={workerData._8_1_2} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_8_2_2" value={workerData._8_2_2} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_8_3_2" value={workerData._8_3_2} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_8_4_2" value={workerData._8_4_2} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_8_5_2" value={moment(workerData._8_5_2).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_8_6_2" value={workerData._8_6_2} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_8_7_2" value={workerData._8_7_2} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input
                      type="date" name="_8_8_2" value={moment(workerData._8_8_2).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_8_9_2" value={workerData._8_9_2} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_8_10_2" value={workerData._8_10_2} className="form-control" onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type="text" name="_8_1_3" value={workerData._8_1_3} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_8_2_3" value={workerData._8_2_3} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_8_3_3" value={workerData._8_3_3} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_8_4_3" value={workerData._8_4_3} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_8_5_3" value={moment(workerData._8_5_3).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_8_6_3" value={workerData._8_6_3} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_8_7_3" value={workerData._8_7_3} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input
                      type="date" name="_8_8_3" value={moment(workerData._8_8_3).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_8_9_3" value={workerData._8_9_3} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_8_10_3" value={workerData._8_10_3} className="form-control" onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type="text" name="_8_1_4" value={workerData._8_1_4} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_8_2_4" value={workerData._8_2_4} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_8_3_4" value={workerData._8_3_4} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_8_4_4" value={workerData._8_4_4} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_8_5_4" value={moment(workerData._8_5_4).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_8_6_4" value={workerData._8_6_4} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_8_7_4" value={workerData._8_7_4} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input
                      type="date" name="_8_8_4" value={moment(workerData._8_8_4).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_8_9_4" value={workerData._8_9_4} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_8_10_4" value={workerData._8_10_4} className="form-control" onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type="text" name="_8_1_5" value={workerData._8_1_5} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_8_2_5" value={workerData._8_2_5} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_8_3_5" value={workerData._8_3_5} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_8_4_5" value={workerData._8_4_5} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_8_5_5" value={moment(workerData._8_5_5).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_8_6_5" value={workerData._8_6_5} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_8_7_5" value={workerData._8_7_5} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input
                      type="date" name="_8_8_5" value={moment(workerData._8_8_5).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_8_9_5" value={workerData._8_9_5} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_8_10_5" value={workerData._8_10_5} className="form-control" onChange={handleChange} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>

          <Col lg={12} className="section-header my-4 mx-4">
            9. Detail of assisstive Device
          </Col>
          <Col>
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    9.1 <br></br> Wheel Chair
                  </th>
                  <th>
                    9.2 <br></br> Calipers
                  </th>
                  <th>
                    9.3<br></br> Hearing Aids
                  </th>
                  <th>
                    9.4<br></br> Tri Cycle
                  </th>
                  <th>
                    9.5 <br></br> Hang gripper
                  </th>
                  <th>
                    9.6 <br></br> Neck Belt
                  </th>
                  <th>
                    9.7 <br></br> Soft Ball
                  </th>
                  <th>
                    9.8 <br></br> Any other (Specify){" "}
                  </th>
                  <th>
                    9.9 <br></br> Any other (Specify)
                  </th>
                  <th>
                    9.10 <br></br> Any other (Specify)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Male</td>
                  <td>
                    <input type="text" name="_9_1m" value={workerData._9_1m} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_9_2m" value={workerData._9_2m} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_9_3m" value={workerData._9_3m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_9_4m" value={workerData._9_4m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_9_5m" value={moment(workerData._9_5m).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_9_6m" value={workerData._9_6m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_9_7m" value={workerData._9_7m} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input
                      type="date" name="_9_8m" value={moment(workerData._9_8m).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_9_9m" value={workerData._9_9m} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_9_10m" value={workerData._9_10m} className="form-control" onChange={handleChange} />
                  </td>
                </tr>

                <tr>
                  <td>Female</td>
                  <td>
                    <input type="text" name="_9_1f" value={workerData._9_1f} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_9_2f" value={workerData._9_2f} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_9_3f" value={workerData._9_3f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_9_4f" value={workerData._9_4f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="date" name="_9_5f" value={moment(workerData._9_5f).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_9_6f" value={workerData._9_6f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_9_7f" value={workerData._9_7f} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input
                      type="date" name="_9_8f" value={moment(workerData._9_8f).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_9_9f" value={workerData._9_9f} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_9_10f" value={workerData._9_10f} className="form-control" onChange={handleChange} />
                  </td>
                </tr>

                <tr>
                  <td>Supported By</td>
                  <td>
                    <input type="text" name="_9_1s" value={workerData._9_1s} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_9_2s" value={workerData._9_2s} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_9_3s" value={workerData._9_3s} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_9_4s" value={workerData._9_4s} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="date" name="_9_5s" value={moment(workerData._9_5s).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_9_6s" value={workerData._9_6s} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_9_7s" value={workerData._9_7s} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input
                      type="date" name="_9_8s" value={moment(workerData._9_8s).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_9_9s" value={workerData._9_9s} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_9_10s" value={workerData._9_10s} className="form-control" onChange={handleChange} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>

          <Col lg={12} className="section-header my-4 mx-4">
            10. Travel Detail
          </Col>
          <Col>
            <table className="table">
              <thead>
                <tr>
                  <th>
                    10.1 <br></br>Total KM travelled in the last month
                  </th>
                  <th>
                    10.2 <br></br> Total Petrol used in last month
                  </th>
                  <th>
                    10.3<br></br> Average KM{" "}
                  </th>
                  <th>
                    10.4<br></br>Total KM travelled in current month
                  </th>
                  <th>
                    10.5 <br></br> Total Petrol used in current month
                  </th>
                  <th>
                    10.6 <br></br> Average/KM
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input type="text" name="_10_1" value={workerData._10_1} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_10_2" value={workerData._10_2} className="form-control" onChange={handleChange} />{" "}
                  </td>
                  <td>
                    <input type="text" name="_10_3" value={workerData._10_3} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_10_4" value={workerData._10_4} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input
                      type="text" name="_10_5" value={workerData._10_5} className="form-control" onChange={handleChange} />
                  </td>
                  <td>
                    <input type="text" name="_10_6" value={workerData._10_6} className="form-control" onChange={handleChange} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>

          <Col lg={12} className="section-header my-4 mx-4">
            11.Donation/Community contribution raised during the month
          </Col>
          <Col lg={12}>
            <Form.Group className="mx-4" controlId="contribution">
              <Form.Control as="textarea" name="_11" value={workerData._11} onChange={handleChange} />
            </Form.Group>
          </Col>
          <Col lg={12} className="section-header my-4 mx-4">
            12. Any Problem/Challenge occured during the month
          </Col>
          <Col lg={12}>
            <Form.Group className="mx-4" controlId="FormControlProblemChallenge">
              <Form.Control as="textarea" name="_12" value={workerData._12} onChange={handleChange} />
            </Form.Group>
          </Col>
          <Col lg={12} className="section-header my-4 mx-4">
            13.Strategy used to resolve the problem/Challenge
          </Col>
          <Col lg={12}>
            <Form.Group className="mx-4" controlId="strategyProblemChalenge">
              <Form.Control as="textarea" name="_13" value={workerData._13} onChange={handleChange} />
            </Form.Group>
          </Col>
          <Col lg={12} className="section-header my-4 mx-4">
            14.Achievement During the month
          </Col>
          <Col lg={12}>
            <Form.Group className="mx-4" controlId="AchieventDuringMonth">
              <Form.Control as="textarea" name="_14" value={workerData._14} onChange={handleChange} />
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mx-4 my-2" controlId="fromControlenchange">
              <form-label>15.Signature of the Incharge </form-label>
              <Form.Control type="text" name="signofincharge" required value={workerData.signofincharge} onChange={handleChange} />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-4 my-2" controlId="fromControlpathologist">
              <form-label>Signature of Speech pathologist/Teacher : </form-label>
              <Form.Control type="text" name="signofcbrworker" required value={workerData.signofcbrworker} onChange={handleChange} />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-4 my-2" controlId="FromControlIncharge">
              <form-label>16.Name of Incharge </form-label>
              <Form.Control type="text" name="inchargename" required value={workerData.inchargename} onChange={handleChange} />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-4 my-2" controlId="FronControlldate">
              <form-label>Date </form-label>
              <Form.Control type="date" name="date" required value={moment(workerData.date).format('YYYY-MM-DD')} onChange={handleChange} />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-4 my-2" controlId="reportSubmitMonth">
              <form-label>
                17.Note .Report Should be Submitted by the last day of every month{" "}
              </form-label>
              <Form.Control type="text" name="note" value={workerData.note} onChange={handleChange} />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-4 my-2" controlId="contactnumber">
              <form-label>Contact No.</form-label>
              <Form.Control type="number" required name="contactno" value={workerData.contactno} onChange={handleChange} />
            </Form.Group>
          </Col>
        </Row>
      </fieldset>
      {/* ........................................................... */}
      <div className="App container mt-5" style={{ display: 'none' }}>
        <div id="divToPrint">
          <html>
            <head>
              <meta charset="UTF-8" />
            </head>
            <body>
              <PdfGenerator data={workerData} refresh={Date.now()} />
            </body>
          </html>
        </div>
      </div>
    </Container >
  );
};

export default CbrWorkerReportEdit;
