import React, { useState, useEffect } from "react";
import { Badge, Button, Card, Col, Container, ListGroup, Modal, Row } from "react-bootstrap";
import Confirm from "./Confirm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import FilesCreate from "./FilesCreate";
import RelatedListFiles from "./RelatedListFiles";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from 'moment';
import Form from "react-bootstrap/Form";
import Alert from 'react-bootstrap/Alert';
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import CaseHistory from "./CaseHistory";
import * as constants from "../constants/CONSTANT";
import FacpSectionResult from "./FacpSectionResult";
import PhysioSectionReport from "./PhysioSectionReport";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import WorkerBehaviour from "./WorkerBehaviour";




const StudentView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [student, setStudent] = useState(location.state ? location.state : {});
  //console.log('student view location', student)

  let Sid = student;
  const [modalShow, setModalShow] = useState(false);
  const [modalShowTaskfile, setModalShowFile] = useState(false);
  const [refreshFileList, setRefreshFileList] = useState();
  const [age, setAge] = useState();
  const [registrationAge, setRegistrationAge] = useState();

  const [showDetails, setshowDetails] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [facpAssessment, setFacpAssessment] = useState([]);
  const [facpAssessmentpdf, setFacpAssessmentPdf] = useState([]);

  const [assessmentId, setAssessmentId] = useState('');
  const [diffrenceAge, setDiffrenceAge] = useState();
  const [facpModalShow, setfacpModalShow] = useState(false);
  const [pdfInfoFacp, setpdfInfoFacp] = useState({});
  const [nextButtontrue, setNextButtonTrue] = useState(false);
  const [dataFacp, setDataFacp] = useState([]);
  const [generateFacpReport, setgenerateFacpReport] = useState(false);
  const [assessmentResult, setAssessmentResult] = useState([]);
  const [showfacpPdfComponent, setshowfacpPdfComponent] = useState(false);
  const [currentAssessmentType, setcurrentAssessmentType] = useState();
  const [phsyioShowModal, setphsyioShowModal] = useState(false);
  const [physioResult, setphysioResult] = useState([]);
  const [physioAssessmentId, setphysioAssessmentId] = useState();
  const [phsioQuestionMarks, setphsioQuestionMarks] = useState([]);
  const [requiredDocuments, setrequiredDocuments] = useState({});
  const [showWorkBehaviour, setshowWorkBehaviour] = useState(false);

  useEffect(() => {
    fetchStudentById();
    ////console.log(localStorage.getItem("permissions"));

  }, []);



  let ageData = 0
  useEffect(() => {
    (async () => {
      let result = await inventoryApi.fetchStudentById(student.id);
      console.log('result student:', result);

      let age = null;
      const dob = moment(student.dob);
      const today = moment();

      const yearsDiff = today.year() - dob.year();
      dob.add(yearsDiff, 'years');
      const monthsDiff = today.month() - dob.month();
      let ageInFloat = yearsDiff + (monthsDiff / 12);
      ageInFloat = parseFloat(ageInFloat.toFixed(1));
      //console.log('dob age', ageInFloat);

      let registrationAge = null;
      const registrationDate = moment(student.childregistrationdate);
      const todayDate = moment();

      const yearsDifference = todayDate.year() - registrationDate.year();

      dob.add(yearsDifference, 'years');
      const monthsDifference = todayDate.month() - registrationDate.month();
      let ageInDecimal = yearsDifference + (monthsDifference / 12);
      ageInDecimal = parseFloat(ageInDecimal.toFixed(1));
      //console.log('registration age', ageInDecimal);

      let ageData = ageInFloat - ageInDecimal;
      //console.log('ageData', ageData);
    })();
  }, []);

  useEffect(() => {
    fetchFacpResult();
    fetchPhsyioPDFData()
   

  }, []);

  let currentAge = 0;

  const fetchStudentById = async () => {
    console.log('if call')
    if (location.hasOwnProperty('pathname') && location.pathname.split('/').length >= 3) {
      student.id = location.pathname.split('/')[2];
    }
    console.log('id',student.id)
    let result = await inventoryApi.fetchStudentById(student.id);
    console.log('result student:', result);
    let age = null;
    const dob = moment(result.dob);
    const today = moment();

    const yearsDiff = today.year() - dob.year();
    dob.add(yearsDiff, 'years');
    const monthsDiff = today.month() - dob.month();
    let ageInFloat = yearsDiff + (monthsDiff / 12);
    ageInFloat = parseFloat(ageInFloat.toFixed(1));
    //console.log(' dob age', ageInFloat);
    setAge(ageInFloat);


    let registrationAge = null;
    const registrationDate = moment(result.childregistrationdate);
    let dobDate = moment(result.dob);
    
    const yearsDifference = registrationDate.diff(dobDate, 'years');
    console.log('yearsDifference',yearsDifference)
    dobDate.add(yearsDifference, 'years');
    const monthsDifference = registrationDate.diff(dobDate, 'months');
    console.log('monthsDifference',monthsDifference)
    let ageInDecimal = yearsDifference + (monthsDifference / 12);
    console.log('ageinDecimal',ageInDecimal);
    ageInDecimal = parseFloat(ageInDecimal.toFixed(1));
    console.log('Age difference in decimal:', ageInDecimal);
    setRegistrationAge(ageInDecimal);
    setStudent(result);


  };


  const editStudent = () => {
    navigate(`/students/${student.id}/e`, { state: student });
  };


  const submitfiles = () => {
    setModalShowFile(false);
    setRefreshFileList(Date.now());
    async function init() {
      const result = await inventoryApi.fetchrequireddocuments(student.id);
      //console.log('required document ', result)
      setrequiredDocuments(result);
    }
    init();


  };

  useEffect(() => {
    fetchPhsyioPDFData();

  }, [phsioQuestionMarks]);




  useEffect(() => {
    async function init() {
      const result = await inventoryApi.fetchrequireddocuments(student.id);
      //console.log('required document ', result)
      setrequiredDocuments(result);
    }
    init();

  }, []);

  const editPersonalInfo = () => {
    async function initStudent() {
      let result = await inventoryApi.fetchStudentPersonalInfo(student);
      //////console.log('call edit personal button for fetch result', result)
      if (result.id) {
        navigate(`/students/personalInfo/${result.id}`, { state: result });
      }
      else {
        navigate(`/students/${student.id}/personalInfo`, { state: student });
      }
    }
    initStudent();
  };


  const parentEditInfo = () => {
    async function initStudent() {
      let result = await inventoryApi.fetchStudentParent(student);
      student.fathername = result.fathername;
      //////console.log('call edi t button for fetch result', result)
      //////console.log('student>>>>>>>', student)
      if (result.id) {
        navigate(`/students/parent/${result.id}`, { state: result });
      }
      else {
        navigate(`/students/${student.id}/parent`, { state: student });
      }
    }
    initStudent();
  };

  const editAssesment = () => {
    async function initStudent() {
      let result = await inventoryApi.fetchStudentAssessment(student);
      //////console.log('call edit button for fetch result', result)
      if (result.id) {
        navigate(`/students/assessmentEdit/${result.id}`, { state: result });
      }
      else {
        navigate(`/students/${student.id}/assessmentEdit`, { state: student });
      }
    }
    initStudent();
  }
  
  const handlePhysiotherepy = () => {
    async function initStudent() {
      let result = await inventoryApi.fetchStudentPhysioAssessment(student);
      //console.log('physioAssessment', result)
      if (result && result.id) {
        navigate(`/student/physioassessment/${result.id}`, { state: result })

      }
      else {
        navigate(`/student/${student.id}/physioassessment`, { state: student })
      }
    }
    initStudent();
  }

  const editDST = () => {
    async function initStudent() {
      let result = await inventoryApi.fetchDSTWithStudentId(student.id);
      // //////console.log('result $$$$$$$$$$$$$$$$$', result)  
      if (result?.id) {
        navigate(`/students/DST/${result.id}`, { state: result });
      }
      else {
        navigate(`/students/${student.id}/DST`, { state: student });
      }
    }
    initStudent();
  }


  let currentStudentAge = 0;
  const studentQuestionsScoring = (e, btnName) => {

    console.log('registrationAge', registrationAge);
    if (btnName === 'DST') {
      navigate(`/students/DST/${student.id}`, { state: { ...student, qstype: btnName, Currentage: registrationAge } })
    } else if (btnName === 'VSMS') {
      navigate(`/students/VSMS/${student.id}`, { state: { ...student, qstype: btnName, Currentage: registrationAge } })
    } else if (btnName === 'GDT') {
      navigate(`/students/GDT/${student.id}`, { state: { ...student, qstype: btnName } })
    } else if (btnName === 'FACP' || btnName === 'PHYSIO') {
      setcurrentAssessmentType(btnName)
      setShowModal(true);
      async function init() {
        let result = await inventoryApi.fetchFACPAssessmentInfo(student.id);
        //console.log('result facp', result)
        setFacpAssessment(result);

      }
      init();
    }
  }
  const handleNextButton = () => {

    if (currentAssessmentType === 'FACP') {
      navigate(`/students/${student.id}/FACP/${assessmentId}`, { state: { ...student, qstype: 'FACP' } })

    } else if (currentAssessmentType === 'PHYSIO') {
      navigate(`/students/${student.id}/PHYSIO/${assessmentId}`, { state: { ...student, qstype: 'PHYSIO' } })
    }


  }
  const handleAssessmentSelection = (item) => {
    const { assessmentname, period, assessmentid } = item;
    setAssessmentId(assessmentid)
  }
  const pdfRow = () => {

    setshowDetails(true)
    ////console.log('if pdf row call')
    const pdfTable = document.getElementById("divToPrint");
    ////console.log("pdf table", pdfTable);
    var html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize: true,
    });
    ////console.log('html', html)
    const documentDefinition = {
      content: html, defaultStyle: {
        font: 'arialunicodems1'
      }
    };
    pdfMake.fonts = {
      arialunicodems1: {
        normal: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
        bold: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
      },

      // download default Roboto font from cdnjs.com
      Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
      }
    }
    ////console.log(pdfMake.vfs)
    pdfMake.createPdf(documentDefinition).open();
  };


  const handleNextPdf = () => {
    setNextButtonTrue(true);
    fetchFacpResult(nextButtontrue)
  }


  const showFacpModal = async () => {
    setgenerateFacpReport(true)
    setfacpModalShow(true);
    let result = await inventoryApi.fetchFACPAssessmentInfo(student.id);
    ////console.log('result $$$$$$$$$$$$$$$$$', result)
    if (result) {
      setFacpAssessmentPdf(result);
    }
  }




  const fetchPhsyioPDFData = () => {

    const pdfTable = document.getElementById("phsyioResult");
    ////console.log("pdf table", pdfTable);
    var html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize: true,
    });
    ////console.log('html', html)
    const documentDefinition = {
      content: html, defaultStyle: {
        font: 'arialunicodems1'
      }
    };
    pdfMake.fonts = {
      arialunicodems1: {
        normal: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
        bold: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
      },
      // download default Roboto font from cdnjs.com
      Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
      }
    }
    //console.log(nextButtontrue)
    //console.log('assessment id ', physioAssessmentId);
    if (physioAssessmentId != null) {
      pdfMake.createPdf(documentDefinition).open();
      setphsyioShowModal(false)

    }

  }

  const handlePhysioModal = async () => {
    setphsyioShowModal(true)
    let result = await inventoryApi.fetchFACPAssessmentInfo(student.id);
    //console.log('result Phsyo', result);
    let PhysioResult = result.filter((value) => value.assessmentcategory === 'PHYSIO');
    //console.log('PHSYIO RESULT', PhysioResult);
    setphysioResult(PhysioResult);

  }


  const handlePhysioAssessmentSelection = async (item) => {
    setphysioAssessmentId(item.assessmentid);
  }


  const handlePhysioNext = async () => {
    let assessmentName = 'PHYSIO';
    //console.log(physioAssessmentId)
    let result = await inventoryApi.getAssessmentQuestions(assessmentName, student.id, physioAssessmentId);
    //console.log('questions => ', result);
    let marks = await inventoryApi.getMarks(student.id, result[0].assessmentid);
    //console.log('questions marks => ', marks);
    result.forEach((question) => {
      marks?.forEach((mark) => {
        if (mark.questionid === question.id) {
          question.marks = mark.marks;
        }
      });
    });
    setphsioQuestionMarks(result);

    //console.log(phsioQuestionMarks, phsioQuestionMarks);
  }

  const fetchFacpResult = async (nextButtontrue) => {
    const pdfTable = document.getElementById("facpresult");
    ////console.log("pdf table", pdfTable);
    var html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize: true,
    });
    ////console.log('html', html)
    const documentDefinition = {
      content: html, defaultStyle: {
        font: 'arialunicodems1'
      }
    };
    pdfMake.fonts = {
      arialunicodems1: {
        normal: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
        bold: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
      },
      Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
      }
    }
    if (nextButtontrue === false || nextButtontrue === true) {
      pdfMake.createPdf(documentDefinition).open();
      setfacpModalShow(false)
      setgenerateFacpReport(false)
    }
  };

  const values = [

    "Pre-Primary",
    "Primary 1st",
    "Primary 2nd",
    "Secondary",
    "Pre-Vocational 1st",
    "Pre-Vocational 2nd",
    "Care Group"
  ];


  const handleSelectFACPDataPdf = async (item, value) => {
    let pdfinfo = pdfInfoFacp;
    pdfinfo.assessment = item.assessmentid
    pdfinfo.assessmentperiod = item.period
    setpdfInfoFacp(pdfinfo);
    const result = await inventoryApi.fetchFacpResult(student.id, pdfInfoFacp.assessment);
    //console.log('result ===== facp result', result)
    setAssessmentResult(result);
    ////console.log(pdfInfoFacp)
  };

  const handleAccesscategory = async (value) => {
    //console.log('value category', value)
    let data = pdfInfoFacp;
    //console.log('data ', data)
    data.category = value;
    setpdfInfoFacp(data)
    //console.log('final assessment data', pdfInfoFacp)
    const result = await inventoryApi.fetchFacpResult(student.id, pdfInfoFacp.assessment);
    //console.log('result ===== facp result', result)
    const categoryArray = Object.keys(result).map((categoryName) => ({
      name: categoryName,
      items: result[categoryName],
    }));
    //console.log('categoryArray', categoryArray)
    if (Array.isArray(value) && value.length > 0) {
      //console.log('is array call')
      setDataFacp(categoryArray);
    } else {
      const filteredItems = categoryArray.filter((item) => item.items.category === value);
      //console.log('filteredItems', filteredItems)
      setDataFacp(filteredItems);
      //console.log('data', dataFacp)
    }
  }



const studentWorkBehaviour = () =>{
  navigate(`/workerbehaviours/${student.id}/e`, { state: student });
}




  return (
    <div>
      {student && (
        <Container>
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}

              title="Confirm delete?"
              message="You are going to delete the record. Are you sure?"
              table="lead"
            />
          )}

          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>{currentAssessmentType} Assessment </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex flex-column ">


                <div className="col-auto pb-2" style={{}}>


                  {!facpAssessment || facpAssessment.length === 0 ?
                    'No assessment available' : 'Select ' + currentAssessmentType + ' Assessment'

                  };


                </div>
                {facpAssessment.map((item) => {
                  if (item.assessmentcategory === currentAssessmentType) {

                    const endDate = new Date(item.enddate);
                    const startDate = new Date(item.startdate);

                    const formatDate = (date) => {
                      const options = { day: 'numeric', month: 'long', year: 'numeric' };
                      return date.toLocaleDateString('en-US', options);
                    }

                    const formattedEndDate = formatDate(endDate);
                    const formattedStartDate = formatDate(startDate);

                    //console.log(formattedStartDate); // "17 April, 2023"
                    //console.log(formattedStartDate); // "17 April, 2023"

                    return (
                      <div key={item.id} className="row no-gutters">
                        <div className="col-auto mb-2" style={{ marginLeft: '30px' }}>
                          <input type="radio" name='assessment' onChange={() => handleAssessmentSelection(item)}></input>
                        </div>
                        <div className="col" style={{ marginLeft: '10px', fontSize: '12px' }}>
                          <b>{item.assessmentname} - {item.period}</b>
                        </div>
                        <div className="col" style={{ marginLeft: '10px', fontSize: '12px' }}>
                          <b>{formattedStartDate} -  {formattedEndDate} </b>

                        </div>
                      </div>
                    );
                  }
                })}

              </div>
            </Modal.Body>
            <Modal.Footer>
              {facpAssessment && facpAssessment.length > 0 ?
                <Button variant="primary" onClick={() => handleNextButton()}>
                  Next
                </Button> : ''}
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Row className="view-form mx-2">

            <Col lg={9} style={{ border: '1px solid #ccc' }}>
              <Row className="view-form-header align-items-center">
                <Col lg={3}>
                  {student.name ? student.name : 'Student'}
                </Col>

                <Col lg={9} className="d-flex justify-content-end">
                  <Button
                    className="btn-sm mx-2"
                    variant="primary"
                    onClick={() => { editStudent() }}
                  >
                    Edit Student <span className="numberCircle">A</span>
                  </Button>

                  <Button
                    className="btn-sm mx-2"
                    variant="primary"
                    onClick={() => { parentEditInfo() }}
                  >
                    Parent Info <span className="numberCircle">B</span>
                  </Button>
                  <Button
                    className="btn-sm mx-2"
                    variant="primary"
                    onClick={() => { editPersonalInfo() }}
                  >
                    Personal Info <span className="numberCircle">C</span>
                  </Button>

                  {/* <Button
                    className="btn-sm mx-2"
                    variant="primary"
                    onClick={() => { editAssesment() }}
                  >
                    Student Assessment <span className="numberCircle">D</span>
                  </Button> */}

                  {/* <Button
                    className="btn-sm"
                    variant="primary"
                    onClick={() => { editDST() }}
                  >
                    CBR SP 
                  </Button>

                  <Button
                    className="btn-sm ms-2"
                    variant="primary"
                    //onClick={() => { studentRelatedQuestions() }}

                  >
                    <b>PHYSIO </b>
                  </Button> */}

                  <Dropdown>


                    <Dropdown.Toggle variant="primary"  >
                      Assessments
                    </Dropdown.Toggle>
                    <Dropdown.Menu>

                      <Dropdown.Item onClick={() => { editAssesment() }}>Student Assessment</Dropdown.Item>
                      <Dropdown.Item onClick={handlePhysiotherepy}>Physiotherapy</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>

              <Row>
              <Col lg={6}>
                  <label>Registration Number</label>
                  <span>{student.regnumber}</span>
                </Col>
                <Col lg={6}>
                  <label>Religion</label>
                  <span>{student.religion}</span>
                </Col>
                <Col lg={6}>
                  <label>Name</label>
                  <span>{student.name}</span>
                </Col>
                <Col lg={6}>
                  <label>Father Name</label>
                  <span>{student.fathername}</span>
                </Col>
                <Col lg={6}>
                  <label>Registration Date</label>
                  <span>{student.childregistrationdate ? moment(student.childregistrationdate).format('DD-MM-YYYY') : ''}</span>
                </Col>

                <Col lg={6}>
                  <label>Age at Enrollement</label>
                  <span>{isNaN(registrationAge) ? '' : registrationAge}</span>
                </Col>


                <Col lg={6}>
                  <label>DOB</label>
                  <span>{moment(student.dob).format('DD-MM-YYYY')}</span>
                </Col>
                <Col lg={6}>
                  <label>Current Age</label>
                  <span>{isNaN(age) ? '' : age}</span>
                </Col>

                <Col lg={6}>
                  <label>Phone</label>
                  <span>{student.phone}</span>
                </Col>

                <Col lg={6}>
                  <label>Problem</label>
                  <span>{student.problem}</span>
                </Col>
                <Col lg={6}>
                  <label>Adhar No.</label>
                  <span>{student.adharcard}</span>
                </Col>

                <Col lg={6}>
                  <label>Gender</label>
                  <span>{student.gender}</span>
                </Col>
                <Col lg={6}>
                  <label>Reffer By</label>
                  <span>{student.refferby}</span>
                </Col>
                <Col lg={6}>
                  <label>Service Area</label>
                  <span>{student.servicearea}</span>
                </Col>
                <Col lg={6}>
                  <label>Service Category</label>
                  <span>{student.servicecategoryname}</span>
                </Col>

                <Col lg={6}>
                  <label>Staff Name</label>
                  <span >
                    {student.refertostaff && student.refertostaff instanceof Array && student.refertostaff.map((item, index) => {

                      return <Badge bg="warning" style={{ display: 'inline-block', marginBottom: '2px', 'marginRight': '5px', color: '#000' }}>{item.label}</Badge>
                    })}


                  </span>
                </Col>
                <Col lg={6}>
                  <label>Mother Tongue</label>
                  <span>{student.mothertongue}</span>
                </Col>

                <Col lg={6}>
                  <label>Status</label>
                  <span>{student.status}</span>
                </Col>
                {student.status === 'Dropout' ?
                  <Col lg={6}>
                    <label>Dropout Reason</label>
                    <span>{student.dropoutreason}</span>
                  </Col>
                  : ''}
                {student.status === 'Dropout' ?
                  <Col lg={6}>
                    <label>Dropout Date</label>
                    <span>{moment(student.dropoutdate).format('DD-MM-YYYY')}</span>
                  </Col> : ''}
               



                <Col lg={6}>
                  <label>Remark</label>
                  <span>{student.remark}</span>
                </Col>
              </Row>
            </Col>

            <Col lg={3} >

              <div className="view-form-header text-center p-2">
                Score Card
              </div>


              <Button
                className=" my-2 btn btn-sm btn-block"

                variant="outline-primary"
                onClick={(e) => studentQuestionsScoring(e, 'DST')
                }
              >
                <b>DST Scoring</b>
              </Button>

              <Button
                className=" my-2 btn btn-sm btn-block"
                variant="outline-primary"
                onClick={(e) => studentQuestionsScoring(e, 'VSMS')
                }
              >
                <b>VSMS Scoring</b>
              </Button>
              <Button
                className=" my-2 btn btn-sm btn-block"
                variant="outline-primary"
                onClick={(e) => studentQuestionsScoring(e, 'GDT')
                }
              >
                <b>GDT Scoring</b>
              </Button>

              <Button
                className=" my-2 btn btn-sm btn-block"
                variant="outline-primary"
                onClick={(e) => studentQuestionsScoring(e, 'FACP')
                }
              >
                <b>FACP Scoring</b>
              </Button>


              <Button
                className=" my-2 btn btn-sm btn-block"
                variant="outline-primary"
                onClick={(e) => studentQuestionsScoring(e, 'PHYSIO')
                }
              >
                <b>PHYSIO Scoring</b>
              </Button>


              <Button
                className=" my-2 btn btn-sm btn-block"
                variant="outline-primary"
                onClick={studentWorkBehaviour}
              >
                <b>Work Behaviour Scoring</b>
              </Button>

              <hr />

             


              <button
                className=" my-2 btn-sm btn-block text-left blue"

                onClick={() => { pdfRow() }}
              >
                <i class="fa-solid fa-download ml-2 mr-4" style={{ color: '#ffffff' }}></i>
                <b>Student Case History</b>

              </button>

              <button
                className=" my-2 btn-sm btn-block blue"
                variant="light" onClick={(e) => showFacpModal(e, 'GDT')
                }
              >
                <i class="fa-solid fa-download ml-2 mr-4" style={{ color: '#ffffff' }}></i> <b>FACP Report</b>
              </button>
              <button
                className=" my-2 btn-sm btn-block blue"
                variant="light" onClick={(e) => handlePhysioModal()
                }
              >
                <i class="fa-solid fa-download ml-2 mr-4" style={{ color: '#ffffff' }}></i> <b>PHYSIO Report</b>
              </button>



            </Col>
          </Row>
          {requiredDocuments.documents &&
            <Alert variant='danger' className="my-3 mx-2">
              Please Upload Required Documents: <b>{requiredDocuments.documents.replaceAll(',', ', ')}</b>
            </Alert>
          }
          <Card bg="light" text="light" className="mb-2 mt-4">
            <Card.Header className="d-flex justify-content-between">
              <Tabs defaultActiveKey="files" id="uncontrolled-tab-example">
                <Tab eventKey="files" title="Files"></Tab>
              </Tabs>
              <Button
                className="float-right btn-sm"
                onClick={() => setModalShowFile(true)}
              >
                Upload File
              </Button>
              {modalShowTaskfile && (
                <FilesCreate
                  show={modalShowTaskfile}
                  onHide={() => setModalShowFile(false)}
                  parent={student}
                  submitfiles={submitfiles}
                  objectName="student"
                />
              )}
            </Card.Header>
            <Card.Body>
              {/* <RelatedListFiles parent={student} files={files} /> */}
              <RelatedListFiles refreshFileList={refreshFileList} parent={student} />
            </Card.Body>
          </Card>
          <div className="App container mt-5" style={{ display: 'none' }}>
            <div id="divToPrint">
              <html>
                <head>
                  <meta charset="UTF-8" />
                </head>
                <body>
                  <CaseHistory data={student} />
                </body>
              </html>
            </div>
          </div>
          <div className="App container mt-5" style={{ display: 'none' }}>

            <Modal show={facpModalShow} onHide={() => setShowModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>FACP Assessment </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="d-flex flex-column ">
                  <Row>
                    <Col><div className="col-auto pb-2" style={{}}>
                      <b> {!facpAssessmentpdf || facpAssessmentpdf.length === 0 ?
                        'No assessment available' : 'Select FACP Assessment'}</b>
                    </div>
                    </Col>
                    <Col>
                      <b>Select FACP Category</b>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mt-3">
                      {facpAssessmentpdf.map((item) => {
                        const endDate = new Date(item.enddate);
                        const startDate = new Date(item.startdate);

                        const formatDate = (date) => {
                          const options = { day: 'numeric', month: 'long', year: 'numeric' };
                          return date.toLocaleDateString('en-US', options);
                        }

                        const formattedEndDate = formatDate(endDate);
                        const formattedStartDate = formatDate(startDate);

                        //console.log(formattedStartDate); // "17 April, 2023"
                        //console.log(formattedEndDate); // "17 April, 2023"

                        return (
                          <div key={item.id} className="row no-gutters">
                            <div className="col-auto mb-2" style={{ marginLeft: '30px' }}>
                              <input type="radio" name='assessment' onChange={() => handleSelectFACPDataPdf(item)}></input>
                            </div>
                            <div className="col" style={{ marginLeft: '10px', fontSize: '12px' }}>
                              <b>{item.assessmentname} - {item.period}</b>
                            </div>
                            {/* <div className="col" style={{ marginLeft: '10px', fontSize: '12px' }}>
                              <b>{formattedStartDate} - {formattedEndDate}</b>
                            </div> */}
                          </div>
                        );
                      })}
                    </Col>

                    <Col>


                      <ListGroup className="mt-3">
                        <ListGroup.Item key="all">
                          <Form.Check
                            inline
                            name="selectAllCategoryName"
                            value={values}
                            type="radio"
                            id={`inline-radio-all`}
                            onChange={(e) => handleAccesscategory(values)}
                          />

                          Select All
                        </ListGroup.Item>
                        {values.map((value, index) => {
                          const isCategoryEnabled = assessmentResult && assessmentResult.some(obj => obj.category === value);
                          return (
                            <ListGroup.Item key={index} className={isCategoryEnabled ? "" : "disabled"}>
                              <Form.Check
                                inline
                                name="categoryname"
                                value={value}
                                type="radio"
                                id={`inline-radio-${index}`}
                                onChange={(e) => handleAccesscategory(value)}
                                disabled={!isCategoryEnabled}
                              />
                              {value}
                            </ListGroup.Item>
                          );
                        })}


                      </ListGroup>
                    </Col>
                  </Row>

                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={() => handleNextPdf()}>
                  Next
                </Button>
                <Button variant="secondary" onClick={() => setfacpModalShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

          </div>


          {/*  PHSYO Modal  */}



          <Modal show={phsyioShowModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title> PHYSIO Assessment </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex flex-column ">


                <div className="col-auto pb-2" style={{}}>


                  {!physioResult || physioResult.length === 0 ?
                    'No assessment available' : 'Select PHYSIO Assessment'

                  };
                </div>
                {physioResult.map((item) => {
                  const endDate = new Date(item.enddate);
                  const startDate = new Date(item.startdate);

                  const formatDate = (date) => {
                    const options = { day: 'numeric', month: 'long', year: 'numeric' };
                    return date.toLocaleDateString('en-US', options);
                  }

                  const formattedEndDate = formatDate(endDate);
                  const formattedStartDate = formatDate(startDate);

                  return (
                    <div key={item.id} className="row no-gutters">
                      <div className="col-auto mb-2" style={{ marginLeft: '30px' }}>
                        <input type="radio" name='assessment' onChange={() => handlePhysioAssessmentSelection(item)}></input>
                      </div>
                      <div className="col" style={{ marginLeft: '10px', fontSize: '12px' }}>
                        <b>{item.assessmentname} - {item.period} </b>
                      </div>
                      <div className="col" style={{ marginLeft: '10px', fontSize: '12px' }}>
                        <b>{formattedStartDate} - {formattedEndDate} </b>
                      </div>
                    </div>
                  );
                })}

              </div>
            </Modal.Body>
            <Modal.Footer>
              {physioResult && physioResult.length > 0 ?
                <Button variant="primary" onClick={() => handlePhysioNext()}>
                  Next
                </Button> : ''}
              <Button variant="secondary" onClick={() => setphsyioShowModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <div id="facpresult">
            {generateFacpReport === true && (
              <FacpSectionResult data={student} reportInfo={dataFacp} />
            )}
          </div>


          <div id="phsyioResult">
            {phsyioShowModal === true && (
              <PhysioSectionReport data={student} phsioQuestionMarks={phsioQuestionMarks} />
            )
            }
          </div>

        


        </Container>
      )}
    </div>
  );
};

export default StudentView;