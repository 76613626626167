import React, { useEffect, useState } from "react";
import { Alert, Badge, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../api/inventoryApi";
import moment from "moment"
import * as constants from "../constants/CONSTANT";
import { MultiSelect } from "react-multi-select-component";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import PubSub from 'pubsub-js';

const StudentEdit = (props) => {
  const [validated, setValidated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [student, setStudent] = useState(location.state ? location.state : {});
  console.log('location state', student)
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [errorShow, setErrorShow] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  const [servicecategory, setSelectedServicCategory] = useState([]);
  const [serviceArea, setServiceArea] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterStaff, setFilterStaff] = useState([]);
  const [regDate, setregDate] = useState(student.childregistrationdate? moment(student.childregistrationdate).toDate() : new Date());
  let options = [];
  const [age, setAge] = useState();
  const [staffMember, setStaffMember] = useState([]);
  const [dropOutReasonShow, setReasonShow] = useState(false)
  const [StaffMemberData, setStaffMemberData] = useState([]);
  const [staffdata, setStaffData] = useState([]);
  const [PermanentAddress, setPermanentAddress] = useState(false);


  useEffect(() => {
    //console.log('location.state:', location.state);
    async function init() {
      const result = await inventoryApi.fetchStaffMember();
      const activeMembers = result.filter((item) => item.isactive === true);
      setStaffMemberData(activeMembers);
      let staffOptions = [];
      //console.log(activeMembers);
      activeMembers.map((mem) => {
        staffOptions.push({
          label: mem.firstname + ' ' + mem.lastname,
          value: mem.id
        })
      });

      //console.log(staffOptions)
      setStaffMember(staffOptions);
      //console.log('result shivangi', activeMembers);

      let temArrayStaffMember = [...activeMembers];
      //console.log('service area',location?.state?.servicearea)
      //console.log('usertype',location?.state?.usertype)

      let filterStaff = temArrayStaffMember?.filter(item => item.usertype === location?.state?.usertype && item.servicearea === location?.state?.servicearea);
      //console.log('filterStaff ====== first ', filterStaff);

      let staffOptionss = [];
      //console.log(filterStaff);
      filterStaff.map((mem) => {
        staffOptionss.push({
          label: mem.firstname + ' ' + mem.lastname,
          value: mem.id
        })
      });

      //console.log(staffOptionss)
      setStaffMember(staffOptionss);
      if (student.refertostaff) {
        setSelectedStaff(student.refertostaff);
      }
    }


    init();
  }, []);



  useEffect(() => {
    async function init() {
      const result = await inventoryApi.fetchServiceArea();
      //console.log('service===>', result)
      if (result) {
        setServiceArea(result);
        if (location?.state?.servicearea) {
          //console.log('location?.state?.servicearea', location?.state?.servicearea)
          let tempArray = result.length && result.filter(item => item.servicearea === location.state.servicearea);
          //console.log('manager', tempArray)
          setFilteredItems(tempArray);

        }
      }
      if (StaffMemberData) {
        let temArrayStaffMember = [...StaffMemberData];
        //console.log('temArrayStaffMember',temArrayStaffMember)
        let filterStaff = temArrayStaffMember?.filter(item => item.usertype === staffMember.usertype && item.servicearea === location?.state?.servicearea);
        //console.log('filterStaff', filterStaff);
        //setFilterStaff(filterStaff);
      }



    }
    init();

    if (student.dropoutreason != null) {
      setReasonShow(true)
    }

  }, []);


  let serviceAreavalue = '';

  const handleSelectedChildDate = (name, value) => {
    console.log('name,value', name, value)
    let datevalue = moment(value).format('YYYY-MM-DD')
    console.log('datevalue', datevalue)
    setStudent({ ...student, [name]: datevalue });

  }



  const handleChange = (e) => {

    console.log(e.target.name, e.target.value)
    if (e.target.name === 'issameas' && e.target.checked === true) {
      setPermanentAddress(true);
    } else {
      setPermanentAddress(false);
    }
    //console.log(e.target.value)
    //console.log(e.target.name, e.target.value)
    const { name, value } = e.target;
    let age = null;
    if (name === 'dob') {
      const dob = moment(value, 'YYYY-MM-DD');
      age = moment().diff(dob, 'years');
      //console.log('age', age)
      setAge(age);
    }
    //console.log(age)

    if (e.target.value === 'Active' || e.target.value === 'Inactive' || e.target.value === 'Rejected') {
      setReasonShow(false)
    }
    if (e.target.name === 'status' && e.target.value === 'Dropout') {
      setReasonShow(true)
    }
    if (e.target.name === 'servicearea') {
      serviceAreavalue = e.target.value
      //console.log(e.target.name, e.target.value)
      setSelectedServicCategory(e.target.value)
      let tempArray = [...serviceArea];
      //console.log(tempArray)
      setFilteredItems(tempArray.length && tempArray.filter(item => item.servicearea === e.target.value));
      //console.log('====>', filteredItems)
      let st = student;
      st['servicecategory'] = '';
      //setSelectedStaff([]);
      setStudent(st);
    }

    if (e.target.name === 'usertype') {
      let temArrayStaffMember = [...StaffMemberData];
      let filterStaff = temArrayStaffMember?.filter(item => item.usertype === e.target.value && item.servicearea === servicecategory);
      //console.log('filterStaff', filterStaff);
      setFilterStaff(filterStaff);
      let staffOptions = [];
      //console.log(filterStaff);
      filterStaff.map((mem) => {
        staffOptions.push({
          label: mem.firstname + ' ' + mem.lastname,
          value: mem.id
        })
      });

      //console.log(staffOptions)

      setStaffMember(staffOptions);

    }
    setStudent({ ...student, [e.target.name]: e.target.value });
  };

  const checkRequredFields = () => {
    //console.log('selectedStaff:', selectedStaff);
    if (selectedStaff && selectedStaff.length > 0)
      student.refertostaff = selectedStaff.map(u => u.value).join(';');
    else
      student.refertostaff = "";
    //console.log('student.refertostaff:', student.refertostaff);

    let referTo = localStorage.getItem('userrole') !== 'USER' ? (student.refertostaff && student.refertostaff.trim() !== "") : true;
    if (
      student.name && student.name.trim() !== ""
      &&
      student.adharcard && student.adharcard.trim() !== ""
      &&
      student.phone && student.phone.trim() !== ""
      &&
      student.servicecategory && student.servicecategory.trim() !== ""
      &&
      student.gender && student.gender.trim() !== ""
      &&
      student.fathername && student.fathername.trim() !== ""
      &&
      student.dob && student.dob.trim() !== "" &&
      student.childregistrationdate && student.childregistrationdate.trim() !== ""


    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    console.log("handle submit call", student);
    e.preventDefault();
    if (checkRequredFields()) {
      setValidated(true);
      setErrorShow(true);
      return;
    }
    let result = {};
    //console.log(student);
    if (student && student?.id) {
      setValidated(false);
      result = await inventoryApi.saveStudent(student);
      //console.log('result:', result);
      if (result.success) {
        PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record update successfully' });
        navigate(`/students/${student.id}`, { state: student });
      } else {
        PubSub.publish('RECORD_ERROR_TOAST', { title: 'Record Save Error', message: result.errors });
      }
    } else {
      console.log('student', student)
      result = await inventoryApi.createStudent(student);
      console.log('result:', result);
      if (result.success) {
        PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record create successfully' });
        navigate(`/students/${result.studentRec.id}`, { state: result.studentRec });
      } else {

        PubSub.publish('RECORD_ERROR_TOAST', { title: 'Record Save Error', message: result.errors });
      }
    }
  };

  const handleCancel = () => {
    if (student.id)
      navigate(`/students/${student.id}`, { state: student });
    else
      navigate(`/students`, { state: student });
  };

  const parentEditInfo = () => {
    async function initStudent() {
      let result = await inventoryApi.fetchStudentParent(student);
      ////console.log('call edit button for fetch result', result)
      ////console.log('student>>>>>>>', student)
      if (result.id) {
        navigate(`/students/parent/${result.id}`, { state: result });
      }
      else {
        navigate(`/students/${student.id}/parent`, { state: student });
      }
    }
    initStudent();
  };


  const customValueRenderer = (selected, _options) => {
    return selected.length
      ? selected?.map(({ label }) => <Badge bg="secondary" className="mr-1" style={{ display: 'inline-block' }}>
        {label}
      </Badge>)
      : "No Items Selected";
  };

  return (
    <Container className="view-form" style={{ marginBottom: '5rem' }}>
      <Row>
        <Col></Col>
        <Col lg={8}>
          <Form className="mt-3" onSubmit={handleSubmit} noValidate validated={validated}>
            <Row className="view-form-header align-items-center">
              <Col lg={3}>
                {student.id ? (
                  <>
                    Edit Student
                  </>
                ) : (
                  "Create Student Record"
                )}
              </Col>
              <Col lg={9} className="d-flex justify-content-end">

                {isEditable &&
                  <Button className="btn-sm mx-2" type="submit">
                    Save
                  </Button>}

                <Button
                  className="btn-sm mx-2"
                  variant="primary"
                  onClick={() => { parentEditInfo() }}
                >
                  Parent Info <span className="numberCircle">B</span>
                </Button>

                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicname">
                  <Form.Label
                    className="form-view-label"
                  >
                    Registration Number
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="regnumber"
                    placeholder="Enter Name"
                    value={student.regnumber}
                    onChange={handleChange}

                  />

                </Form.Group>

              </Col>
            </Row>
            <fieldset disabled={!isEditable}>
              <Row>
                <Alert variant="danger" show={errorShow} className="error-alert my-2"><i class="fa-solid fa-triangle-exclamation"></i> Please fill all required values</Alert>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicname">
                    <Form.Label
                      className="form-view-label"

                    >
                      Name
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="name"
                      placeholder="Enter Name"
                      value={student.name}
                      onChange={handleChange}
                    />

                  </Form.Group>

                </Col>

                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicLastName">
                    <Form.Label className="form-view-label">
                      Child Registration Date
                    </Form.Label>
                    <div className="form-group d-flex">
                      <DatePicker
                        className="form-control"
                        required
                        name="childregistrationdate"
                        selected={regDate}
                        onChange={(date) => handleSelectedChildDate('childregistrationdate', date)}
                        maxDate={moment().endOf('year').add(10, 'years').toDate()}
                        dateFormat="dd-MM-yyyy"

                      />
                    <Button variant="secondary" className="btn-sm mx-2">
                        <i class="fa-solid fa-calendar-days"></i>
                      </Button>
                    </div>


                  </Form.Group>
                </Col>
                {/* <Col lg={6}>
                 
                  <Form.Group className="mx-3" controlId="formBasicLastName">
                    <Form.Label
                      className="form-view-label"

                    >
                     
                      Child Registration Date
                    </Form.Label>
                    {/* <Form.Control
                    required
                      type="date"
                      name="childregistrationdate"
                      value={moment(student.childregistrationdate).format('YYYY-MM-DD')}
                      onChange={handleChange}
                    /> 
                <DatePicker style = {{zIndex : '10'}} />

                    

                  </Form.Group>
                </Col> */}
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicfatherName">
                    <Form.Label
                      className="form-view-label"
                    >
                      Father Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="fathername"
                      placeholder="Enter Father Name"
                      value={student.fathername}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mx-3" controlId="formBasicMotherTounge">
                    <Form.Label
                      className="form-view-label"
                    >
                      Mother Tongue
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="mothertongue"
                      placeholder="Enter Mother Tongue"
                      value={student.mothertongue}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mx-3">
                    <Form.Label className="form-view-label">
                      Category
                    </Form.Label>
                    <Form.Select
                      name="category"
                      value={student.category}
                      onChange={handleChange}
                    >
                      <option >--Select--</option>
                      <option value="GEN">GEN </option>
                      <option value="OBC">OBC </option>
                      <option value="ST">ST</option>
                      <option value="SC">SC</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mx-3">
                    <Form.Label className="form-view-label">Problem</Form.Label>
                    <Form.Control
                      type="text"
                      name="problem"
                      placeholder="Enter Problem"
                      value={student.problem}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>



              <Row>
                <Col>
                  <Form.Group className="mx-3" controlId="adharcard">
                    <Form.Label
                      className="form-view-label"
                    >
                      Aadhar No
                    </Form.Label>
                    <Form.Control
                      required
                      type="number"
                      name="adharcard"
                      placeholder="Enter Aadhar No"
                      value={student.adharcard}
                      onChange={handleChange}

                    />

                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mx-3" controlId="formBasicGenderType">
                    <Form.Label
                      className="form-view-label"
                    >
                      Gender
                    </Form.Label>
                    <Form.Select
                      aria-label="Enter Gender"
                      required
                      name="gender"
                      value={student.gender}
                      onChange={handleChange}
                    >
                      <option value="" >--Select--</option>
                      <option value="Male">Male </option>
                      <option value="Female">Female</option>
                      <option value="Others">Others</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mx-3">
                    <Form.Label className="form-view-label">Country</Form.Label>
                    <Form.Control
                      type="text"
                      name="country"
                      placeholder="Enter country"
                      value={student.country}
                      onChange={handleChange}
                    />

                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="mx-3" controlId="formBasicDOB">
                    <Form.Label
                      className="form-view-label"
                    >
                      DOB
                    </Form.Label>
                    <div className="form-group d-flex " >
                      <DatePicker
                        className="form-control"
                        
                        required
                        name="dob"
                        selected={moment(student.dob).toDate()}
                        onChange={(date) => handleSelectedChildDate('dob', date)}
                        maxDate={moment().endOf('year').add(10, 'years').toDate()}
                        dateFormat="dd-MM-yyyy"
                      />
                      <Button variant="secondary" className="btn-sm mx-2">
                        <i class="fa-solid fa-calendar-days"></i>
                      </Button>
                    </div>

                  </Form.Group>
                </Col>

              </Row>




              <Row>
                <Col>
                  <Form.Group className="mx-3" controlId="formBasicRefferBy">
                    <Form.Label className="form-view-label">
                      Refer By
                    </Form.Label>
                    <Form.Select
                      name="refferby"
                      value={student.refferby}
                      onChange={handleChange}
                    >
                      <option value="">-- Select --</option>
                      <option value="Friend">Friend</option>
                      <option value="RMKM Staff">RMKM Staff</option>
                      <option value="Doctor">Doctor</option>
                      <option value="ANM">ANM</option>
                      <option value="AWW">AWW</option>
                      <option value="Parents of School Children">Parents of School Children</option>
                      <option value="Media">Media</option>


                    </Form.Select>
                  </Form.Group>

                </Col>
                <Col>
                  <Form.Group className="mx-3">
                    <Form.Label className="form-view-label">
                      Religion
                    </Form.Label>
                    <Form.Select
                      name="religion"
                      value={student.religion}
                      onChange={handleChange}
                    >
                      <option >--Select--</option>
                      <option value="Hindu">Hindu </option>
                      <option value="Muslim">Muslim</option>
                      <option value="Sikh">Sikh</option>
                      <option value="Christian">Christian</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>


              <Row>
                <Col>
                  <Form.Group className="mx-3" controlId="formBasicRefferBy">
                    <Form.Label
                      className="form-view-label"
                    >
                      Education
                    </Form.Label>


                    <Form.Select
                      required
                      name="education"
                      value={student.education}
                      onChange={handleChange}
                    >

                      <option >--Select--</option>
                      <option value="Primary">Primary </option>
                      <option value="Middle">Middle</option>
                      <option value="Secondary">Secondary</option>
                      <option value="Higher Secondary">Higher Secondary</option>
                      <option value="Graduation">Graduation</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mx-3">
                    <Form.Label className="form-view-label">
                      School Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="schoolname"
                      placeholder="Enter schoolname"
                      value={student.schoolname}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>


              <Row>
                <Col>
                  <Form.Group className="mx-3" controlId="formBasicmobile">
                    <Form.Label
                      className="form-view-label"

                    >
                      Mobile
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="phone"
                      placeholder="Enter mobile"
                      value={student.phone}
                      onChange={handleChange}
                    />

                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mx-3">
                    <Form.Label className="form-view-label">
                      Family Phone
                    </Form.Label>
                    <Form.Control

                      type="number"
                      name="familyphone"

                      value={student.familyphone}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="mx-3">
                  <b> PRESENT ADDRESS </b>
                </Col>

              </Row>
              <Row>

                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="Formstreet">
                    <Form.Label className="form-view-label mt-2">Street</Form.Label>
                    <Form.Control

                      type="text"
                      name="street"
                      placeholder="Enter Street"
                      value={student.street}
                      onChange={handleChange}
                    />

                  </Form.Group>
                </Col>
                <Col lang={6}>
                  <Form.Group className="mx-3">
                    <Form.Label className="form-view-label mt-2">Landmark</Form.Label>
                    <Form.Control

                      type="text"
                      name="landmark"
                      value={student.landmark}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mx-3">
                    <Form.Label className="form-view-label">Ward</Form.Label>
                    <Form.Control

                      type="text"
                      name="ward"
                      value={student.ward}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicCity">
                    <Form.Label
                      className="form-view-label"

                    >
                      City
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      placeholder="Enter City"
                      value={student.city}
                      onChange={handleChange}

                    />

                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3">
                    <Form.Label className="form-view-label"> State</Form.Label>
                    <Form.Control
                      type="text"
                      name="state"
                      placeholder="Enter state"
                      value={student.state}
                      onChange={handleChange}
                    />

                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasiccountry">
                    <Form.Label
                      className="form-view-label"

                    >
                      Country
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="country"
                      placeholder="Enter Country"
                      value={student.country}
                      onChange={handleChange}

                    />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicPincode">
                    <Form.Label
                      className="form-view-label"

                    >
                      PinCode
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="pincode"
                      placeholder="Enter pincode"
                      value={student.pincode}
                      onChange={handleChange}

                    />
                  </Form.Group>
                </Col>

              </Row>



              <Row className="my-3">
                <Col className="mx-3">
                  <b> PERMANENT ADDRESS </b> (
                  <Form.Check
                    inline
                    name="issameas"
                    type="checkbox"
                    onChange={handleChange}

                  /><i>Same as present address</i> )
                </Col>
              </Row>
              <Row>

                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="FormIdstreet">
                    <Form.Label className="form-view-label">Street</Form.Label>
                    <Form.Control
                      type="text"
                      name="permstreet"
                      placeholder="Enter Street"
                      value={PermanentAddress ? student.street : (student.permstreet || '')}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lang={6}>
                  <Form.Group className="mx-3">
                    <Form.Label className="form-view-label">Landmark</Form.Label>
                    <Form.Control

                      type="text"
                      name="permlandmark"
                      value={PermanentAddress ? student.landmark : (student.permlandmark || '')}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mx-3">
                    <Form.Label className="form-view-label">Ward</Form.Label>
                    <Form.Control

                      type="text"
                      name="permward"
                      value={PermanentAddress ? student.ward : (student.permward || '')}

                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicCity">
                    <Form.Label
                      className="form-view-label"

                    >
                      City
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="permcity"
                      placeholder="Enter City"
                      value={PermanentAddress ? student.city : (student.permcity || '')}

                      onChange={handleChange}

                    />

                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3">
                    <Form.Label className="form-view-label"> State</Form.Label>
                    <Form.Control
                      type="text"
                      name="permstate"
                      placeholder="Enter state"
                      value={PermanentAddress ? student.state : (student.permstate || '')}
                      onChange={handleChange}
                    />

                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasiccountry">
                    <Form.Label
                      className="form-view-label"

                    >
                      Country
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="permcountry"
                      placeholder="Enter Country"
                      //value={student.permcountry}
                      value={PermanentAddress ? student.country : (student.permcountry || '')}
                      onChange={handleChange}

                    />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicPincode">
                    <Form.Label
                      className="form-view-label"

                    >
                      PinCode
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="permpincode"
                      placeholder="Enter pincode"
                      value={PermanentAddress ? student.pincode : (student.permpincode || '')}
                      onChange={handleChange}

                    />
                  </Form.Group>
                </Col>

              </Row>
              <Row className="my-3">
                <Col className="mx-3">
                  <b> Reffer To:</b>
                </Col>
              </Row>
              <Row>

              </Row>
              <Row>
                <Col>
                  <Form.Group className="mx-3" controlId="formBasicServiceArea">
                    <Form.Label
                      className="form-view-label"

                    >
                      Service Area
                    </Form.Label>
                    <Form.Select

                      aria-label="Enter Service Area"
                      name="servicearea"
                      value={student.servicearea}
                      onChange={handleChange}
                      required
                    >
                      <option value="">--Choose--</option>
                      <option value="School">School </option>
                      <option value="CBR">CBR</option>
                      <option value="Day Care">Day Care </option>

                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mx-3" controlId="formBasicType">
                    <Form.Label
                      className="form-view-label"
                    >
                      Name of Centre
                    </Form.Label>
                    <Form.Select
                      required
                      aria-label="Enter Select Type"
                      name="servicecategory"
                      value={student.servicecategory}
                      onChange={handleChange}
                    >
                      <option value='' >--SELECT--</option>
                      {filteredItems.map(item => (
                        <option value={item.id} key={item.id}>{item.name}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="mx-3" controlId="formBasicUserType">
                    <Form.Label className="form-view-label">
                      User Type
                    </Form.Label>
                    <Form.Select
                      required
                      name="usertype"
                      onChange={handleChange}
                      value={student.usertype}
                      disabled={staffMember.servicearea === ""}
                    >
                      <option>-- Select --</option>
                      {student.servicearea === "CBR" && (
                        <React.Fragment>
                          <option value="CBR Worker">CBR Worker</option>
                          <option value="CMH">CMH</option>
                          <option value="CMM">CMM</option>
                          <option value="Phsyico Therepist">Phsyico Therepist</option>
                          <option value="Psychologist">Psychologist</option>
                          <option value="Speech Therapist">Speech Therapist</option>
                          <option value="Vocational Teacher">Vocational Teacher</option>

                        </React.Fragment>
                      )}
                      {student.servicearea === "School" && (
                        <React.Fragment>

                          <option value="Centre In charge">Centre In charge</option>
                          <option value="Special Educator">Special Educator</option>
                          <option value="Inclusive Teacher">Inclusive Teacher</option>
                          <option value="Physiotherapist">Physiotherapist</option>
                          <option value="Psychologist">Psychologist</option>
                          <option value="Speech Therapist">Speech Therapist</option>
                          <option value="Vocational Teacher">Vocational Teacher</option>
                        </React.Fragment>
                      )}
                      {student.servicearea === "Day Care" && (
                        <React.Fragment>
                          <option value="Centre In charge">Centre In charge</option>
                          <option value="Special Educator">Special Educator</option>
                          <option value="Inclusive Teacher">Inclusive Teacher</option>
                          <option value="Physiotherapist">Physiotherapist</option>
                          <option value="Psychologist">Psychologist</option>
                          <option value="Speech Therapist">Speech Therapist</option>
                          <option value="Vocational Teacher">Vocational Teacher</option>
                        </React.Fragment>
                      )}

                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="my-2">

                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicRefferToStaff">
                    <Form.Label className="form-view-label">
                      Staff
                    </Form.Label>
                    <MultiSelect
                      options={staffMember}
                      value={selectedStaff}
                      hasSelectAll={false}
                      className="multi-staff"
                      onChange={setSelectedStaff}
                      valueRenderer={customValueRenderer}
                    />
                  </Form.Group>
                </Col>



                <Col lg={3}>
                  <Form.Group className="mx-3" controlId="formBasicRefferToStaff">
                    <Form.Label
                      className="form-view-label"
                    >
                      Status
                    </Form.Label>
                    <Form.Select aria-label="Enter status" name="status" value={student.status} onChange={handleChange} required>

                      <option value="">--Select Status--</option>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                      <option value="Rejected">Rejected</option>
                      <option value="Dropout">Dropout</option>
                    </Form.Select>

                  </Form.Group>
                </Col>
                {dropOutReasonShow === true && student.status === 'Dropout' ?
                  <Col lg={3}>
                    <Form.Group className="mx-3" controlId="formBasicRefferToStaff">
                      <Form.Label
                        className="form-view-label"
                      >
                        Dropout Reason
                      </Form.Label>

                      <Form.Select aria-label="Enter status" name="dropoutreason" onChange={handleChange} value={student.dropoutreason}>
                        <option value="">--Select Reason--</option>
                        <option value="Migrated ">Migrated</option>
                        <option value="Employed">Employed</option>
                        <option value="Parents do not cooperate">Parents do not cooperate</option>
                        <option value="Rehabilitated ">Rehabilitated </option>
                        <option value="Death">Death</option>
                        <option value="Other">Other</option>

                      </Form.Select>

                    </Form.Group>
                  </Col> : null}
                <Col lg={6}>
                  <Form.Group className="mx-3 " controlId="formBasicRemark">
                    <Form.Label
                      className="form-view-label"

                    >
                      Remark
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="remark"
                      placeholder="Enter Remark"
                      value={student.remark}
                      onChange={handleChange}

                    />

                  </Form.Group>
                </Col>
                {dropOutReasonShow === true && student.status === 'Dropout' ?
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                      >
                        Dropout Date
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="dropoutdate"
                        value={moment(student.dropoutdate).format('YYYY-MM-DD')}
                        onChange={handleChange}
                      />

                    </Form.Group>
                  </Col> : null}


              </Row>

            </fieldset>
          </Form>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default StudentEdit;