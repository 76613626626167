import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import inventoryApi from "../api/inventoryApi";

import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';
import { Link } from "react-router-dom";


const CbrWorkerReportList = () => {
    const navigate = useNavigate();
    const [body, setBody] = useState([]);
    useEffect(() => {
        async function init() {
            const result = await inventoryApi.fetchCbrWorkerReports();
            if (result && result.length) {
                setBody(result);
            } else {
                setBody([]);

            }
        }
        init();
    }, []);


    const header = [
        {
            title: 'Name', prop: 'name', isFilterable: true,
            cell: (row) => (
                <Link
                    to={"/cbrworkerreport/" + row.id + "/e"}
                    state={row}
                >
                    {row.name}
                </Link>
            )
        },
        { title: 'Project Name', prop: 'projectname', isFilterable: true },
        { title: 'CBR Field area', prop: 'cbrfieldarea', isFilterable: true },
        { title: 'Name of Incahrge', prop: 'inchargename', isFilterable: true },

    ];

    const labels = {
        beforeSelect: ""
    }

    const createCbrWorker = () => {
        navigate(`/cbrworkerreport/e`);
    }


    return (
        <Row className="g-0">
            <Col lg={2} className="mx-2">
                <Link className="nav-link">Home <i class="fa-solid fa-chevron-right"></i> CBR Worker</Link>
            </Col>
            <Col lg={12} className="p-lg-4">

                {body ?
                    <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
                        initialState: {
                            rowsPerPage: 15,
                            options: [5, 10, 15, 20]
                        }
                    }}>
                        <Row className="mb-4">
                            <Col
                                xs={12}
                                lg={4}
                                className="d-flex flex-col justify-content-end align-items-end"
                            >
                                <Filter />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-start align-items-start"
                            >
                                <PaginationOptions labels={labels} />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-end align-items-end"
                            >
                                <Button className="btn-sm" variant="outline-primary" onClick={() => createCbrWorker(true)}>Create Worker</Button>
                            </Col>
                        </Row>
                        <Table striped className="data-table">
                            <TableHeader />
                            <TableBody />
                        </Table>
                        <Pagination />

                    </DatatableWrapper> : ''}
            </Col>
            <Col lg={2}></Col>
        </Row>
    )
}

export default CbrWorkerReportList
