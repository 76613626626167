import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import * as constants from "../constants/CONSTANT";
import PubSub from 'pubsub-js';


const PersonalInfoPartC = (prop) => {
  const location = useLocation();
  const [personalInfo, setPersonalInfo] = useState(
    location.state ? location.state : {}
  );
  console.log(personalInfo)
  const [isEditable, setIsEditable] = useState(true);

  const [studentPersonal, setStudentPersonal] = useState({});
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [DuringPreg, setDuringPreg] = useState(false);
  const [babysuffer, setbabysuffer] = useState(false);
  useEffect(() => {
    if (personalInfo?.studentid) {
      setStudentPersonal(personalInfo);
    }
  }, []);

  const navigate = useNavigate();
  let isChecked = true;

  const handleChange = (e) => {
    const value = e.target.value;
    isChecked = e.target.checked;
    console.log("is checked", isChecked);
    if (e.target.name === 'mothersufferedpregnancy' && e.target.value === 'Yes') {
      setDuringPreg(true);
    } else {
      setDuringPreg(false);
    }
    if (e.target.name === 'babysufferedproblem' && e.target.value === 'Yes') {
      setbabysuffer(true);

    } else {
      setbabysuffer(false);
    }
    if (isChecked) {
      console.log("if call");
      setCheckedBoxes((prevState) => [...prevState, value]);
    } else {
      setCheckedBoxes((prevState) => prevState.filter((box) => box !== value));
    }
    setStudentPersonal({ ...studentPersonal, [e.target.name]: e.target.value });
    console.log(studentPersonal);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    let result = {};
    if (studentPersonal.id) {
      console.log("=====true area=====");
      console.log(studentPersonal);
      result = await inventoryApi.savePersonalInfo(studentPersonal);
      PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record update successfully' });

      //navigate(`/students/${studentPersonal.studentid}`, {state: studentPersonal,});
    } else {
      console.log('====else===');
      console.log(studentPersonal);
      delete studentPersonal.success;
      delete studentPersonal.message;
      studentPersonal.studentid = personalInfo.id;
      result = await inventoryApi.createPersonalInfo(studentPersonal);
      console.log("create result", result);
      if (result) {
        PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record Create successfully' });
        //navigate(`/students/${result.studentid}`, { state: result });
      }
    }
  };

  const handleCancel = () => {
    if (studentPersonal.id) {
      navigate(`/students/${studentPersonal.studentid}`, { state: studentPersonal });
    } else {
      navigate(`/students/${personalInfo.id}`, { state: personalInfo });
    }

  };


  const editAssesment = () => {
    async function initStudent() {
      console.log('personalassessment', personalInfo)
      let result = await inventoryApi.fetchStudentAssessmentByPartC(personalInfo.studentid);
      console.log('call edit button for fetch result assessment', result)
      if (result.id) {
        navigate(`/students/assessmentEdit/${result.id}`, { state: result });
      }
      else {
        navigate(`/students/${personalInfo.studentid}/assessmentEdit`, { state: personalInfo });
      }
    }
    initStudent();
  }
  return (
    <Container>
      <Row className="view-form-header align-items-center mx-2">
        <Col lg={3}>
          <h4>Edit Personal Info</h4>
        </Col>
        <Col lg={9} className="d-flex justify-content-end">
          {isEditable && <Button className="btn-sm mx-2" onClick={handlesubmit}>
            Save
          </Button>}

          <Button
            className="btn-sm mx-2"
            variant="primary"
            onClick={() => { editAssesment() }}
          >
            Student Assessment <span className="numberCircle">D</span>
          </Button>
          <Button className="btn-sm" variant="danger" onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
      <fieldset disabled={!isEditable}>
        <Row className="align-items-center mx-2">
          <Col lg={12} className="section-header my-3">
            Form Part C Detail
          </Col>

          <Col lg={6}>
            <Form.Group className="mx-3" controlId="dfdgo">
              <Form.Label className="form-view-label form-label">
                Present Complaints (According to Parents)
              </Form.Label>
              <Form.Control
                as="textarea"
                name="complations"
                value={studentPersonal.complations}
                onChange={handleChange}
              />
            </Form.Group>

          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3" controlId="formProblemdetect">
              <Form.Label className="form-view-label">
                Age at which the problem was detected / come into notice
              </Form.Label>
              <Form.Control
                type="number"
                name="problemdetectage"
                value={studentPersonal.problemdetectage}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">Responded by</Form.Label>
              <Form.Control
                as="textarea"
                name="respondedby"
                value={studentPersonal.respondedby}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          <Col lg={12} className="section-header my-3">
            1. Prenatal
          </Col>

          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Mother Age At The time of conception
              </Form.Label>
              <Form.Select name="motherageconception" onChange={handleChange} value={studentPersonal.motherageconception}>
                <option value="">-- Choose Age --</option>
                <option value="below18">Below 18</option>
                <option value="18to20" selected="">
                  18 to 20
                </option>
                <option value="20to30">20 to 30</option>
                <option value="above30">Above 30</option>
              </Form.Select>
            </Form.Group>
          </Col>
         
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Father Age At The time of conception
              </Form.Label>
              <Form.Select name="fatherageconception" onChange={handleChange} value={studentPersonal.fatherageconception}>
                <option value="">-- Choose Age --</option>
                <option value="below18">Below 18</option>
                <option value="18to20" selected="">
                  18 to 20
                </option>
                <option value="20to30">20 to 30</option>
                <option value="above30">Above 30</option>
              </Form.Select>
            </Form.Group>
          </Col>


          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Mother nutration during pregnancy
              </Form.Label>

              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  label="Average"
                  name="mothernutritionpregnancy"
                  value="Average"
                  type="radio"
                  checked={studentPersonal.mothernutritionpregnancy === "Average"}
                  id={`inline-radio-1`}
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  label="Good"
                  name="mothernutritionpregnancy"
                  value="Good"
                  checked={studentPersonal.mothernutritionpregnancy === "Good"}
                  type="radio"
                  id={`inline-radio-2`}
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  label="Low"
                  name="mothernutritionpregnancy"
                  value="Low"
                  type="radio"
                  checked={studentPersonal.mothernutritionpregnancy === "Low"}
                  id={`inline-radio-1`}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                During pregnancy mother was suffered from medical problem
              </Form.Label>
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  label="Yes"
                  name="mothersufferedpregnancy"
                  value="Yes"
                  checked={studentPersonal.mothersufferedpregnancy === "Yes"}
                  type="radio"
                  id={`inline-radio-1x`}
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  label="No"
                  name="mothersufferedpregnancy"
                  value="No"
                  type="radio"
                  checked={studentPersonal.mothersufferedpregnancy === "No"}
                  id={`inline-radio-2`}
                  onChange={handleChange}
                />
                {DuringPreg === true || studentPersonal.mothersufferedpregnancy === 'Yes' ?
                  <Form.Group >
                    <Form.Label className="form-view-label">
                      Remark
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="mothersufferedremark"
                      value={studentPersonal.mothersufferedremark}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  : ''}

              </div>
            </Form.Group>

          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Medicine taken during the pregnancy
              </Form.Label>
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  label="With Concern"
                  name="medicinepregnancy"
                  value="With Concern"
                  type="radio"
                  checked={studentPersonal.medicinepregnancy === "With Concern"}
                  id={`inline-radio-1`}
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  label="Without Concern (Doctor)"
                  name="medicinepregnancy"
                  value="Without Concern (Doctor)"
                  type="radio"
                  checked={
                    studentPersonal.medicinepregnancy ===
                    "Without Concern (Doctor)"
                  }
                  id={`inline-radio-2`}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Fetal Movement was normal
              </Form.Label>
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  label="Yes"
                  name="fetalmovement"
                  value="Yes"
                  type="radio"
                  checked={studentPersonal.fetalmovement === "Yes"}
                  id={`inline-radio-1`}
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  label="No"
                  name="fetalmovement"
                  value="No"
                  checked={studentPersonal.fetalmovement === "No"}
                  type="radio"
                  id={`inline-radio-2`}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>


          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Mother had gone for regular antenatal check
              </Form.Label>
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  label="Yes"
                  name="motherantentalcheckup"
                  value="Yes"
                  type="radio"
                  checked={studentPersonal.motherantentalcheckup === "Yes"}
                  id={`inline-radio-1`}
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  label="No"
                  name="motherantentalcheckup"
                  value="No"
                  checked={studentPersonal.motherantentalcheckup === "No"}
                  type="radio"
                  id={`inline-radio-2`}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>



          {/* Section Next */}
          <Col lg={12} className="section-header my-3">
            2. Natal
          </Col>

          {/* Col 1 */}
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Full Term Delivery
              </Form.Label>

              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  label="Caesarian"
                  name="deliverytype"
                  value="Caesarian"
                  checked={studentPersonal.deliverytype === "Caesarian"}
                  type="radio"
                  id={`inline-radio-1`}
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  label="Normal"
                  name="deliverytype"
                  value="Normal"
                  type="radio"
                  checked={studentPersonal.deliverytype === "Normal"}
                  id={`inline-radio-2`}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">Birth Cry</Form.Label>
              {/* <Form.Control
                type="text"
                name="birthcry"
                value={studentPersonal.birthcry}
                onChange={handleChange}
              /> */}

              <Form.Select name="birthcry" onChange={handleChange} value={studentPersonal.birthcry}>
                <option value="">--Select----</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>

              </Form.Select>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">Birth Weight</Form.Label>
              <Form.Control
                type="text"
                name="birthweight"
                value={studentPersonal.birthweight}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">First Milk Given</Form.Label>
              {/* <Form.Control
                type="text"
                name="firstmilkgiven"
                value={studentPersonal.firstmilkgiven}
                onChange={handleChange}
              /> */}

              <Form.Select name="firstmilkgiven" onChange={handleChange} value={studentPersonal.firstmilkgiven}>
                <option value="">--Select----</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Select>
            </Form.Group>
          </Col>

          {/* Row 2 */}

          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">Delivery at</Form.Label>

              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  label="Hospital"
                  name="deliveryat"
                  value="Hospital"
                  type="radio"
                  checked={studentPersonal.deliveryat === "Hospital"}
                  id={`inline-radio-1`}
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  label="Home"
                  name="deliveryat"
                  value="Home"
                  type="radio"
                  checked={studentPersonal.deliveryat === "Home"}
                  id={`inline-radio-2`}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Cord Around neck
              </Form.Label>
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  label="Yes"
                  name="cordaroundneck"
                  value="Yes"
                  checked={studentPersonal.cordaroundneck === "Yes"}
                  type="radio"
                  id={`inline-radio-1`}
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  label="No"
                  name="cordaroundneck"
                  value="No"
                  type="radio"
                  checked={studentPersonal.cordaroundneck === "No"}
                  id={`inline-radio-2`}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Baby Color After Birth
              </Form.Label>


              <Form.Select
                name="bobycolor"
                value={studentPersonal.bobycolor}
                onChange={handleChange}
              >
                <option value="">-- Choose Color --</option>
                <option value="Pink">Pink</option>
                <option value="Blue">Blue</option>
                <option value="White">White</option>
                
              </Form.Select>


            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Labour Duration
              </Form.Label>


              <Form.Select
                name="labourduration"
                value={studentPersonal.labourduration}
                onChange={handleChange}
              >
                <option>-- Choose--</option>
                <option value="Normal">Normal</option>
                <option value="Pro long">Pro long</option>
                <option value="Not Known">Not Known</option>
              </Form.Select>


            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">Breach Delivery</Form.Label>
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  label="Yes"
                  name="deliverybreach"
                  value="Yes"
                  type="radio"
                  checked={studentPersonal.deliverybreach === "Yes"}
                  id={`inline-radio-1`}
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  label="No"
                  name="deliverybreach"
                  value="No"
                  type="radio"
                  checked={studentPersonal.deliverybreach === "No"}
                  id={`inline-radio-2`}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
          <Col lg={12} className="section-header my-3">
            3. Post Natal
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">Baby was Active</Form.Label>
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  label="Yes"
                  name="babyactive"
                  value="Yes"
                  type="radio"
                  checked={studentPersonal.babyactive === "Yes"}
                  id={`inline-radio-1`}
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  label="No"
                  name="babyactive"
                  value="No"
                  type="radio"
                  checked={studentPersonal.babyactive === "No"}
                  id={`inline-radio-2`}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Baby was suffering medical issues
              </Form.Label>
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  label="Yes"
                  name="babysufferedproblem"
                  value="Yes"
                  type="radio"
                  checked={studentPersonal.babysufferedproblem === "Yes"}
                  id={`inline-radio-1`}
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  label="No"
                  name="babysufferedproblem"
                  value="No"
                  type="radio"
                  checked={studentPersonal.babysufferedproblem === "No"}
                  id={`inline-radio-2`}
                  onChange={handleChange}
                />
               
                
              </div>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Baby was immunize as per schedule
              </Form.Label>
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  label="Yes"
                  name="babyimmunize"
                  value="Yes"
                  type="radio"
                  checked={studentPersonal.babyimmunize === "Yes"}
                  id={`inline-radio-1`}
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  label="No"
                  name="babyimmunize"
                  value="No"
                  type="radio"
                  checked={studentPersonal.babyimmunize === "No"}
                  id={`inline-radio-2`}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>

          <Col lg={6} className="mb-1">
                {babysuffer === true || studentPersonal.babysufferedproblem === 'Yes' ?
                  <Form.Group >
                    <Form.Label className="form-view-label">
                      Remark
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="babysufferingremark"
                      value={studentPersonal.babysufferingremark}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  : ''}
                </Col>
          <Col lg={12} className="section-header my-3">
            4. Other Details
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">Medical History</Form.Label>
              <Form.Control
                as="textarea"
                name="medicalhistory"
                value={studentPersonal.medicalhistory}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">Parents History</Form.Label>
              <Form.Control
                as="textarea"
                name="familyhistory"
                value={studentPersonal.familyhistory}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          {/* =====  ===== ===== =====
        <Col lg={12}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Pedigree chart :-{" "}
            </Form.Label>
            <Form.Control type="file" size="sm" />
            {/* <img src={file} class /> */}
          {/*</Form.Group>
        </Col>
         ===== ===== ===== ===== */}
          <Col lg={12} className="section-header my-3">
            5. Developement History
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Neck Holding : (1-4 months)
              </Form.Label>
              {/* <Form.Control
                type="text"
                name="neckholding"
                value={studentPersonal.neckholding}
                onChange={handleChange}
              /> */}

              <Form.Select
                name="neckholding"
                value={studentPersonal.neckholding}
                onChange={handleChange}
              >
                <option>-- Choose--</option>
                <option value="1">1 Month</option>
                <option value="2">2 Month</option>
                <option value="3">3 Month</option>
                <option value="4">4 Month</option>
                <option value="Delayed">Delayed</option>
                
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Mother Recognization : (1-4 Months)
              </Form.Label>
              {/* <Form.Control
                type="text"
                name="motherrecongnition"
                value={studentPersonal.motherrecongnition}
                onChange={handleChange}
              /> */}
              <Form.Select
                name="motherrecongnition"
                value={studentPersonal.motherrecongnition}
                onChange={handleChange}
              >
                <option>-- Choose--</option>
                <option value="1">1 Month</option>
                <option value="2">2 Month</option>
                <option value="3">3 Month</option>
                <option value="4">4 Month</option>
                <option value="Delayed">Delayed</option>

              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Smile : (1-4 Months)
              </Form.Label>
              {/* <Form.Control
                type="text"
                name="smile"
                value={studentPersonal.smile}
                onChange={handleChange}
              /> */}

              <Form.Select
                name="smile"
                value={studentPersonal.smile}
                onChange={handleChange}
              >
                <option>-- Choose--</option>
                <option value="1">1 Month</option>
                <option value="2">2 Month</option>
                <option value="3">3 Month</option>
                <option value="4">4 Month</option>
                <option value="Delayed">Delayed</option>

              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Sitting : (5-10 months)
              </Form.Label>
              {/* <Form.Control
                type="text"
                name="sitting"
                value={studentPersonal.sitting}
                onChange={handleChange}
              /> */}
              <Form.Select
                name="sitting"
                value={studentPersonal.sitting}
                onChange={handleChange}
              >
                <option>-- Choose--</option>
                <option value="5">5 Month</option>
                <option value="6">6 Month</option>
                <option value="7">7 Month</option>
                <option value="8">8 Month</option>
                <option value="9">9 Month</option>
                <option value="10">10 Month</option>
                <option value="Delayed">Delayed</option>

              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Standing : (9-14 Months)
              </Form.Label>
              {/* <Form.Control
                type="text"
                name="standing"
                value={studentPersonal.standing}
                onChange={handleChange}
              /> */}

              <Form.Select
                name="standing"
                value={studentPersonal.standing}
                onChange={handleChange}
              >
                <option>-- Choose--</option>
                <option value="9">9 Month</option>
                <option value="10">10 Month</option>
                <option value="11">11 Month</option>
                <option value="12">12 Month</option>
                <option value="13">13 Month</option>
                <option value="14">14 Month</option>
                <option value="Delayed">Delayed</option>

              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Walking : (9-14 Months)
              </Form.Label>
              {/* <Form.Control
                type="text"
                name="walking"
                value={studentPersonal.walking}
                onChange={handleChange}
              /> */}


              <Form.Select
                name="walking"
                value={studentPersonal.walking}
                onChange={handleChange}
              >
                <option>-- Choose--</option>
                <option value="9">9 Month</option>
                <option value="10">10 Month</option>
                <option value="11">11 Month</option>
                <option value="12">12 Month</option>
                <option value="13">13 Month</option>
                <option value="14">14 Month</option>
                <option value="Delayed">Delayed</option>

              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Babbling : (5-7 months)
              </Form.Label>
              {/* <Form.Control
                type="text"
                name="babbling"
                value={studentPersonal.babbling}
                onChange={handleChange}
              /> */}

              <Form.Select
                name="babbling"
                value={studentPersonal.babbling}
                onChange={handleChange}
              >
                <option>-- Choose--</option>
                <option value="5">5 Month</option>
                <option value="6">6 Month</option>
                <option value="7">7 Month</option>
                <option value="Delayed">Delayed</option>


              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                First Ward Phase : (7-12 Months)
              </Form.Label>
              {/* <Form.Control
                type="text"
                name="firstwardphase"
                value={studentPersonal.firstwardphase}
                onChange={handleChange}
              /> */}

              <Form.Select
                name="firstwardphase"
                value={studentPersonal.firstwardphase}
                onChange={handleChange}
              >
                <option>-- Choose--</option>

                <option value="7">7 Month</option>
                <option value="8">8 Month</option>
                <option value="9">9 Month</option>
                <option value="10">10 Month</option>
                <option value="11">11 Month</option>
                <option value="12">12 Month</option>
                <option value="Delayed">Delayed</option>

              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Two Ward Phase : (16-30 Months)
              </Form.Label>
              {/* <Form.Control
                type="text"
                name="twowardphase"
                value={studentPersonal.twowardphase}
                onChange={handleChange}
              /> */}

              <Form.Select
                name="twowardphase"
                value={studentPersonal.twowardphase}
                onChange={handleChange}
              >
                <option>-- Choose--</option>

                <option value="16">16 Month</option>
                <option value="17">17 Month</option>
                <option value="18">18 Month</option>
                <option value="19">19 Month</option>
                <option value="20">20 Month</option>
                <option value="21">21 Month</option>
                <option value="22">22 Month</option>
                <option value="23">23 Month</option>
                <option value="24">24 Month</option>
                <option value="25">25 Month</option>
                <option value="26">26 Month</option>
                <option value="27">27 Month</option>
                <option value="28">28 Month</option>
                <option value="29">29 Month</option>
                <option value="30">30 Month</option>
                <option value="Delayed">Delayed</option>

              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Sentance : (3-4 Months)
              </Form.Label>
              {/* <Form.Control
                type="text"
                name="sentence"
                value={studentPersonal.sentence}
                onChange={handleChange}
              /> */}

              <Form.Select
                name="sentence"
                value={studentPersonal.sentence}
                onChange={handleChange}
              >
                <option>-- Choose--</option>
                <option value="3">3 Month</option>
                <option value="4">4 Month</option>
                <option value="Delayed">Delayed</option>


              </Form.Select>
            </Form.Group>
          </Col>



          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Toilet Control : (3-4 Years)
              </Form.Label>
             
                <Form.Select
                name="toiletcontrol"
                value={studentPersonal.toiletcontrol}
                onChange={handleChange}
              >
                <option>-- Choose--</option>
                <option value="3">3 Year</option>
                <option value="4">4 Year</option>
                <option value="Delayed">Delayed</option>


              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={12} className="section-header my-3">
            6. Speech and Language Development history
          </Col>
          
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Repeat some vowel and consonant sounds (3-6 months)
              </Form.Label>
              <Form.Control
                type="text"
                name="vowelconsonantsounds"
                value={studentPersonal.vowelconsonantsounds}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Exchange Sounds,facial expressions,or gestures (3-6 months )
              </Form.Label>
              <Form.Control
                type="text"
                name="exchangesounds"
                value={studentPersonal.exchangesounds}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Pays attention to own name (3-6 months)
              </Form.Label>
              <Form.Control
                type="text"
                name="payattentionname"
                value={studentPersonal.payattentionname}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Plays peek-a-boo (3-6 months)
              </Form.Label>
              <Form.Control
                type="text"
                name="playspeekboo"
                value={studentPersonal.playspeekboo}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Begins Repetiative Babbling (6-9 months)
              </Form.Label>
              <Form.Control
                type="text"
                name="repetitivebabbling"
                value={studentPersonal.repetitivebabbling}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Associate gesture with owrds like 'hi' and 'bye bye' (6-9 months)
              </Form.Label>
              <Form.Control
                type="text"
                name="gestureswords"
                value={studentPersonal.gestureswords}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Use vocal and non vocal communication to (6-9 months)
              </Form.Label>
              <Form.Control
                type="text"
                name="vocalcommunication"
                value={studentPersonal.vocalcommunication}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Understand name of fimiliar people and objects (9-12 months)
              </Form.Label>
              <Form.Control
                type="text"
                name="understandnames"
                value={studentPersonal.understandnames}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Says a few words (9-12 months)
              </Form.Label>
              <Form.Control
                type="text"
                name="sayswords"
                value={studentPersonal.sayswords}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Reponds to firm 'no' (9-12 months)
              </Form.Label>
              <Form.Control
                type="text"
                name="respondfirmno"
                value={studentPersonal.respondfirmno}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Mimic simple actions (9-12 months)
              </Form.Label>
              <Form.Control
                type="text"
                name="mimicactions"
                value={studentPersonal.mimicactions}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">School History</Form.Label>
              <Form.Control
                as="textarea"
                name="schoolhistory"
                value={studentPersonal.schoolhistory}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Physical Deformity & sensory impairment
              </Form.Label>
              <Form.Control
                as="textarea"
                name="physicalimpairment"
                value={studentPersonal.physicalimpairment}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mx-3">
              <Form.Label className="form-view-label">
                Behavoiral Problem
              </Form.Label>
              <Form.Control
                as="textarea"
                name="behavioralproblems"
                value={studentPersonal.behavioralproblems}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          {/* Date Section */}
        </Row>
      </fieldset>
    </Container>
  );
};

export default PersonalInfoPartC;
