import React , {useEffect} from 'react'
import { useState } from 'react';
import inventoryApi from "../api/inventoryApi";

const PhysioSectionReport = (props) => {
    let student = props.data;
    let phsioQuestionMarks  = props.phsioQuestionMarks || '';
    console.log('phsioQuestionMarks ==> ',phsioQuestionMarks)    
    
  return (
    <div>
         <table style={{ width: "100%" }}>
        <tr>
          <td style={{ border: "None", textAlign: "center" }}>
            <h6> STUDENT PHYSIO REPORT  </h6>
            <h5>{student.serviceareaname}</h5>
            <h6>Supported By: Rajasthan Mahila Kalyan Mandal</h6>
          </td>
        </tr>
      </table>
    <table>
        <tr>
          <td style={{ width: "400px", border: "None" }}>
            <h5 style={{ fontSize: "15px" }}> PHYSIO Information </h5>
          </td>
          {/* <td style={{ width: "300px", border: "None" }}>
            Total Score : % 
          </td> */}
        </tr>
      </table>
      <table class="table table-bordered">
        <thead>
          <tr>
            <td style={{ width: "10%", backgroundColor: "#DFE0E1" }}>
              <b>No.</b>
            </td>
            <td style={{ width: "80%", backgroundColor: "#DFE0E1" }}>
              <b>Question</b>
            </td>
            <td style={{ width: "10%", backgroundColor: "#DFE0E1" }}>
              <b>Marks</b>
            </td>
          </tr>
        </thead>
        <tbody>
          {phsioQuestionMarks.length && phsioQuestionMarks?.map((item, index) => (
            <div>
              <tr key={index}>
                <td>{index + 1}</td>

                <td>{item.question}</td>
                <td>{item.marks}</td>
              </tr>
            </div>
          ))}
        </tbody>
      </table>
            
    </div>
  )
}

export default PhysioSectionReport
