import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../api/inventoryApi";
import PubSub from 'pubsub-js';
import moment from "moment"


const StaffMemberEdit = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [servicecategory, setSelectedCategory] = useState('');
    const [show, setShow] = React.useState(false);
    let userValue;

    const [managers, setManagers] = useState([]);
    const [validated, setValidated] = useState(false);
    const [staffMember, setStaffMember] = useState(location.state ? location.state : {});
    //(staffMember)
    const [serviceArea, setServiceArea] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [userRole, setUserRoleManager] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);
    ////(filteredItems)
    //('//////', servicecategory)
    //('area', serviceArea)
    const [staffMembe, setStaffMembe] = useState({
        usertype: "",
        servicearea: "",
    });
    const [filteredItemsRole, setFilteredItemsRole] = useState([]);

    useEffect(() => {
        handleUserRoleValues();
        async function init() {
            const result = await inventoryApi.fetchServiceArea();
            //('area', result)
            if (result) {
                setServiceArea(result);
                if (location?.state?.servicearea) {
                    let tempArray = result.length && result.filter(item => item.servicearea === location.state.servicearea);
                    //('manager', tempArray)
                    setFilteredItems(tempArray);
                }

            }
        }
        init();

        if (!staffMember.id) {
            staffMember.dob = moment(Date.now()).format('YYYY-MM-DD');
        }

    }, []);





    const handleUserRoleValues = async (e) => {
        const result = await inventoryApi.fetchManagers();
        //('MANAGER', result)
        if (result) {
            const activeMembers = result.filter((item) => item.isactive === true);
            setManagers(activeMembers);
            if (location?.state?.userrole) {
                //('======>', location?.state?.userrole)
                let username = location?.state?.userrole === 'USER' ? 'ADMIN' : 'SUPER_ADMIN';
                //('======>', username)
                let tempArray = result.length && result.filter(item => item.userrole === username);
                //('Temp array', tempArray)
                setFilteredItemsRole(tempArray);
            }
        }
    }

    const handleChange = async (e) => {

        const { name, value } = e.target;
        setStaffMembe((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        if (e.target.name === 'usertype') {

        }
        if (e.target.name === 'userrole') {
            //(e.target.value)
            setUserRoleManager(e.target.value)
            let tempArray = [...managers];
            //('tempArray', tempArray)
            let username = e.target.value === 'ADMIN' ? 'SUPER_ADMIN' : 'ADMIN';
            //('======>', username)
            setFilteredItemsRole(tempArray.length && tempArray.filter(item => item.userrole === username));

        }
        if (e.target.name === 'servicearea') {
            const { name, value } = e.target;
            setStaffMembe((prevState) => ({
                ...prevState,
                [name]: value,
            }));
            //('Yes')
            setSelectedCategory(e.target.value)
            let tempArray = [...serviceArea];
            //('tempArray', tempArray)
            setFilteredItems(tempArray.length && tempArray.filter(item => item.servicearea === e.target.value));
        }


        setStaffMember({ ...staffMember, [e.target.name]: e.target.value });
        //(staffMember)

    };

    const handleCancel = () => {
        //('====', staffMember)

        if (staffMember.id)
            navigate("/staffmembers/" + staffMember.id, { state: staffMember });
        else
            navigate("/staffmembers");
    };


    const checkRequredFields = () => {
        if (
            staffMember.firstname && staffMember.firstname.trim() !== ""
            &&
            staffMember.lastname && staffMember.lastname.trim() !== ""
            &&
            staffMember.email && staffMember.email.trim() !== ""
            &&
            staffMember.adharcard && staffMember.adharcard.trim() !== ""
            &&
            staffMember.dob !== ''
            &&
            staffMember.userrole && staffMember.userrole.trim() !== ""
            &&
            staffMember.servicearea && staffMember.servicearea.trim() !== "" &&

            staffMember.qualificatoin && staffMember.qualificatoin.trim() !== "" &&

            staffMember.usertype && staffMember.usertype.trim() !== ""
        ) {
            return false;
        }
        return true;
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        if (checkRequredFields()) {
            //('====true======');
            setValidated(true);
            return;
        }
        //('==========');
        let result = {};
        //(staffMember)
        if (staffMember.id) {
            //('====edit-====');
            result = await inventoryApi.saveStaffMember(staffMember);
            console.log('result ===> ',result)
            if (result.errors) {

                PubSub.publish('RECORD_ERROR_TOAST', { title: 'Error Saved', message: result.errors });

                // setShowError(true);
                // setErrorMessage(result.errors);
                return;
            }
            if (result.success) {
                //('if result true');
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record update successfully' });
                navigate(`/staffmembers/${staffMember.id}`, { state: staffMember });
            }
        }
        else {
            setShow(true);
            result = await inventoryApi.createStaffMember(staffMember);
            if (result.errors) {
                setShowError(true);
                setErrorMessage(result.errors);
                return;
            }
            if (result) {
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record Create successfully' });
                navigate(`/staffmembers/`, { state: result });
            }
        }
        //('result:', result);
        //(staffMember)

    };

    return (
        <Container className="view-form">
            <Row>
                <Col></Col>

                <Col lg={8} className="mb-5">
                    <Alert show={showError} variant="danger" className="error-alert">{errorMessage}</Alert>
                    <Form className="mt-3" noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="view-form-header align-items-center">
                            <Col lg={3}>
                                Staff Member
                            </Col>
                            <Col lg={9} className="d-flex justify-content-end">
                                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                                    Save
                                </Button>

                             
                                <Button
                                    className="btn-sm"
                                    variant="danger"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>

                            <Col lg={6}>

                                <Form.Group className="mx-3">
                                    <Form.Label
                                        className="form-view-label"
                                    >
                                        First Name
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="firstname"
                                        placeholder="Enter First Name"
                                        value={staffMember.firstname}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                            </Col>

                            <Col lg={6}>

                                <Form.Group className="mx-3">
                                    <Form.Label
                                        className="form-view-label"

                                    >
                                        Last Name
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="lastname"
                                        placeholder="Enter Last Name"
                                        value={staffMember.lastname}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mx-3">
                                    <Form.Label
                                        className="form-view-label"

                                    >
                                        Email
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="email"
                                        name="email"
                                        placeholder="Enter Email"
                                        value={staffMember.email}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mx-3">
                                    <Form.Label className="form-view-label">Password</Form.Label>
                                    <Form.Control
                                        required
                                        type="password"
                                        name="password"
                                        placeholder="Enter Your Password"
                                        value={staffMember.password}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>



                            <Col lg={6}>
                                <Form.Group className="mx-3">
                                    <Form.Label
                                        className="form-view-label"
                                    >
                                        Gender
                                    </Form.Label>
                                    <Form.Select
                                        name="gender"
                                        onChange={handleChange}
                                        value={staffMember.gender}
                                    >
                                        <option value="">-- Select --</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="other">Other</option>

                                    </Form.Select>
                                </Form.Group>
                            </Col>


                            <Col lg={6}>
                                <Form.Group className="mx-3">
                                    <Form.Label
                                        className="form-view-label"
                                    >
                                        Phone
                                    </Form.Label>
                                    <Form.Control
                                        type="phone"
                                        name="phone"
                                        placeholder="Enter Phone"
                                        value={staffMember.phone}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mx-3" >
                                    <Form.Label
                                        className="form-view-label"

                                    >
                                        Adhar Card
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="adharcard"
                                        placeholder="Enter Adhar Card"
                                        value={staffMember.adharcard}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>




                            <Col lg={6}>
                                <Form.Group className="mx-3">
                                    <Form.Label
                                        className="form-view-label"
                                    >
                                        DOB

                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="date"
                                        name="dob"
                                        placeholder="Enter DOB"
                                        value={moment(staffMember.dob).format('YYYY-MM-DD')}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>


                            <Col lg={6}>
                                <Form.Group className="mx-3">
                                    <Form.Label
                                        className="form-view-label"

                                    >
                                        Street
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="street"
                                        placeholder="Enter Street"
                                        value={staffMember.street}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mx-3" >
                                    <Form.Label
                                        className="form-view-label"

                                    >
                                        Qualification
                                    </Form.Label>
                                    <Form.Select
                                        required
                                        name="qualificatoin"
                                        onChange={handleChange}
                                        value={staffMember.qualificatoin}
                                    >
                                        <option>---Select---</option>
                                        <option value="Primary">Primary</option>
                                        <option value="Middle">Middle</option>
                                        <option value="Secondary">Secondary</option>
                                        <option value="Senior Secondary">Senior Secondary</option>
                                        <option value="Graduate">Graduate</option>
                                        <option value="Post Graduate">Post Graduate</option>

                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col lg={6}>
                                <Form.Group className="mx-3">
                                    <Form.Label
                                        className="form-view-label"

                                    >
                                        Professional Qualification
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="professionalqualication"
                                        placeholder="Enter Professional Qualification"
                                        value={staffMember.professionalqualication}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg={6}>
                                <Form.Group className="mx-3">
                                    <Form.Label
                                        className="form-view-label"
                                    >
                                        City
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="city"
                                        placeholder="Enter city"
                                        value={staffMember.city}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="mx-3" controlId="formBasicServiceArea">
                                        <Form.Label
                                            className="form-view-label"
                                        >
                                            Service Area
                                        </Form.Label>
                                        <Form.Select
                                            required
                                            aria-label="Enter Service Area"
                                            name="servicearea"
                                            value={staffMember.servicearea}
                                            onChange={handleChange}
                                        >
                                            <option value="">--Choose--</option>
                                            <option value="School">School </option>
                                            <option value="CBR">CBR</option>
                                            <option value="Day Care">Day Care</option>

                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>

                            <Col lg={6}>
                                    <Form.Group className="mx-3" controlId="formBasicType">
                                        <Form.Label
                                            className="form-view-label"
                                        >
                                            Service Category
                                        </Form.Label>
                                        <Form.Select
                                            required
                                            name="servicecategory"
                                            value={staffMember.servicecategory}
                                            onChange={handleChange}
                                        >
                                            <option value="">--Choose--</option>
                                            {filteredItems && filteredItems.length && filteredItems.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))}

                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                              

                                <Col lg={6}>
                                    <Form.Group className="mx-3" controlId="formBasicUserType">
                                        <Form.Label className="form-view-label">
                                            User Type
                                        </Form.Label>
                                        <Form.Select
                                            required
                                            name="usertype"
                                            onChange={handleChange}
                                            value={staffMember.usertype}
                                            disabled={staffMember.servicearea === ""}
                                        >
                                            <option>-- Select --</option>
                                            {staffMember.servicearea === "CBR" && (
                                                <React.Fragment>
                                                    <option value="CBR Worker">CBR Worker</option>
                                                    <option value="CMH">CMH</option>
                                                    <option value="CMM">CMM</option>
                                                    <option value="Phsyico Therepist">Phsyico Therepist</option>
                                                    <option value="Psychologist">Psychologist</option>
                                                    <option value="Speech Therapist">Speech Therapist</option>
                                                    <option value="Vocational Teacher">Vocational Teacher</option>

                                                </React.Fragment>
                                            )}
                                            {staffMember.servicearea === "School" && (
                                                <React.Fragment>
                                                    <option value="Centre In charge">Centre In charge</option>

                                                    <option value="Special Educator">Special Educator</option>
                                                    <option value="Inclusive Teacher">Inclusive Teacher</option>
                                                    <option value="Physiotherapist">Physiotherapist</option>
                                                    <option value="Psychologist">Psychologist</option>
                                                    <option value="Speech Therapist">Speech Therapist</option>
                                                    <option value="Vocational Teacher">Vocational Teacher</option>
                                                </React.Fragment>
                                            )}
                                            {staffMember.servicearea === "Day Care" && (
                                                <React.Fragment>
                                                    <option value="Centre In charge">Centre In charge</option>
                                                    <option value="Special Educator">Special Educator</option>
                                                    <option value="Inclusive Teacher">Inclusive Teacher</option>
                                                    <option value="Physiotherapist">Physiotherapist</option>
                                                    <option value="Psychologist">Psychologist</option>
                                                    <option value="Speech Therapist">Speech Therapist</option>
                                                    <option value="Vocational Teacher">Vocational Teacher</option>
                                                </React.Fragment>
                                            )}

                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Col lg={6}>
                                <Form.Group className="mx-3">
                                    <Form.Label className="form-view-label">Assign User Role</Form.Label>
                                    <Form.Select name="userrole" onChange={handleChange} required value={staffMember.userrole}>
                                        <option value="">-- Select --</option>
                                        {localStorage.getItem('userrole') === 'ADMIN' ? (
                                            <option value="USER">User</option>
                                        ) : (
                                            <>
                                                <option value="ADMIN">Admin</option>
                                                <option value="USER">User</option>
                                            </>
                                        )}
                                    </Form.Select>
                                </Form.Group>

                            </Col>
                            {/* <Col lg={6}>
                                <Form.Group className="mx-3">
                                    <Form.Label className="form-view-label">Role</Form.Label>
                                    <Form.Select name="userrole" onChange={handleChange} >
                                        <option value="">-- Select --</option>
                                        {localStorage.getItem('userrole') === 'ADMIN' ? (
                                            <option value="USER">User</option>
                                        ) : (
                                            <>
                                            <option value="SUPER_ADMIN">Admin</option>
                                            <option value="ADMIN">User</option>
                                          </>
                                        )}
                                    </Form.Select>

                                </Form.Group>

                            </Col> */}
                            <Col lg={6}>
                                <Form.Group className="mx-3">
                                    <Form.Label
                                        className="form-view-label"
                                    >
                                        Select Manager

                                    </Form.Label>
                                    <Form.Select

                                        name="manager"
                                        onChange={handleChange}
                                        value={staffMember.manager}
                                    >

                                        <option value="">-- Select --</option>
                                        {filteredItemsRole?.map((item, index) => (
                                            <option value={item.id} key={item.id}>{item.username}</option>
                                        ))}

                                    </Form.Select>
                                </Form.Group>
                            </Col>



                        </Row>
                    </Form>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    );
};

export default StaffMemberEdit;