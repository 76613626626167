import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as constants from "../constants/CONSTANT";
import { useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  return (
    <>
      <nav id="sidebar" style={{ fontSize: "small" }}>
        <div className="sidebar-header text-center">
          <div className="pb-1"></div> <h3>RMKM</h3>
        </div>

        <ul class="list-unstyled components">
          <li className={`${location.pathname === '/' ? 'active' : ''}`}>
            <Link to="/">
              <i class="fa-solid fa-home mx-2 fa-fw"></i>Dashboard

            </Link>
          </li>


          {localStorage.getItem("permissions") &&
            (localStorage
              .getItem("permissions")
              .indexOf(constants.VIEW_SERVICE) >= 0 ||
              localStorage.getItem("permissions").indexOf(constants.MODIFY_ALL) >=
              0) ? (
            <li className={`${location.pathname.includes('/serviceareas') ? 'active' : ''}`}>
              <Link to="/serviceareas">
                <i class="fa-solid fa-building mx-2 fa-fw"></i>Service Category
              </Link>
            </li>
          ) : (
            ""
          )}
          {localStorage.getItem("permissions") &&
            (localStorage.getItem("permissions").indexOf(constants.VIEW_STAFF) >=
              0 ||
              localStorage.getItem("permissions").indexOf(constants.MODIFY_ALL) >=
              0) ? (
            <li className={`${location.pathname.includes('/staffmembers') ? 'active' : ''}`}>
              <Link to="/staffmembers">
                {" "}
                <i class="mx-2 fa-solid fa-users fa-fw"> </i>Staff Management
              </Link>
            </li>
          ) : (
            ""
          )}

          <li>
            {localStorage.getItem("permissions") &&
              (localStorage
                .getItem("permissions")
                .indexOf(constants.VIEW_STUDENT) >= 0 ||
                localStorage.getItem("permissions").indexOf("MODIFY_ALL") >=
                0) ? (
              <li className={`${location.pathname.includes('/students') ? 'active' : ''}`}>
                <Link to="/students">
                  {" "}
                  <i class="fa-solid fa-child-reaching mx-2 fa-fw"></i>Case History
                </Link>
              </li>
            ) : (
              ""
            )}
          </li>
          {localStorage.getItem("permissions") &&
            (localStorage.getItem("permissions").indexOf(constants.VIEW_ADMIN) >=
              0 ||
              localStorage.getItem("permissions").indexOf("MODIFY_ALL") >= 0
              || localStorage.getItem("permissions").indexOf("VIEW_QUESTION") >= 0) ? (
            <li>
              <a
                href="#AssesmentsSubmenu"
                data-toggle="collapse"
                aria-expanded="false"
                class="dropdown-toggle"
              >
                <i class=" mx-2 fa-regular fa-file-lines fa-fw" aria-hidden="true"></i>Questions
              </a>
              <ul class="collapse list-unstyled" id="AssesmentsSubmenu">
                {localStorage.getItem("permissions") &&
                  (localStorage.getItem("permissions").indexOf(constants.VIEW_IEP) >=
                    0 ||
                    localStorage.getItem("permissions").indexOf(constants.MODIFY_ALL) >=
                    0) ? (
                  <li className={`${location.pathname.includes('/ieps') ? 'active' : ''}`}>
                    <Link to="/ieps" style={{ fontSize: "small" }}>
                      <div style={{ marginLeft: "55px" }}>IEP</div>
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                <li className={`${location.pathname.includes('/questions') ? 'active' : ''}`}>
                  <Link to="/questions" style={{ fontSize: "small" }}>
                    <div style={{ marginLeft: "55px" }}>All Questions</div>
                  </Link>
                </li>

              </ul>
            </li>
          ) : (
            ""
          )}


          {localStorage.getItem("permissions") &&
            (localStorage.getItem("permissions").indexOf(constants.VIEW_ASSESSMENT) >=
              0 || localStorage.getItem("permissions").indexOf(constants.MODIFY_ALL) >=
              0) ? (
            <li className={`${location.pathname === '/assessments' ? 'active' : ''}`}>
              <Link to="/assessments"> <i class="fa-solid fa-list-check mx-2 fa-fw"></i>Assessment</Link>
            </li>
          ) : (
            ""
          )}

          {/* {localStorage.getItem("permissions") &&
            (localStorage.getItem("permissions").indexOf(constants.VIEW_REPORT) >=
              0 ||
              localStorage.getItem("permissions").indexOf(constants.MODIFY_ALL) >=
              0) ? (
            <li className={`${location.pathname.includes('/reports') ? 'active' : ''}`}>
              <Link to="/reports">
                <i class="fa-solid fa-chart-simple mx-2 fa-fw"></i>Reports
              </Link>
            </li>
          ) : (
            ""
          )} */}



          <li>
            <a
              href="#ReportSubmenu"
              data-toggle="collapse"
              aria-expanded="false"
              class="dropdown-toggle"
            >
              <i class=" mx-2 fa-regular fa-file-lines fa-fw" aria-hidden="true"></i>Reports
            </a>
            <ul class="collapse list-unstyled" id="ReportSubmenu">

              <li >
                <Link to="/homebasedcbr" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "55px" }}>Home Based CBR</div>
                </Link>
              </li>

              <li className={`${location.pathname.includes('/questions') ? 'active' : ''}`}>
                <Link to="/cbrworkerreport" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "55px" }}>CBR Worker</div>
                </Link>
              </li>

              <li>
                <Link to="/cbrcmhworkerreports" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "55px" }}>CBR CMH Worker</div>
                </Link>
              </li>

              <li>
                <Link to="/cbrcmmworkerreports" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "55px" }}>CBR CMM Worker</div>
                </Link>
              </li>


            </ul>
          </li>


          {localStorage.getItem("permissions") &&
            (localStorage.getItem("permissions").indexOf(constants.VIEW_ATTENDANCE) >= 0) ? (

            <li className={`${location.pathname === '/attendance' ? 'active' : ''}`}>
              <Link to="/attendance"> <i class="fa-solid fa-location-dot mx-2 fa-fw"></i>Attendance</Link>
            </li>
          ) : (
            ""
          )}

          <li className={`${location.pathname.includes('/editprofiles') ? 'active' : ''}`}>
            <Link to="/editprofiles"> <i className="fa fa-user mx-2"></i>My profile</Link>
          </li>


          {/* <li className={`${location.pathname === '/changepassword' ? 'active' : ''}`}>
            <Link to="/changepassword"><i className="fa-solid fa-key mx-2"></i>Change Password</Link>
          </li> */}

        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
