import React, { useState} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../api/inventoryApi";


const FilesCreate = (props) => {
  const [validated, setValidated] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [selectType, setSelectType] = useState("");
  const [fileDescription, setFileDescription] = useState();
  

  const handleChange = (event) => {
    setSelectedFiles(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(props.parent);
    console.log('fileDescription:', fileDescription);
    console.log('selectType:', selectType);
    if (selectedFiles && selectType) {
      setValidated(false);
      console.log("files", selectedFiles);
      console.log("description", selectType);
      const result = await inventoryApi.createFiles(selectedFiles,fileDescription,selectType, props.parent.id);
      console.log(":result ",result)
      if (result.length > 0) {
        props.submitfiles();
      }
    } else {
      setValidated(true);
    }
  }

  

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          File Upload
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form
            className="mt-3"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
        <Form.Group className="mx-3">
          <Form.Label className="form-view-label">Select Type</Form.Label>
          {props.objectName === "staffMember"  &&
          <Form.Select
            required
            aria-label="Enter Select option"
            name="selecttype"
            value={selectType}
            onChange={(e) => setSelectType(e.target.value)}
          >
            <option value="">Select Option</option>
            <option value="Image">Image</option>
            <option value="Signature">Signature</option>
          </Form.Select>
          }

          {props.objectName === "student"  &&
          <Form.Select
            required
            aria-label="Enter Select option"
            name="selecttype"
            value={selectType}
            onChange={(e) => setSelectType(e.target.value)}
          >
            <option value="">Select Option</option>
            filteredItems

            <option value="Adhar Card">Adhar Card</option>
            <option value="Astha Card">Astha Card</option>
            <option value="BPL Card">BPL Card</option>

            <option value="Brith Certificate">Brith Certificate</option>
            <option value="Current Child Photo">Current Child Photo</option>
            <option value="Disability Certificate">Disability Certificate</option>
            <option value="Niramaya Enrollment">Niramaya Enrollment</option>
            <option value="Pension Status">Pension Status</option>
            <option value="Pedigree chart">Pedigree chart</option>
            <option value="UID Registration">UID Registration</option>
            <option value="5 to 10 Sec. video Clip of Child">
              5 to 10 Sec. video Clip of Child
            </option> 
          </Form.Select>
          }
          <Form.Control.Feedback type="invalid">
            Please provide a valid Select Type.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mx-3">
        <Form.Label className="form-view-label"></Form.Label>
          <Form.Control type="file" name="file" onChange={handleChange} required />
          <Form.Control.Feedback type="invalid">
              Please file upload.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mx-3" style={{paddingTop:"13px"}}>
          <Form.Label
            className="form-view-label"
            htmlFor="formBasicDescription"
          >
            Description
          </Form.Label>
          <Form.Control
            as="textarea"
            name="description"
            placeholder="Enter description"
            onChange={(e) => setFileDescription(e.target.value)}
          />
        </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="submit">
          <Button variant="success" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
        <Button onClick={props.onHide} variant="light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default FilesCreate;
