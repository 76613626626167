import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../api/inventoryApi";
import moment from "moment"
import PubSub from 'pubsub-js';

const IEPEdit = (props) => {
  const [validated, setValidated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [iep, setIEP] = useState({});
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [show, setShow] = React.useState(false);
  const [errorMessage,setErrorMessage] = useState();
  useEffect(() => {
    localStorage.getItem('ADMIN');
    if (location?.state?.row) {
        setIEP(location.state.row);
    } else {
        setIEP({...iep,startdate: defaultDate ,enddate : defaultDate});
    }
  }, []);

  

  //Access all the value input values
  const handleChange = (e) => {
    setIEP({ ...iep, [e.target.name]: e.target.value });
  };

  const checkRequredFields = () => {
    if (
      iep.startdate !== ""
      &&
      iep.enddate !== ""
      &&
      iep.period && iep.period.trim() !== ""
      &&
      iep.remarks && iep.remarks.trim() !== ""
      &&
      iep.status && iep.status.trim() !== ""

    ) {
      return false;
    }
    return true;
  };
 




  const handleSubmit = async (event) => {
    event.preventDefault();

    if (checkRequredFields()) {
      console.log('====true======');
      setValidated(true);
      return;
    }

    if(iep){
     
        if(iep?.id){
          const result = await inventoryApi.saveIEP(iep);
          if(result.errors){
            setErrorMessage(result.errors);
            setShow(true);
          }else{
            PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record create successfully' });

            navigate(`/ieps`);
          }
          
        }else{
          const resultData = await inventoryApi.createIEP(iep);
          if(resultData.errors){
            setErrorMessage(resultData.errors);
            setShow(true);
          }else{
            PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record create successfully' });

            navigate(`/ieps`);
          }
          
        }
        
    }
  };

  const handleCancel = () => {
    navigate(`/ieps`);
  };

  return (
    <Container className="view-form">
      <Alert variant="danger" show = {show}>{errorMessage}</Alert>
      <Row style={{ paddingLeft: "10px" }}>
        <Col lg={10}>
          
        </Col>
      </Row>
      <Row>
        <Col></Col>
        <Col lg={8}>
          <Form
            className="mt-3"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            
            <Row className="view-form-header align-items-center">
              <Col lg={3}>
                <h5>IEP</h5>
              </Col>
              <Col lg={9} className="d-flex justify-content-end">
                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                  Save
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            
            <Row>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicStartDate">
                    <Form.Label
                        className="form-view-label"
                    >
                        Start Date
                    </Form.Label>

                    <Form.Control
                        required
                        type="date"
                        name="startdate"
                        placeholder="Enter Start Date"
                        value={moment(iep.startdate).format('YYYY-MM-DD')}
                     
                        onChange={handleChange}
                    />
                     <Form.Control.Feedback type="invalid">
                    Please provide a valid start date.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicenddate">
                    <Form.Label
                        className="form-view-label"
                    >
                        End Date
                    </Form.Label>

                    <Form.Control
                        required
                        type="date"
                        name="enddate"
                        placeholder="Enter End Date"
                        value={moment(iep.enddate).format('YYYY-MM-DD')}
                        defaultValue={defaultDate}
                        onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                    Please provide a valid end date.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicperiod">
                  <Form.Label className="form-view-label">
                    IEP Period
                  </Form.Label>

                  <Form.Select
                    aria-label="Enter Select option"
                    name="period"
                    value={iep.period}
                    required
                    onChange={handleChange}
                  >
                    <option value="">-- Select--</option>
                    
                    <option value="JAN to JULY">JAN  to JULY</option>
                    <option value="AUG to DEC">AUG to DEC</option>
                    <option value="JAN to MAR">JAN   to MAR</option>
                    <option value="APR to JUNE">APR  to JUNE</option>
                    <option value="JULY to SEP">JULY to SEP</option>
                    <option value="OCT to DEC">OCT   to DEC</option> 
                    <option value="Entrance Test">Entrance Test</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid select section.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicRemark">
                  <Form.Label className="form-view-label">Remarks</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="remarks"
                    placeholder="Enter Remarks"
                    value={iep.remarks}
                    required
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid remarks.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicStatus">
                  <Form.Label className="form-view-label">
                   Status
                  </Form.Label>

                  <Form.Select
                    name="status"
                    value={iep.status}
                    required
                    onChange={handleChange}
                  >
                    <option value="">-- Select --</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid select section.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            
          </Form>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default IEPEdit;
