import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../api/inventoryApi";
import AssignQuestions from "./AssignQuestions";

const Assessmentedit = () => {
  const [validated, setValidated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState(location.state ? location.state : {});

  useEffect(() => {
    if (location?.state?.row) {
        setQuestions(location.state.row);
    } else {
        setQuestions({});
    }
  }, []);

  const [staffMember, setStaffMember] = useState([]);

  useEffect(() => {
    async function init() {
      const result = await inventoryApi.fetchStaffMember();
      console.log(result)
      if (result) {
        setStaffMember(result);

      } else {
        setStaffMember([]);

      }

    }
    init();


  }, []);



  //Access all the value input values
  const handleChange = (e) => {
    setQuestions({ ...questions, [e.target.name]: e.target.value });
  };

  //
  const handleSubmit =  async(event) => {
    if(questions){
        let result = await inventoryApi.saveAssessments(questions);
            console.log("result", result);
            if (result.success) {
                console.log("enter in if");
                navigate(`/assessments/${questions.id}`, { state: questions });
            }
  };
}

  const handleCancel = () => {
    navigate(`/assessments`);
  };

  return (
    <Container className="view-form">
      <Row>
        <Col></Col>
        <Col lg={8}>
          <Form
            className="mt-3"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Row className="view-form-header align-items-center">
              <Col lg={3}>
                <h5>Assessment</h5>
              </Col>
              <Col lg={9} className="d-flex justify-content-end">
                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                  Save
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicName">
                  <Form.Label className="form-view-label">Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter Name"
                    value={questions.name}
                    required
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicperiod">
                  <Form.Label className="form-view-label">
                    Staff
                  </Form.Label>

                  <Form.Select
                    name="period"
                    value={questions.staffname}
                    onChange={handleChange}
                  >
                   <option value="">--Select Staff--</option>
                    {staffMember?.map((item, index) => (
                      <option value={item.id} key={item.id}>{item.username}</option>
                    ))}

                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid period.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className="mx-3" controlId="formBasicperiod">
                  <Form.Label className="form-view-label">
                    Period
                  </Form.Label>

                  <Form.Select
                    aria-label="Enter Select option"
                    name="period"
                    value={questions.period}
                    required
                    onChange={handleChange}
                  >
                    <option value="">-- Select --</option>
                    <option value="JAN to JUNE">JAN to JUNE</option>
                    <option value="JULY to DEC">JULY to DEC</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid period.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col></Col>
      </Row>
     
    </Container>
  );
};

export default Assessmentedit;
