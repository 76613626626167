import React from 'react'
import{ useState, useEffect } from "react";
import { Button, Row } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import inventoryApi from "../api/authApi";
import ModelPopUp from './ModelPopUp';
import Container from 'react-bootstrap/Container';
import ToastMessage  from './ToastMessage';
import Col from 'react-bootstrap/Col';
const RolesList = () => {
    const [roletable, setroleTable] = useState([]);
    const [roleListForInsert, setRoleList] = useState([]);
    const [roleListForUpdate, setupdateRoleList] = useState([]);
    const [isModelPopup, setModlePopup] = useState(false);
    const [isToast, setToast] = useState(false);
    const [isChange, setChange] = useState(false);
    const [messageForToast, setMessageToast] = useState('');
    const [rolesId, setRolesId] = useState('');
    const [gettingRoles, setgettingRoles] = useState([]);
    async function handleDelete(ids,id) {
        if(id != null && id !== ''){
            const result = await inventoryApi.deleteRole(id);
            if(result.success){
                setChange(!isChange)
                setMessageToast('Successfully record Deleted');
                setToast(true);
            }
            
        }else{
            setroleTable(roletable.filter((role)=>{
                return  ( role.ids !== ids);
              }))
        }
    }

    function handleAddNew() {
        setroleTable([...roletable, {ids : roletable.length+1}])
    }

    async function handleSave() {
        setRoleList([]);
        setupdateRoleList([]);
        let roleList = document.querySelectorAll(".input")
        console.log(roleList)
        roleList.forEach((element) => {
            console.log(element)
            if(element.vaue !== null && element.value !== '' && (element.dataset.id === '' || element.dataset.id == null ||  element.dataset.id === undefined)){
                roleListForInsert.push({name:element.value, description:'Test'})
            }
            var updateRec = gettingRoles.find((role)=>{
                return role.id ===  element.dataset.id
            });

            if(updateRec !== undefined && updateRec.name !== element.value  && element.value !== null && element.value !== ''  &&  element.dataset.id !== '' && element.dataset.id !== null &&  element.dataset.id !== undefined){
                roleListForUpdate.push({name:element.value, description:'Test', id:element.dataset.id })
            }
        });
        console.log('roleListForInsert' ,roleListForInsert);
        console.log('roleListForUpdate' ,roleListForUpdate);
        if(roleListForInsert.length > 0){
            const result = await inventoryApi.createRoles(roleListForInsert);
            
            console.log(result)
            if(result.hasOwnProperty('errors')){
                alert(result.errors[0].msg)
            }
            else if(result){
                console.log(result)
                setMessageToast('Successfully record created');
                setToast(true)
                setChange(!isChange)
            }
        }
        if(roleListForUpdate.length > 0){
            setChange(!isChange);
            console.log('roleListForUpdate',roleListForUpdate)
            const resultUpdate = await inventoryApi.saveRole(roleListForUpdate);
            console.log(resultUpdate)
        }
        

    }
    function handleAssignPermission(id){
        console.log('id',id)
        setRolesId(id);
        setModlePopup(true)
    }

    function handleClose() {
        setModlePopup(false)
        setToast(false);
    }
    useEffect(() => {
        async function init() {
          const roles = await inventoryApi.fetchRoles();
        //   this.gettingRoles =roles;
          console.log('roes',roles)
          if (roles)
            setroleTable(roles);
            setgettingRoles(roles)  
            
        }
        init();
    
      }, [isChange]);


    function onTodoChange(event){
       if(event.target.dataset.id !== '' && event.target.dataset.id !== undefined){
       console.log('evetn',event)
        setroleTable(roletable.map((data)=>{
            if(data.id ===  event.target.dataset.id){
                    return {...data, name :event.target.value }
            } else{
                return data
            }        
            }));
        }
        console.log(roletable)
    }
  return (
    <>
    <Container>
        <Row className="justify-content-md-center">
            <Col >
            <h3><b>Roles</b></h3>
            </Col>
            <Col>
            <Button variant="primary" onClick={handleAddNew}>Add New</Button>{' '}
            </Col>   
        </Row>
              
    </Container>
    <Container style={{padding:"2rem"}}>
        {roletable && roletable.map((role)=>{

        return   (
            <Row>
                <Col sm={6}>
                    <Form.Group className="mb-3">
                    <Form.Control placeholder="Roles Name" data-id={role.id} value={role.name} onChange={e => onTodoChange(e)} className="input"/>
                    </Form.Group>
                </Col>
                <Col sm={1}>
                    <Button className='btn-sm mx-2' variant='danger' onClick={()=>{handleDelete(role.ids,role.id)}}><i class="fa-regular fa-trash-can"></i></Button>
                </Col>
                <Col>
                <Button className='ms-10' variant="primary" onClick={()=>handleAssignPermission(role.id)}>Assign Permissions</Button>{' '}
                </Col>
            </Row>
            
            )
        })}  
    </Container>
    <div class="col-md-6 text-center">
        <Button variant="primary" style={{width:"5rem"}}  onClick={handleSave}>Save</Button>{' '}
    </div>
        
        {
           
           isModelPopup && <ModelPopUp show={isModelPopup} handleClose={handleClose} roleid={rolesId}/>
       } 
        {
           
           isToast && <ToastMessage show={isToast} handleClose={handleClose} msg={messageForToast}/>
       }
    </>
  )
}


export default RolesList;