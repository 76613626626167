import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import moment from "moment";

import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";

const HomeBasedCbrList = () => {
    const navigate = useNavigate();
    const [body, setBody] = useState([]);
    useEffect(() => {
        async function init() {
            const result = await inventoryApi.fetchHomeBasedCbrRecs();
            if (result && result.length) {
                setBody(result);
            } else {
                setBody([]);
            }
        }
        init();
    }, []);

    const header = [
        {
            title: "Child Name",
            prop: "name",
            childname: true,
            isFilterable: true,
            cell: (row) => (
                <Link to={"/homebasedcbr/" + row.id + `/e`} state={row}>
                    {row.name}
                </Link>
            ),
        },
        { title: "Service Area", prop: "servicearea", isFilterable: true },
        // { title: 'Child Name', prop: 'childname', childname: true },
        { title: "Cbr Worker Name", prop: "cbrworker", isFilterable: true },
        { title: "Date", prop: "date", isFilterable: true},
    ];

    const labels = {
        beforeSelect: "",
    };

    const createHomeBasedCbr = () => {
        navigate(`/homebasedcbr/e`);
    };

    return (
        <Row className="g-0">
            <Col lg={2} className="mx-2">
                <Link className="nav-link">
                    Home <i class="fa-solid fa-chevron-right"></i> CBR List
                </Link>
            </Col>
            <Col lg={12} className="p-lg-4">
                {body ? (
                    <DatatableWrapper
                        body={body}
                        headers={header}
                        paginationOptionsProps={{
                            initialState: {
                                rowsPerPage: 50,
                                options: [5, 10, 15, 20],
                            },
                        }}
                    >
                        <Row className="mb-4">
                            <Col
                                xs={12}
                                lg={4}
                                className="d-flex flex-col justify-content-end align-items-end"
                            >
                                <Filter />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-start align-items-start"
                            >
                                <PaginationOptions labels={labels} />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-end align-items-end"
                            >
                                <Button
                                    className="btn-sm"
                                    variant="outline-primary"
                                    onClick={() => createHomeBasedCbr(true)}
                                >
                                    Create Home Based CBR
                                </Button>
                            </Col>
                        </Row>
                        <Table striped className="data-table">
                            <TableHeader />
                            <TableBody />
                        </Table>
                        <Pagination />
                    </DatatableWrapper>
                ) : (
                    ""
                )}
            </Col>
            <Col lg={2}></Col>
        </Row>
    );
};

export default HomeBasedCbrList;