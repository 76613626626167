import React, { useState, useEffect } from "react";
const FacpSectionResult = (props) => {

    let student = props.data;
    console.log(props.reportInfo)
    let reportInfoData = props.reportInfo || '';
    console.log('reportInfoData', reportInfoData)
    const uniqueCategories = [...new Set(reportInfoData.map((item) => item.items.category))];


    return (

        <div className="" style={{ fontSize: "10px" }}>
            <table style={{ width: '100%' }}>
                <tr>
                    <td style={{ border: "None", textAlign: 'center' }}>
                        <h5>{student.servicecategoryname}</h5>
                        <h6>Supported By: Rajasthan Mahila Kalyan Mandal</h6>
                    </td>
                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ width: "250px", border: "None" }}>
                        <b>Name Of Speech Pathologist / Teachers : </b>
                    </td>
                    <td style={{ width: "120px", border: "None" }}></td>
                    <td style={{ width: "100px", border: "None" }}></td>
                    <td style={{ width: "100px", border: "None" }}>
                        <b>Name Of Project : </b>
                    </td>
                </tr>
            </table>

            <table>
                <tr>
                <td style={{ width: "250px", border: "None" }}>
                    <b>Date :  </b>
                </td>
                <td style={{ width: "120px", border: "None" }}></td>
                <td style={{ width: "100px", border: "None" }}></td>
                <td style={{ width: "100px", border: "None" }}>
                    <b>CBR Field Area : </b>
                </td>
                </tr>
            </table>
            <div>
                {uniqueCategories.map((category, index) => (
                    <div key={index}>
                        <b>
                            <h5>{category}</h5>
                        </b>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th style={{ width: "130px" }}>Sr.No</th>
                                    <th style={{ width: "130px" }}>Area</th>
                                    <th style={{ width: "130px" }}>Total Function</th>
                                    <th style={{ width: "130px" }}>Current Level</th>
                                    <th style={{ width: "130px" }}>Percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reportInfoData
                                    .filter((item) => item.items.category === category)
                                    .map((data, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{data.items.section}</td>
                                            <td>{data.items.count}</td>
                                            <td>{data.items.sum}</td>
                                            <td>{((data.items.sum / (data.items.count * 100)) * 100).toFixed(2)}%</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>
        </div>

    )
}

export default FacpSectionResult