import React from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import inventoryApi from '../api/inventoryApi';
import PubSub from 'pubsub-js';
import * as constants from "../constants/CONSTANT";

const Questions = () => {

    const navigate = useNavigate();
    const location = useLocation();
    console.log('location => ', location.state);
    const [student, setStudent] = useState(location.state ? location.state : {});
    // const [isEditable, setIsEditable] = useState(localStorage.getItem("permissions") &&
    //                                              localStorage.getItem("permissions").indexOf(constants.EDIT_SCORE) >= 0 
    //                                              ? true : false);
    const [isEditable, setIsEditable] = useState(true);
    const [newQuestions, setNewQuestions] = useState({});
    const [assessmentId, setAssessmentId] = useState();
    const [assessmentName, setAssessmentName] = useState();
    const [questionsMarks, setQuestionsMarks] = useState([]);
    const [phsyoMarks, setphsyoMarks] = useState([]);

    useEffect(() => {
        if (location.hasOwnProperty('pathname') && location.pathname.split('/').length >= 3) {
            student.assessmentid = location.pathname.split('/')[4];
        }
        console.log('student.assessmentid:', student.assessmentid);
        if (student.id && student.qstype) {
            async function init() {
                let options = null;
                // get assessment question according to type ,Student id ,assessment id 
                let questions = await inventoryApi.getAssessmentQuestions(student.qstype, student.id, student.assessmentid);
                console.log('getAssessmentQuestions', questions);
                setAssessmentId(questions[0].assessmentid);
                setAssessmentName(questions[0].assessmentname);
                console.log('qstype', student.qstype)
                if (student.qstype === 'PHYSIO'){
                    console.log('fetch question by category')
                    options = await inventoryApi.fetchOptionsByCategory(student.qstype);
                    console.log('options=> ', options);
                    setphsyoMarks(options);

                }
                let marks = await inventoryApi.getMarks(student.id, questions[0].assessmentid);
                console.log('questions => ', questions);
                console.log('marks => ', marks);
                if (questions && questions?.length > 0) {
                    modifyQuestion(questions, marks, options);

                } else {
                    setQuestionsMarks([]);
                }
            }
            init();
        }
    }, [])

    const modifyQuestion = ((questionsArr, marksArr, optionsArr) => {
        let qsAr = [];
        questionsArr.forEach((question) => {
            let qsObj = question;
            qsObj.marks = null;
            if (marksArr) {
                marksArr.forEach((mark) => {
                    if (qsObj.id === mark.questionid) {
                        qsObj.marks = mark.marks;
                        qsObj.selectedoptionid = mark.selectedoptionid;
                    }
                })
            }

            qsObj.options = null;
            if (optionsArr) {

                optionsArr.forEach((option) => {
                    if (qsObj.id === option.relatedoption.id) {

                        qsObj.options = option.relatedoption.options;
                        // qsObj.selectedoptionid = option.
                    }
                })
                //qsObj.options = optArray;
            }
            else {
                console.log('else call optArray')
                let optArray = [{
                    "id" : "PP",
                    "name": "PP",
                    "score": "25"
                },
                {
                    "id" : "VP",
                    "name": "VP",
                    "score": "50"
                },
                {
                    "id" : "GP",
                    "name": "GP",
                    "score": "75"
                },
                {
                    "id" : "Complete",
                    "name": "Complete",
                    "score": "100"
                },
                {
                    "id" : "Incomplete",
                    "name": "Incomplete",
                    "score": "0"
                },
                {
                    "id" : "NE",
                    "name": "NE",
                    "score": "0"
                },
                {
                    "id" : "NA",
                    "name": "NA",
                    "score": "0"
                },
                {
                    "id" : "Modelling",
                    "name": "Modelling",
                    "score": "0"
                },

                ];
                qsObj.options = optArray;

            }
            qsAr.push(qsObj);
        })
        console.log('qsAr => ', qsAr);
        setQuestionsMarks(qsAr);

    })

    const handleMarksSelection = (e) => {
        console.log('questionsMarks => ', questionsMarks)
        console.log('e.target.value => ', e.target.value);
        let marks = e.target.value.split('/')[0];
        let qsId = e.target.value.split('/')[1];
        console.log('qsId',qsId)
        let selectedoptionid =  e.target.value.split('/')[2];
        console.log('optionid',selectedoptionid)
        let questionsArray = [];
        console.log('qsid => ', qsId);
        console.log('marks => ', marks);
        let arr = []
        questionsMarks.forEach(question => {
            let obj = {}
            if (question.id === qsId) {
                obj.questionid = question.id;
                if (marks === 'empty'){
                    obj.marks = null;
                    obj.selectedoptionid = null;
                }
                    
                else{
                    obj.marks = marks;
                    obj.selectedoptionid = selectedoptionid;
                }
                    
            } else {
                obj.questionid = question.id;
                obj.marks = question.marks;
                obj.selectedoptionid = question.selectedoptionid;
            }
            question.marks = obj.marks;
            question.selectedoptionid = obj.selectedoptionid;
            questionsArray.push(question);
            if (obj.marks !== null) {
                arr.push(obj);
            }
        });


        console.log('arr => ', arr);
        setNewQuestions({ studentid: student.id, assessmentid: assessmentId, questionsandmarks: arr });
        setQuestionsMarks([...questionsArray]);
        //setQuestions(questionsArray);
        console.log('questionsArray => ', questionsArray)
    }


    // const handleMarksSelection = (e) => {
    //     const { value } = e.target;
    //     const [marks, qsId, optionId] = value.split('/');
    //     console.log(marks, qsId, optionId)
    //     const updatedQuestionsArray = questionsMarks.map((question) => {
    //       if (question.id === qsId) {
    //         const updatedQuestion = { ...question };
    //         updatedQuestion.marks = marks === 'empty' ? null : marks;
    //         updatedQuestion.optionid = optionId;
    //         return updatedQuestion;
    //       }
    //       return question;
    //     });
    //     console.log('updatedQuestionsArray',updatedQuestionsArray)
    //     const updatedQuestions = updatedQuestionsArray.filter((question) => question.marks !== null);
    //     console.log('updatedQuestions',updatedQuestions)
    //     setNewQuestions({ studentid: student.id, assessmentid: assessmentId, questionsandmarks: updatedQuestions });
    //     setNewQuestions(updatedQuestionsArray);
    //     //setUpdatedQuestionsMarks(updatedQuestionsArray);

    //   };
    

    const handleSaveClick = async () => {
        console.log('questionsMarks => ', questionsMarks)
        console.log('new questions => ', newQuestions)
        if (newQuestions.hasOwnProperty('questionsandmarks')) {
            let marks = await inventoryApi.addMarks(newQuestions);

        }
        PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Marks saved successfully' });
    }

    const handleCancel = () => {
        navigate(`/students/${student.id}`, { state: student });
        //navigate('/students/:id')

    };
    return (
        <>
            <Container>
                <Row>
                    <div>
                        <Col>
                            <Row className="view-form-header align-items-center">
                                {student.qstype === 'FACP' ? (
                                    <Col lg={3} xs={6}>
                                        FACP: &nbsp;&nbsp;{assessmentName}
                                    </Col>
                                ) : (
                                    <Col lg={3} xs={6}>
                                        PHYSIO: &nbsp;&nbsp;{assessmentName}
                                    </Col>
                                )}


                                <Col lg={9} xs={6} className="d-flex  justify-content-end">
                                    {isEditable && <Button className="btn-sm mx-2" onClick={handleSaveClick}>
                                        Save
                                    </Button>}
                                    <Button
                                        className="btn-sm"
                                        variant="danger"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </Col>

                            </Row>
                            <Row className='pt-3'>
                                <Col lg={1}>
                                    <b>NAME</b>
                                </Col>
                                <Col lg={2}>
                                    {student.name}
                                </Col>
                                <Col lg={2}>
                                    <b>FATHER NAME</b>
                                </Col>
                                <Col lg={4}>
                                    {student.fathername}<br />
                                </Col>
                            </Row>
                            <Row className='my-2'>
                                <Col lg={1}>
                                    <b>AGE</b>
                                </Col>
                                <Col lg={2}>{student.age}
                                </Col>
                                <Col lg={2}>
                                    <b>GENDER</b>
                                </Col>
                                <Col lg={4}>
                                    {student.gender}<br />
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </Row>
                <fieldset disabled={!isEditable}>
                    <Row>

                        <Col><div className='px-2'>
                            {questionsMarks && questionsMarks.length ? questionsMarks.map((item, index) => {
                                return <ol key={index} className="list-group mt-2">
                                    <li className="list-group-item d-flex justify-content-between align-items-start">
                                        <div className=" me-auto">
                                            <span className="">{index + 1}.  </span>
                                            {item.question}
                                        </div>
                                        <div className="float-end">
                                            <Form.Select style={{ width: '170px' }} aria-label="Enter marks" onChange={handleMarksSelection} name="marks">
                                            <option value={`empty/${item.id}/null`} >--Marks--</option>
                                                {item.options && item.options.length ? (
                                                    item.options.map((opt, index) => (
                                                        
                                                        <option
                                                            key={`${opt.score}/${item.id}/${opt.id}`}
                                                            value={`${opt.score}/${item.id}/${opt.id}`}
                                                            selected={item.selectedoptionid === opt.id}
                                                        >
                                                            {opt.name} 
                                                        </option>
                                                    ))
                                                ) : null}

                                            </Form.Select>
                                        </div>
                                    </li>
                                </ol>
                            }) : <div className='text-center'>No Question Available!</div>}</div>
                        </Col>



                    </Row>
                </fieldset>
            </Container>
        </>
    )
}

export default Questions
