import Toast from 'react-bootstrap/Toast';
import{ useState, useEffect } from "react";
import { Button,Row,Form,ToastContainer } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
const ToastMessage = ({show,handleClose, msg}) => {
    const [position, setPosition] = useState('top-center');
;
    // const [show, setShow] = useState(true);
  return (
    <>
    
    <ToastContainer className="mb-2" position={position} >

        <Toast onClose={handleClose} show={show}  delay={3000} autohide key="Info" bg="info">
          <Toast.Header closeButton={false}>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Success Message</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body><h4>{msg}</h4></Toast.Body>
        </Toast>

      </ToastContainer>
    
        
        

  </>

      
    
    
     
  );
}

export default ToastMessage;