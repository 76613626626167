import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import FilesCreate from "./FilesCreate";
import { Button, Card, Col, Container, Form, Modal, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment"
import RelatedListFiles from "./RelatedListFiles";
import RelatedLocationHistory from "./RelatedLocationHistory";
import PubSub from 'pubsub-js';
import Confirm from './Confirm';
import Badge from 'react-bootstrap/Badge';

const StaffMemberView = (props) => {

  const location = useLocation();
  const navigate = useNavigate();
  const [staffMember, setStaffMember] = useState(location.state ? location.state : {});
  console.log('location enter', staffMember)
  const [modalShowTaskfile, setModalShowFile] = useState(false);
  const [locationHistorysTab, setLocationHistorysTab] = useState(false);
  const [filesTab, setFilesTab] = useState(true);
  const [refreshFileList, setRefreshFileList] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [allStaffMember, setAllStaffMember] = useState([]);
  const [replacementStaffId, setReplacementStaffId] = useState([]);
  const [modalShowDeativate, setModalShowDeativate] = useState(false);

  useEffect(() => {
    fetchStaffById()
  }, []);

  const fetchStaffById = () => {
    console.log('fetch')
    async function initStudent() {
      let result = await inventoryApi.fetchStaffById(staffMember.id);
      console.log('==>', result)
      setStaffMember(result)
      console.log('student view result', result);

      result = await inventoryApi.fetchStaffMember();
      if (result) {
        setAllStaffMember(result);

      } else {
        setAllStaffMember([]);

      }
    }

    initStudent();
  };




  const editStaffMember = () => {
    navigate(`/staffmembers/${staffMember.id}/e`, { state: staffMember });
  };

  const submitfiles = () => {

    setModalShowFile(false);
    setRefreshFileList(Date.now());

  };



  const handleSelect = (key) => {
    if (key === 'files') {
      setLocationHistorysTab(false);
      setFilesTab(true);
    } else if (key === 'login history') {
      setLocationHistorysTab(true);
      setFilesTab(false);
    }
  }

  const handleTransferSubmit = async (e) => {
    console.log('fetch')
    console.log(staffMember.id)

    async function init() {
      let result = await inventoryApi.replaceStaffMember(staffMember.id, replacementStaffId);
      console.log('==>', result)
    }
    PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Replace staff successfully' });
    setModalShow(false);
    init();
  }

  const handleTransferChange = async (e) => {
    console.log('e', e.target.value)
    setReplacementStaffId(e.target.value);
  }

  const handleDelete = (row) => {
    setModalShowDeativate(true);
  }

  const handleDeactivate = async () => {
    let result = {};
    if (staffMember.isactive === true) {
      console.log('if call')
      staffMember.isactive = false
      result = await inventoryApi.saveStaffMember(staffMember);
      console.log('delete successfully ', result)
      if (result.success) {
        PubSub.publish('RECORD_SAVED_TOAST', { title: 'Success', message: ' Deactivated successfully' });
        setModalShowDeativate(false);
      } else if (result.success === false) {
        staffMember.isactive = true
        setModalShowDeativate(false);
        console.log('staff in else if ', staffMember.isactive)
        PubSub.publish('RECORD_ERROR_TOAST', { title: 'Error', message: ' Cannot delete. Dependent records are exist' });
      }
    }
  }

  const handleActivate = async () => {
    let result = {};
    if (staffMember.isactive === false) {
      staffMember.isactive = true
      result = await inventoryApi.saveStaffMember(staffMember);
      console.log('delete successfully ', result)
      if (result.success) {
        PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Activated Staff successfully' });
      }
    }
  }

  return (
    <div>

      {modalShowDeativate &&
        <Confirm
          show={modalShowDeativate}
          onHide={() => setModalShowDeativate(false)}
          handleDeactivate={handleDeactivate}
          title="Confirm Deactivate?"
          message=" Are you sure to deactivate the record ?"
          table="staff"
        />}
      {staffMember && <Container>
        {modalShow &&
          <Modal show={modalShow} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Replace Staff
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="m-2 mb-4">This process will transfer ownership of students handled by current staff <b>{staffMember.firstname} {staffMember.lastname}</b> to new selected user.</p>
              <Form.Group className="mx-3" controlId="formBasicType">
                <Form.Label
                  className="form-view-label"
                  htmlFor="formBasicType"
                >
                  Select Replacement Staff
                </Form.Label>
                <Form.Select
                  required
                  aria-label="Enter Select Type"
                  name="refertostaff"
                  onChange={handleTransferChange}
                >
                  <option value="">--Select Staff--</option>
                  {allStaffMember?.map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.username}
                    </option>
                  ))}
                </Form.Select>

              </Form.Group>

            </Modal.Body>
            <Modal.Footer>
              <div className="submit">
                <Button variant="success" onClick={handleTransferSubmit}>Submit</Button>
              </div>
              <Button onClick={() => { setModalShow(false); }} variant="light">Close</Button>
            </Modal.Footer>
          </Modal>
        }
        <Row className="view-form">
          <Col></Col>
          <Col lg={8}>
            <Row className="view-form-header align-items-center">
              <Col lg={3}>
                Staff Member
              </Col>
              <Col lg={9} className="d-flex justify-content-end">
                <Button className="btn-sm mx-2" onClick={() => editStaffMember(true)}>
                  <i class="fa-regular fa-pen-to-square"></i>
                </Button>
                <Button className="btn-sm mx-2" variant="danger" onClick={() => setModalShow(true)}>
                  Replace Staff
                </Button>
                {staffMember.isactive === true ?
                  <Button className='btn-sm mx-2' variant='danger' onClick={handleDelete} >Deactivate</Button>
                  : <Button className='btn-sm mx-2' variant='success' onClick={handleActivate} >Activate</Button>}
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <label>First Name</label>
                <span>
                  {staffMember.firstname}
                </span>
              </Col>
              <Col lg={6}>
                <label>Last Name</label>
                <span>
                  {staffMember.lastname}
                </span>
              </Col>
              <Col lg={6}>
                <label>Email</label>
                <span>{staffMember.email}</span>
              </Col>
              <Col lg={6}>
                <label>Phone</label>
                <span>{staffMember.phone}</span>
              </Col>
              <Col lg={6}>
                <label>DOB</label>
                <span>{moment(staffMember.dob).format('YYYY-MM-DD')}</span>
              </Col>
              <Col lg={6}>
                <label>Gender</label>
                <span>{staffMember.gender}</span>
              </Col>
              <Col lg={6}>
                <label>Street</label>
                <span>{staffMember.street}</span>
              </Col>
              <Col lg={6}>
                <label>City</label>
                <span>{staffMember.city}</span>
              </Col>
              <Col lg={6}>
                <label>Qualification</label>
                <span>{staffMember.qualificatoin}</span>
              </Col>
              <Col lg={6}>
                <label>User Role</label>
                <span>{staffMember.userrole}</span>
              </Col>
              <Col lg={6}>
                <label>Service Area</label>
                <span>{staffMember.servicearea}</span>
              </Col>

              <Col lg={6}>
                <label>Service Category</label>
                <span>{staffMember.servicecategoryname}</span>
              </Col>
              <Col lg={6}>
                <label>Staff Status </label>
                <span>{staffMember.isactive === true ? <Badge bg="primary">Active</Badge> : <Badge bg="danger">In Active</Badge>}</span>
              </Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>
        <Card bg="light" text="light" className="mb-2 mt-4">
          <Card.Header className="d-flex justify-content-between">
            <Tabs defaultActiveKey="files" id="uncontrolled-tab-example" onSelect={(key) => handleSelect(key)}>
              <Tab eventKey="files" title="Files"></Tab>
              <Tab eventKey="login history" title="Login History"></Tab>
            </Tabs>
            {filesTab && (<Button
              className="float-right btn-sm"
              onClick={() => setModalShowFile(true)}
            >
              Upload File
            </Button>)}

            {modalShowTaskfile && (
              <FilesCreate
                show={modalShowTaskfile}
                onHide={() => setModalShowFile(false)}
                parent={staffMember}
                submitfiles={submitfiles}
                objectName="staffMember"
              />
            )}
          </Card.Header>
          <Card.Body>
            {/* {filesTab && (<RelatedListFiles parent={staffMember} files={files}/>)} */}
            {filesTab ? <RelatedListFiles refreshFileList={refreshFileList} parent={staffMember} /> : ""}
            {locationHistorysTab && (<RelatedLocationHistory parent={staffMember} />)}
          </Card.Body>
        </Card>
      </Container>}
    </div>
  );
};

export default StaffMemberView;