import React, { useState, useEffect } from "react";

import { Button, Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";




const ServiceAreaView = (props) => {
  
  const location = useLocation();
  const navigate = useNavigate();
  const [servicearea, setServiceArea] = useState(location.state ? location.state : {});


  

  useEffect(() => {
  }, []);

  

  const editServiceArea = () => {
    navigate(`/serviceareas/${servicearea.id}/e`, { state: servicearea });
  };



 
  return (
    <div>
      {servicearea && <Container>
       
        <Row className="view-form">
          <Col></Col>
          <Col lg={8}>
            <Row className="view-form-header align-items-center">
              <Col lg={3}>
                Service Area
                
              </Col>
              <Col lg={9} className="d-flex justify-content-end">
                <Button className="btn-sm mx-2" onClick={() => editServiceArea(true)}>
                  <i class="fa-regular fa-pen-to-square"></i>
                </Button>
                
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <label>Name</label>
                <span>{servicearea.name}</span>
              </Col>
              <Col lg={6}>
                <label>Location / City</label>
                <span>{servicearea.location}</span>
              </Col>
              <Col lg={6}>
                <label>Service Area</label>
                <span>{servicearea.servicearea}</span>
              </Col>
             
             
              
            </Row>
          </Col>
          <Col></Col>
        </Row>
       
      </Container>}
    </div>
  );
};

export default ServiceAreaView;
