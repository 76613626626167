import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import PubSub from "pubsub-js";
import { useLocation } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import { useNavigate } from 'react-router-dom'
import moment from 'moment';
import PdfGenerator from "./PdfGenerator";
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import * as constants from "../constants/CONSTANT";


const HomeBasedCbrEdit = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [formEnable, setFormEnable] = useState(true);
    const [editForm, setEditForm] = useState(false);
    const [homeBasedData, setHomeBasedData] = useState(location.state ? location.state : {})
    const [errorShow, setErrorShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [refresh, setRefresh] = useState();
    // const [showError, setShowError] = useState(false);
    // const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        console.log('location.state', location.state)                         //called when it get rendered
        if (location.state === null) {
            setFormEnable(false);
            setEditForm(true);
        }
    }, []);


    const handleChange = (e) => {
        setHomeBasedData({ ...homeBasedData, [e.target.name]: e.target.value });

    };

    const checkRequredFields = () => {
        if (
            homeBasedData.name && homeBasedData.name.trim() !== ""
            &&
            homeBasedData.age && homeBasedData.age.trim() !== ""
            &&
            homeBasedData.cbrworker && homeBasedData.cbrworker.trim() !== ""
            &&
            homeBasedData.guardiansign && homeBasedData.guardiansign.trim() !== ""
            &&
            homeBasedData.cbrworkersign && homeBasedData.cbrworkersign.trim() !== ""
        ) {
            return false;
        }
        return true;
    };

    //..........................
    const pdfMaker = () => {
        

        // setshowDetails(true)
        //console.log('if pdf row call')
        const pdfTable = document.getElementById("divToPrint");
        //console.log("pdf table", pdfTable);
        var html = htmlToPdfmake(pdfTable.innerHTML, {
            tableAutoSize: true,
        });
        console.log('html', html)
        const documentDefinition = {
            content: html, defaultStyle: {
                font: 'arialunicodems1'
            }
        };
        //console.log('documentDefinition', documentDefinition)
        //pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.fonts = {
            arialunicodems1: {
                normal: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
                bold: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
            },


            // download default Roboto font from cdnjs.com
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
            }
        }
        //console.log(pdfMake.vfs)
        pdfMake.createPdf(documentDefinition).open();
    };

    const handleEdit = () => {
        setFormEnable(false);
        setEditForm(true);
    }

    const handleCancel = () => {
        navigate(`/homebasedcbr/`);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (checkRequredFields()) {
            console.log('====true======');
            setValidated(true);
            setErrorShow(true);
            return;
        }
        // console.log("CbrWorkerReportData", homeBasedData);
        if (homeBasedData.id) {
            console.log("====edit-====");
            setValidated(false);
            const result = await inventoryApi.saveHomeBasedCbr(homeBasedData);
            if (result.errors) {
                // setShowError(true);
                // setErrorMessage(result.errors);
                return;
            }
            if (result.success) {
                console.log('if result true');
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record updated successfully' });
                navigate(`/homebasedcbr/${homeBasedData.id}/e`, { state: homeBasedData });
                setFormEnable(true);
                setErrorShow(false);
                setEditForm(false);
            }
        } else {
            const result = await inventoryApi.createHomeBasedCbr(homeBasedData);
            if (result) {
                PubSub.publish("RECORD_SAVED_TOAST", {
                    title: "Record Saved",
                    message: "Record create successfully",
                });
                navigate(`/homebasedcbr/`, { state: result });
            }
            if (result.errors) {
                // setShowError(true);
                // setErrorMessage(result.errors);
                return;
            }
        }
    };

    let index = 0;
    return (
        <>
            <Container id="marbt">
                <Row>
                    <Alert variant="danger" show={errorShow} className="error-alert my-2"><i class="fa-solid fa-triangle-exclamation"></i> Please fill all required values</Alert>
                    <Col lg={12} className="mx-3">
                        <Form
                            className="mt-3"
                            onSubmit={handleSubmit}
                            noValidate
                            validated={validated}
                        >
                            <Row className="view-form-header align-items-center">
                                <Col lg={6}>Home Based Direction For Parents </Col>
                                {editForm === false && (
                                    <Col lg={6} className="d-flex justify-content-end">
                                        <Button className="btn-sm mx-2" variant="primary" onClick={() => { pdfMaker() }}>
                                            <i class="fa-solid fa-download ml-2 mr-4" style={{ color: '#ffffff' }} ></i>
                                            <b>Home Based CBR</b>
                                        </Button>
                                        <Button className="btn-sm mx-2" variant="primary" onClick={handleEdit}> <i class="fa-regular fa-pen-to-square"></i> </Button>
                                    </Col>)}
                                {editForm === true && (
                                    <Col lg={6} className="d-flex justify-content-end">
                                        <Button className="btn-sm mx-2" type="submit" onClick={handleSubmit}> Save </Button>
                                        <Button className="btn-sm" variant="danger" onClick={handleCancel} > Cancel </Button>
                                    </Col>)
                                }
                            </Row>
                        </Form>
                    </Col>
                    {/* <Col>2 of 2</Col> */}
                </Row>
                <Col lg={12} className="section-header my-3 mx-2"> C.B.R </Col>
                <fieldset disabled={formEnable}>
                    <Row>

                        <Row className="my-3">
                            <Col lg={6}>
                                <Form.Group className="mx-3 my-1" controlId="formBasicServiceArea">
                                    <Form.Label className="form-view-label">Service Area</Form.Label>
                                    <Form.Select
                                        // required
                                        // aria-label="Enter Service Area"
                                        name="servicearea"
                                        value={homeBasedData.servicearea}
                                        onChange={handleChange}
                                    >
                                        <select
                                            onChange={handleChange}
                                            className="form-select"
                                        ></select>
                                        {/* <option>--Select--</option> */}
                                        <option value="Day Care">Day Care </option>
                                        <option value="School">School </option>
                                        <option value="CBR">CBR</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mx-3 my-1" controlId="formBasicname">
                                    <Form.Label className="form-view-label">Child's Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="name"
                                        placeholder="Enter Child's Name"
                                        value={homeBasedData.name}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mx-3 my-1" controlId="formBasicname">
                                    <Form.Label className="form-view-label">Child's Age</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="age"
                                        placeholder="Enter Child's Age"
                                        value={homeBasedData.age}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mx-3 my-1" controlId="formBasicname">
                                    <Form.Label className="form-view-label">C.B.R. Worker</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="cbrworker"
                                        placeholder="Enter C.B.R. Worker"
                                        value={homeBasedData.cbrworker}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mx-3 my-1" controlId="formBasicname">
                                    <Form.Label className="form-view-label">Time</Form.Label>
                                    <Form.Control
                                        // required
                                        type="text"
                                        name="time"
                                        placeholder="Enter Time"
                                        value={homeBasedData.time}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicDate">
                                    <Form.Label className="form-view-label">Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="date"
                                        value={moment(homeBasedData.date).format('YYYY-MM-DD')}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mx-2 my-1" controlId="formBasicname">
                                    <Form.Label className="form-view-label">Day</Form.Label>
                                    <Form.Control
                                        // required
                                        type="text"
                                        name="day"
                                        disabled
                                        value={homeBasedData.day}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg={6}>
                                <Form.Group className="mx-3 my-1" controlId="contribution">
                                    <Form.Label className="form-view-label">
                                        Current extension of the child during the program
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="chidextension"
                                        value={homeBasedData.chidextension}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                        </Row>

                        <Row>
                            <Col lg={12} className="mx-2 my-2">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>According to target area</th>
                                            <th style={{ width: "20px" }}></th>
                                            <th>Work done by C.B.R. worker</th>
                                            <th style={{ width: "20px" }}></th>
                                            <th>Materials used</th>
                                            <th>Instructions for Parents</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Personal</td>
                                            <td>{index + 1}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="personalwork_1"
                                                    value={homeBasedData.personalwork_1}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 1}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="personalmaterials_1"
                                                    value={homeBasedData.personalmaterials_1}
                                                    onChange={handleChange}
                                                />
                                            </td>

                                            <Form.Group className="mx-2" controlId="contribution">
                                                {/* <Form.Label className="form-view-label">
                      Current extension of the child during the program
                    </Form.Label> */}
                                                <Form.Control
                                                    as="textarea"
                                                    name="personalinstructions"
                                                    value={homeBasedData.personalinstructions}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 2}</td>

                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.personalwork_2}
                                                    className="form-control"
                                                    name="personalwork_2"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 2}</td>
                                            <td>
                                                {""}
                                                <input
                                                    type="number"
                                                    value={homeBasedData.personalmaterials_2}
                                                    className="form-control"
                                                    name="personalmaterials_2"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 3}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.personalwork_3}
                                                    className="form-control"
                                                    name="personalwork_3"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 3}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.personalmaterials_3}
                                                    className="form-control"
                                                    name="personalmaterials_3"

                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 4}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.personalwork_4}
                                                    className="form-control"
                                                    name="personalwork_4"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 4}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.personalmaterials_4}
                                                    className="form-control"
                                                    name="personalmaterials_4"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 5}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.personalwork_5}
                                                    className="form-control"
                                                    name="personalwork_5"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 5}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.personalmaterials_5}
                                                    className="form-control"
                                                    name="personalmaterials_5"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 6}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.personalwork_6}
                                                    className="form-control"
                                                    name="personalwork_6"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 6}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.personalmaterials_6}
                                                    className="form-control"
                                                    name="personalmaterials_6"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 7}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.personalwork_7}
                                                    className="form-control"
                                                    name="personalwork_7"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 7}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.personalmaterials_7}
                                                    className="form-control"
                                                    name="personalmaterials_7"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 8}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.personalwork_8}
                                                    className="form-control"
                                                    name="personalwork_8"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 8}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.personalmaterials_8}
                                                    className="form-control"
                                                    name="personalmaterials_8"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 9}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.personalwork_9}
                                                    className="form-control"
                                                    name="personalwork_9"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 9}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.personalmaterials_9}
                                                    className="form-control"
                                                    name="personalmaterials_9"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 10}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.personalwork_10}
                                                    className="form-control"
                                                    name="personalwork_10"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 10}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.personalmaterials_10}
                                                    className="form-control"
                                                    name="personalmaterials_10"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>

                                        {/* <tr>
                  <td>Social</td>
                  <td>
                    <input
                      type="number"
                      value="Shivangi"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value="Shivangi"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value="Shivangi"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </td>
                </tr> */}
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td>Social</td>
                                            <td>{index + 1}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.socialwork_1}
                                                    className="form-control"
                                                    name="socialwork_1"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 1}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.socialmaterials_1}
                                                    className="form-control"
                                                    name="socialmaterials_1"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <Form.Group className="mx-2" controlId="contribution">
                                                {/* <Form.Label className="form-view-label">
                      Current extension of the child during the program
                    </Form.Label> */}
                                                <Form.Control
                                                    as="textarea"
                                                    value={homeBasedData.socialinstructions}
                                                    name="socialinstructions"
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                            {/* <td>
                    <input
                      type="number"
                      value="Shivangi"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </td> */}
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 2}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.socialwork_2}
                                                    className="form-control"
                                                    name="socialwork_2"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 2}</td>
                                            <td>
                                                {""}
                                                <input
                                                    type="number"
                                                    value={homeBasedData.socialmaterials_2}
                                                    className="form-control"
                                                    name="socialmaterials_2"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 3}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.socialwork_3}
                                                    className="form-control"
                                                    name="socialwork_3"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 3}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.socialmaterials_3}
                                                    className="form-control"
                                                    name="socialmaterials_3"
                                                    // placeholder="Enter Qualification"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 4}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.socialwork_4}
                                                    className="form-control"
                                                    name="socialwork_4"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 4}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.socialmaterials_4}
                                                    className="form-control"
                                                    name="socialmaterials_4"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 5}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.socialwork_5}
                                                    className="form-control"
                                                    name="socialwork_5"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 5}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.socialmaterials_5}
                                                    className="form-control"
                                                    name="socialmaterials_5"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 6}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.socialwork_6}
                                                    className="form-control"
                                                    name="socialwork_6"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 6}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.socialmaterials_6}
                                                    className="form-control"
                                                    name="socialmaterials_6"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 7}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.socialwork_7}
                                                    className="form-control"
                                                    name="socialwork_7"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 7}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.socialmaterials_7}
                                                    className="form-control"
                                                    name="socialmaterials_7"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 8}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.socialwork_8}
                                                    className="form-control"
                                                    name="socialwork_8"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 8}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.socialmaterials_8}
                                                    className="form-control"
                                                    name="socialmaterials_8"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 9}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.socialwork_9}
                                                    className="form-control"
                                                    name="socialwork_9"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 9}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.socialmaterials_9}
                                                    className="form-control"
                                                    name="socialmaterials_9"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 10}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.socialwork_10}
                                                    className="form-control"
                                                    name="socialwork_10"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 10}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.socialmaterials_10}
                                                    className="form-control"
                                                    name="socialmaterials_10"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td>Business</td>
                                            <td>{index + 1}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.businesswork_1}
                                                    className="form-control"
                                                    name="businesswork_1"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 1}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.businessmaterials_1}
                                                    className="form-control"
                                                    name="businessmaterials_1"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <Form.Group className="mx-2" controlId="contribution">
                                                {/* <Form.Label className="form-view-label">
                      Current extension of the child during the program
                    </Form.Label> */}
                                                <Form.Control
                                                    as="textarea"
                                                    value={homeBasedData.businessinstructions}
                                                    name="businessinstructions"
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                            {/* <td>
                    <input
                      type="number"
                      value="Shivangi"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </td> */}
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 2}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.businesswork_2}
                                                    className="form-control"
                                                    name="businesswork_2"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 2}</td>
                                            <td>
                                                {""}
                                                <input
                                                    type="number"
                                                    value={homeBasedData.businessmaterials_2}
                                                    className="form-control"
                                                    name="businessmaterials_2"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 3}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.businesswork_3}
                                                    className="form-control"
                                                    name="businesswork_3"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 3}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.businessmaterials_3}
                                                    className="form-control"
                                                    name="businessmaterials_3"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 4}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.businesswork_4}
                                                    className="form-control"
                                                    name="businesswork_4"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 4}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.businessmaterials_4}
                                                    className="form-control"
                                                    name="businessmaterials_4"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 5}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.businesswork_5}
                                                    className="form-control"
                                                    name="businesswork_5"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 5}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.businessmaterials_5}
                                                    className="form-control"
                                                    name="businessmaterials_5"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 6}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.businesswork_6}
                                                    className="form-control"
                                                    name="businesswork_6"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 6}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.businessmaterials_6}
                                                    className="form-control"
                                                    name="businessmaterials_6"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 7}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.businesswork_7}
                                                    className="form-control"
                                                    name="businesswork_7"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 7}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.businessmaterials_7}
                                                    className="form-control"
                                                    name="businessmaterials_7"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 8}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.businesswork_8}
                                                    className="form-control"
                                                    name="businesswork_8"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 8}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.businessmaterials_8}
                                                    className="form-control"
                                                    name="businessmaterials_8"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 9}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.businesswork_9}
                                                    className="form-control"
                                                    name="businesswork_9"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 9}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.businessmaterials_9}
                                                    className="form-control"
                                                    name="businessmaterials_9"
                                                    placeholder="enter"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 10}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.businesswork_10}
                                                    className="form-control"
                                                    name="businesswork_10"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 10}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.businessmaterials_10}
                                                    className="form-control"
                                                    name="businessmaterials_10"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td>Educational</td>
                                            <td>{index + 1}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.educationalwork_1}
                                                    className="form-control"
                                                    name="educationalwork_1"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 1}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.educationalmaterials_1}
                                                    className="form-control"
                                                    name="educationalmaterials_1"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <Form.Group className="mx-2" controlId="contribution">
                                                {/* <Form.Label className="form-view-label">
                      Current extension of the child during the program
                    </Form.Label> */}
                                                <Form.Control
                                                    as="textarea"
                                                    value={homeBasedData.educationalinstructions}
                                                    name="educationalinstructions"
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                            {/* <td>
                    <input
                      type="number"
                      value="Shivangi"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </td> */}
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 2}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.educationalwork_2}
                                                    className="form-control"
                                                    name="educationalwork_2"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 2}</td>
                                            <td>
                                                {""}
                                                <input
                                                    type="number"
                                                    value={homeBasedData.educationalmaterials_2}
                                                    className="form-control"
                                                    name="educationalmaterials_2"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 3}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.educationalwork_3}
                                                    className="form-control"
                                                    name="educationalwork_3"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 3}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.educationalmaterials_3}
                                                    className="form-control"
                                                    name="educationalmaterials_3"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 4}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.educationalwork_4}
                                                    className="form-control"
                                                    name="educationalwork_4"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 4}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.educationalmaterials_4}
                                                    className="form-control"
                                                    name="educationalmaterials_4"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 5}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.educationalwork_5}
                                                    className="form-control"
                                                    name="educationalwork_5"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 5}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.educationalmaterials_5}
                                                    className="form-control"
                                                    name="educationalmaterials_5"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 6}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.educationalwork_6}
                                                    className="form-control"
                                                    name="educationalwork_6"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 6}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.educationalmaterials_6}
                                                    className="form-control"
                                                    name="educationalmaterials_6"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 7}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.educationalwork_7}
                                                    className="form-control"
                                                    name="educationalwork_7"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 7}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.educationalmaterials_7}
                                                    className="form-control"
                                                    name="educationalmaterials_7"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 8}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.educationalwork_8}
                                                    className="form-control"
                                                    name="educationalwork_8"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 8}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.educationalmaterials_8}
                                                    className="form-control"
                                                    name="educationalmaterials_8"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 9}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.educationalwork_9}
                                                    className="form-control"
                                                    name="educationalwork_9"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 9}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.educationalmaterials_9}
                                                    className="form-control"
                                                    name="educationalmaterials_9"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 10}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.educationalwork_10}
                                                    className="form-control"
                                                    name="educationalwork_10"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 10}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.educationalmaterials_10}
                                                    className="form-control"
                                                    name="educationalmaterials_10"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td>Entertainment</td>
                                            <td>{index + 1}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.entertainmentwork_1}
                                                    className="form-control"
                                                    name="entertainmentwork_1"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 1}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.entertainmentmaterials_1}
                                                    className="form-control"
                                                    name="entertainmentmaterials_1"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <Form.Group className="mx-2" controlId="contribution">
                                                {/* <Form.Label className="form-view-label">
                      Current extension of the child during the program
                    </Form.Label> */}
                                                <Form.Control
                                                    as="textarea"
                                                    value={homeBasedData.entertainmentinstructions}
                                                    name="entertainmentinstructions"
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                            {/* <td>
                    <input
                      type="number"
                      value="Shivangi"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </td> */}
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 2}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.entertainmentwork_2}
                                                    className="form-control"
                                                    name="entertainmentwork_2"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 2}</td>
                                            <td>
                                                {""}
                                                <input
                                                    type="number"
                                                    value={homeBasedData.entertainmentmaterials_2}
                                                    className="form-control"
                                                    name="entertainmentmaterials_2"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 3}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.entertainmentwork_3}
                                                    className="form-control"
                                                    name="entertainmentwork_3"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 3}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.entertainmentmaterials_3}
                                                    className="form-control"
                                                    name="entertainmentmaterials_3"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 4}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.entertainmentwork_4}
                                                    className="form-control"
                                                    name="entertainmentwork_4"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 4}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.entertainmentmaterials_4}
                                                    className="form-control"
                                                    name="entertainmentmaterials_4"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 5}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.entertainmentwork_5}
                                                    className="form-control"
                                                    name="entertainmentwork_5"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 5}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.entertainmentmaterials_5}
                                                    className="form-control"
                                                    name="entertainmentmaterials_5"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 6}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.entertainmentwork_6}
                                                    className="form-control"
                                                    name="entertainmentwork_6"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 6}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.entertainmentmaterials_6}
                                                    className="form-control"
                                                    name="entertainmentmaterials_6"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 7}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.entertainmentwork_7}
                                                    className="form-control"
                                                    name="entertainmentwork_7"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 7}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.entertainmentmaterials_7}
                                                    className="form-control"
                                                    name="entertainmentmaterials_7"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 8}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.entertainmentwork_8}
                                                    className="form-control"
                                                    name="entertainmentwork_8"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 8}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.entertainmentmaterials_8}
                                                    className="form-control"
                                                    name="entertainmentmaterials_8"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 9}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.entertainmentwork_9}
                                                    className="form-control"
                                                    name="entertainmentwork_9"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 9}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.entertainmentmaterials_9}
                                                    className="form-control"
                                                    name="entertainmentmaterials_9"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 10}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.entertainmentwork_10}
                                                    className="form-control"
                                                    name="entertainmentwork_10"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 10}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.entertainmentmaterials_10}
                                                    className="form-control"
                                                    name="entertainmentmaterials_10"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td>Community Inclusion </td>
                                            <td>{index + 1}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.communityinclusionwork_1}
                                                    className="form-control"
                                                    name="communityinclusionwork_1"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 1}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.communityinclusionmaterials_1}
                                                    className="form-control"
                                                    name="communityinclusionmaterials_1"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <Form.Group className="mx-2" controlId="contribution">
                                                {/* <Form.Label className="form-view-label">
                      Current extension of the child during the program
                    </Form.Label> */}
                                                <Form.Control
                                                    as="textarea"
                                                    value={homeBasedData.communityinclusioninstructions}
                                                    name="communityinclusioninstructions"
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                            {/* <td>
                    <input
                      type="number"
                      value="Shivangi"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </td> */}
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 2}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.communityinclusionwork_2}
                                                    className="form-control"
                                                    name="communityinclusionwork_2"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 2}</td>
                                            <td>
                                                {""}
                                                <input
                                                    type="number"
                                                    value={homeBasedData.communityinclusionmaterials_2}
                                                    className="form-control"
                                                    name="communityinclusionmaterials_2"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 3}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.communityinclusionwork_3}
                                                    className="form-control"
                                                    name="communityinclusionwork_3"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 3}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.communityinclusionmaterials_3}
                                                    className="form-control"
                                                    name="communityinclusionmaterials_3"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 4}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.communityinclusionwork_4}
                                                    className="form-control"
                                                    name="communityinclusionwork_4"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 4}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.communityinclusionmaterials_4}
                                                    className="form-control"
                                                    name="communityinclusionmaterials_4"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 5}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.communityinclusionwork_5}
                                                    className="form-control"
                                                    name="communityinclusionwork_5"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 5}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.communityinclusionmaterials_5}
                                                    className="form-control"
                                                    name="communityinclusionmaterials_5"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 6}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.communityinclusionwork_6}
                                                    className="form-control"
                                                    name="communityinclusionwork_6"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 6}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.communityinclusionmaterials_6}
                                                    className="form-control"
                                                    name="communityinclusionmaterials_6"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 7}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.communityinclusionwork_7}
                                                    className="form-control"
                                                    name="communityinclusionwork_7"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 7}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.communityinclusionmaterials_7}
                                                    className="form-control"
                                                    name="communityinclusionmaterials_7"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 8}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.communityinclusionwork_8}
                                                    className="form-control"
                                                    name="communityinclusionwork_8"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 8}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.communityinclusionmaterials_8}
                                                    className="form-control"
                                                    name="communityinclusionmaterials_8"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 9}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.communityinclusionwork_9}
                                                    className="form-control"
                                                    name="communityinclusionwork_9"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 9}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.communityinclusionmaterials_9}
                                                    className="form-control"
                                                    name="communityinclusionmaterials_9"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 10}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.communityinclusionwork_10}
                                                    className="form-control"
                                                    name="communityinclusionwork_10"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 10}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.communityinclusionmaterials_10}
                                                    className="form-control"
                                                    name="communityinclusionmaterials_10"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td>Language and communication</td>
                                            <td>{index + 1}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.languagecommunicationwork_1}
                                                    className="form-control"
                                                    name="languagecommunicationwork_1"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 1}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.languagecommunicationmaterials_1}
                                                    className="form-control"
                                                    name="languagecommunicationmaterials_1"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <Form.Group className="mx-2" controlId="contribution">
                                                {/* <Form.Label className="form-view-label">
                      Current extension of the child during the program
                    </Form.Label> */}
                                                <Form.Control
                                                    as="textarea"
                                                    value={homeBasedData.languagecommunicationinstructions}
                                                    name="languagecommunicationinstructions"
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                            {/* <td>
                    <input
                      type="number"
                      value="Shivangi"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </td> */}
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 2}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.languagecommunicationwork_2}
                                                    className="form-control"
                                                    name="languagecommunicationwork_2"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 2}</td>
                                            <td>
                                                {""}
                                                <input
                                                    type="number"
                                                    value={homeBasedData.languagecommunicationmaterials_2}
                                                    className="form-control"
                                                    name="languagecommunicationmaterials_2"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 3}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.languagecommunicationwork_3}
                                                    className="form-control"
                                                    name="languagecommunicationwork_3"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 3}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.languagecommunicationmaterials_3}
                                                    className="form-control"
                                                    name="languagecommunicationmaterials_3"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 4}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.languagecommunicationwork_4}
                                                    className="form-control"
                                                    name="languagecommunicationwork_4"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 4}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.languagecommunicationmaterials_4}
                                                    className="form-control"
                                                    name="languagecommunicationmaterials_4"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 5}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.languagecommunicationwork_5}
                                                    className="form-control"
                                                    name="languagecommunicationwork_5"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 5}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.languagecommunicationmaterials_5}
                                                    className="form-control"
                                                    name="languagecommunicationmaterials_5"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 6}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.languagecommunicationwork_6}
                                                    className="form-control"
                                                    name="languagecommunicationwork_6"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 6}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.languagecommunicationmaterials_6}
                                                    className="form-control"
                                                    name="languagecommunicationmaterials_6"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 7}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.languagecommunicationwork_7}
                                                    className="form-control"
                                                    name="languagecommunicationwork_7"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 7}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.languagecommunicationmaterials_7}
                                                    className="form-control"
                                                    name="languagecommunicationmaterials_7"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 8}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.languagecommunicationwork_8}
                                                    className="form-control"
                                                    name="languagecommunicationwork_8"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 8}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.languagecommunicationmaterials_8}
                                                    className="form-control"
                                                    name="languagecommunicationmaterials_8"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 9}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.languagecommunicationwork_9}
                                                    className="form-control"
                                                    name="languagecommunicationwork_9"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 9}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.languagecommunicationmaterials_9}
                                                    className="form-control"
                                                    name="languagecommunicationmaterials_9"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 10}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.languagecommunicationwork_10}
                                                    className="form-control"
                                                    name="languagecommunicationwork_10"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 10}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.languagecommunicationmaterials_10}
                                                    className="form-control"
                                                    name="languagecommunicationmaterials_10"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td>Visitor</td>
                                            <td>{index + 1}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.visitorwork_1}
                                                    className="form-control"
                                                    name="visitorwork_1"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 1}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.visitormaterials_1}
                                                    className="form-control"
                                                    name="visitormaterials_1"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <Form.Group className="mx-2" controlId="contribution">
                                                {/* <Form.Label className="form-view-label">
                      Current extension of the child during the program
                    </Form.Label> */}
                                                <Form.Control
                                                    as="textarea"
                                                    value={homeBasedData.visitorinstructions}
                                                    name="visitorinstructions"
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                            {/* <td>
                    <input
                      type="number"
                      value="Shivangi"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </td> */}
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 2}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.visitorwork_2}
                                                    className="form-control"
                                                    name="visitorwork_2"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 2}</td>
                                            <td>
                                                {""}
                                                <input
                                                    type="number"
                                                    value={homeBasedData.visitormaterials_2}
                                                    className="form-control"
                                                    name="visitormaterials_2"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 3}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.visitorwork_3}
                                                    className="form-control"
                                                    name="visitorwork_3"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 3}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.visitormaterials_3}
                                                    className="form-control"
                                                    name="visitormaterials_3"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 4}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.visitorwork_4}
                                                    className="form-control"
                                                    name="visitorwork_4"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 4}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.visitormaterials_4}
                                                    className="form-control"
                                                    name="visitormaterials_4"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 5}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.visitorwork_5}
                                                    className="form-control"
                                                    name="visitorwork_5"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 5}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.visitormaterials_5}
                                                    className="form-control"
                                                    name="visitormaterials_5"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 6}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.visitorwork_6}
                                                    className="form-control"
                                                    name="visitorwork_6"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 6}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.visitormaterials_6}
                                                    className="form-control"
                                                    name="visitormaterials_6"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 7}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.visitorwork_7}
                                                    className="form-control"
                                                    name="visitorwork_7"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 7}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.visitormaterials_7}
                                                    className="form-control"
                                                    name="visitormaterials_7"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 8}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.visitorwork_8}
                                                    className="form-control"
                                                    name="visitorwork_8"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 8}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.visitormaterials_8}
                                                    className="form-control"
                                                    name="visitormaterials_8"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 9}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.visitorwork_9}
                                                    className="form-control"
                                                    name="visitorwork_9"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 9}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.visitormaterials_9}
                                                    className="form-control"
                                                    name="visitormaterials_9"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{index + 10}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.visitorwork_10}
                                                    className="form-control"
                                                    name="visitorwork_10"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{index + 10}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={homeBasedData.visitormaterials_10}
                                                    className="form-control"
                                                    name="visitormaterials_10"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="mx-3 my-1" controlId="contribution">
                                    <Form.Label className="form-view-label">Next Program</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        value={homeBasedData.nextprogram}
                                        name="nextprogram"
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="mx-3 my-1" controlId="formBasicname">
                                    <Form.Label className="form-view-label">
                                        Guardian Signature
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="guardiansign"
                                        value={homeBasedData.guardiansign}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="mx-3 my-1" controlId="formBasicname">
                                    <Form.Label className="form-view-label">
                                        Signature C.B.R. Worker
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="cbrworkersign"
                                        value={homeBasedData.cbrworkersign}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Row>
                </fieldset>
                {/* ........................................................... */}
                <div className="App container mt-5" style={{ display: 'none' }}>
                    <div id="divToPrint">
                        <html>
                            <head>
                                <meta charset="UTF-8" />
                            </head>
                            <body>
                                <PdfGenerator data={homeBasedData} refresh={Date.now()}/>
                            </body>
                        </html>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default HomeBasedCbrEdit;
