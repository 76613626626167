import React, { useEffect, useState } from "react";
import inventoryApi from "../api/inventoryApi";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { name } from "pubsub-js";
import vsmsData from "../constants/VSMS_Score.json";

const CaseHistory = (props) => {
  let student = props.data;

  let ageDataValue = 0 
  useEffect(() => {
    let registrationAge = null;
    const registrationDate = moment(student.childregistrationdate);
    let dobDate = moment(student.dob);
    
    const yearsDifference = registrationDate.diff(dobDate, 'years');
    console.log('yearsDifference',yearsDifference)
    dobDate.add(yearsDifference, 'years');
    const monthsDifference = registrationDate.diff(dobDate, 'months');
    console.log('monthsDifference',monthsDifference)
    let ageInDecimal = yearsDifference + (monthsDifference / 12);
    console.log('ageinDecimal',ageInDecimal);
    ageInDecimal = parseFloat(ageInDecimal.toFixed(1));
    console.log('Age difference in decimal:', ageInDecimal);
    ageDataValue = ageInDecimal
       
      //console.log('ageDataValue',ageDataValue)
   
  }, []);



  //console.log("enter", student);
  const location = useLocation();
  const [studentInfo, setStudentInfo] = useState({});
  const [ParentInfo, setParentInfo] = useState([]);
  const [personalInfo, setPersonalInfo] = useState([]);
  const [studentAssessment, setStudentAssessment] = useState([]);
  const [dstInfo, setDstInfo] = useState([]);
  const [vsmsInfo, setVSMSInfo] = useState([]);
  const [gdtInfo, setGDTInfo] = useState([]);
  const [score, setscore] = useState(0);
  const [ageData, setAge] = useState();
  const [dstScore, setDSTscore] = useState(0);
  const [body, setBody] = useState([]);
  const[damarks,setDamarks] = useState([]);
  useEffect(() => {
    fetchStudentInfo();
    fetchChildPhoto();
    fetchParentInfo();
    fetchPersonalInfo();
    fetchAssessmentInfo();
    fetchDSTInfo();
    fetchVSMSInfo();
    gdtFetch();
  }, []);
  const fetchStudentInfo = async () => {
    if (
      location.hasOwnProperty("pathname") &&
      location.pathname.split("/").length >= 3
    ) {
      //console.log(location);
      //console.log("data", location.pathname.split("/")[2]);
      student.id = location.pathname.split("/")[2];
    }
    let result = await inventoryApi.fetchStudentById(student.id);
    //console.log("result in related info student:", result);
    // result.dob = moment(dob).format('YYYY-MM-DD')
    const formattedDate = moment(result.dob).format("YYYY-MM-DD");
    //console.log(formattedDate);
    result.dob = formattedDate;

    setStudentInfo(result);
  };

  const fetchParentInfo = async () => {
    let result = await inventoryApi.fetchStudentParent(student);
    //console.log("parent info ======> ", result);
    setParentInfo(result);
  };

  const fetchPersonalInfo = async () => {
    let result = await inventoryApi.fetchStudentPersonalInfo(student);
    //console.log("personal info", result);
    setPersonalInfo(result);
  };

  const fetchAssessmentInfo = async () => {
    let result = await inventoryApi.fetchStudentAssessment(student);
    ////console.log('call edit button for fetch result', result)
    setStudentAssessment(result);
  };

  const fetchDSTInfo = async () => {
    const result = await inventoryApi.fetchCaseHistoryResults(
      student.id,
      "DST"
    );
    countOfDstMarks(result);
    setDstInfo(result);
  };

  const countOfDstMarks = async (result) => {
   
    let calculatedScore = 0;
    let ageInMonths = ageDataValue * 12;
    //console.log("age", ageInMonths);
    //console.log("result", result);
    let historyQuestions = await inventoryApi.fetchCaseHistoryQuestions("DST");
    //console.log("historyQuestions", historyQuestions);
    const updatedResult = result.map((result) => {
      // Find the corresponding history question
      const question = historyQuestions.find(
        (q) => q.id === result.casehistorytestid
      );

      // If a question is found, extract the sectionheading and add it to the result object
      if (question) {
        return {
          ...result,
          sectionheading: question.sectionheading,
        };
      }
      return result;
    });

    //console.log("updatedResult", updatedResult);
    const groupedData = updatedResult.reduce((acc, curr) => {
      const section = curr.sectionheading;
      if (!acc[section]) {
        acc[section] = [];
      }
      acc[section].push(curr);
      return acc;
    }, {});
    //console.log("groupedData", groupedData);

    // Next, transform the grouped data into the desired format
    const array = Object.keys(groupedData).map((section) => {
      const questions = groupedData[section].map((q) => ({
        casehistorytestid: q.casehistorytestid,
        studentid: q.studentid,
        marks: q.marks,
        category: q.category,
        question: q.question,
        days: q.days,
      }));
      return { sectionheading: section, questions };
    });
    //console.log("array", array);
    //console.log("if dst call");
    let totalMarks = 0;
    let questionArrayLength = 0;
    let sectionMarks3Month = 0;
    let sectionMarks6Month = 0;
    let sectionMarks9Month = 0;
    let sectionMarks1Y = 0;
    let sectionMarks1_2y = 0;
    let sectionMarks2y = 0;
    let sectionMarks3y = 0;
    let sectionMarks4y = 0;
    let sectionMarks5y = 0;
    let sectionMarks6y = 0;
    let sectionMarks7y = 0;
    let sectionMarks8y = 0;
    let sectionMarks9y = 0;
    let sectionMarks10y = 0;
    let sectionMarks11y = 0;
    let sectionMarks12y = 0;
    let sectionMarks13y = 0;
    let sectionMarks15y = 0;
    let previousMonth = 0;
    
    array.forEach((section) => {
      if (section.sectionheading === "3 Months") {
        questionArrayLength = section.questions.length;
        section.questions.forEach((question) => {
          //console.log(question.marks);
          sectionMarks3Month += parseFloat(question.marks) || 0;
        });
        //console.log("sectionMarks3Month", sectionMarks3Month);
        totalMarks =
          (1 / questionArrayLength) *
          sectionMarks3Month *
          (parseFloat(section.sectionheading.replace(" Months", "")) || 0);
        previousMonth = section.sectionheading.replace(" Months", "");
        //console.log("totalMarks 3 month", totalMarks);
      } else if (section.sectionheading === "6 Months") {
        questionArrayLength = section.questions.length;
        let previousMonthLess =
          section.sectionheading.replace(" Months", "") - previousMonth;
        //console.log("previousMonthLess", previousMonthLess);
        section.questions.forEach((question) => {
          sectionMarks6Month += parseFloat(question.marks) || 0;
        });
        
        totalMarks +=
          (1 / questionArrayLength) *
          sectionMarks6Month *
          (parseFloat(previousMonthLess) || 0);
        //console.log("6 Months totalMarks", totalMarks);
        previousMonth = section.sectionheading.replace(" Months", "");
      } else if (section.sectionheading === "9 Months") {
        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        let previousMonthLess =
          section.sectionheading.replace(" Months", "") - previousMonth;
        //console.log("previousMonthLess 9", previousMonthLess);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks9Month += parseFloat(question.marks) || 0;
        });
       
        totalMarks +=
          (1 / questionArrayLength) *
          sectionMarks9Month *
          (parseFloat(previousMonthLess) || 0);
        //console.log("9 Months totalMarks", totalMarks);

        previousMonth = section.sectionheading.replace(" Months", "");
      } else if (section.sectionheading === "1 Years") {
        //console.log("1 year call");
        //console.log(section.sectionheading.replace(" Years", ""));
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month", previousMonthLess, convertYearToMonth);
        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks1Y += parseFloat(question.marks) || 0;
        });
        //console.log("sectionMarks1Y", sectionMarks1Y, "totalMarks", totalMarks);
        totalMarks +=
          (1 / questionArrayLength) *
          sectionMarks1Y *
          (parseFloat(previousMonthLess) || 0);
        //console.log("1 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "1 - 1/2 Years") {
        let convertYearToMonth = 18;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month 1/2", previousMonthLess, convertYearToMonth);
        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          sectionMarks1_2y += parseFloat(question.marks) || 0;
        });
        // //console.log(
        //   "sectionMarks1_2y",
        //   sectionMarks1_2y,
        //   "totalMarks",
        //   totalMarks
        // );
        totalMarks +=
          (1 / questionArrayLength) *
          sectionMarks1_2y *
          (parseFloat(previousMonthLess) || 0);
        //console.log("1 - 1/2 Years  totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "2 Years") {
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);
        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks2y += parseFloat(question.marks) || 0;
        });
        //console.log("sectionMarks2y", sectionMarks2y, "totalMarks", totalMarks);
        totalMarks +=
          (1 / questionArrayLength) *
          sectionMarks2y *
          (parseFloat(previousMonthLess) || 0);
        //console.log("2 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "3 Years") {
        //console.log(section.sectionheading.replace(" Months", ""));
        questionArrayLength = section.questions.length;
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);

        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks3y += parseFloat(question.marks) || 0;
        });
        //console.log("sectionMarks3y", sectionMarks3y, "totalMarks", totalMarks);
        totalMarks +=
          (1 / questionArrayLength) *
          sectionMarks3y *
          (parseFloat(previousMonthLess) || 0);
        //console.log("3 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "4 Years") {
        questionArrayLength = section.questions.length;
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);

        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks4y += parseFloat(question.marks) || 0;
        });
      
        totalMarks +=
          (1 / questionArrayLength) *
            parseFloat(sectionMarks4y) *
            previousMonthLess || 0;
        //console.log("4 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "5 Years") {
        questionArrayLength = section.questions.length;
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);

        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks5y += parseFloat(question.marks) || 0;
        });
        
        totalMarks +=
          (1 / questionArrayLength) *
            parseFloat(sectionMarks5y) *
            previousMonthLess || 0;
        //console.log("5 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "6 Years") {
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);

        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks6y += parseFloat(question.marks) || 0;
        });
        
        totalMarks +=
          (1 / questionArrayLength) *
            parseFloat(sectionMarks6y) *
            previousMonthLess || 0;
        //console.log("6 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "7 Years") {
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);

        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks7y += parseFloat(question.marks) || 0;
        });
      
        totalMarks +=
          (1 / questionArrayLength) *
            parseFloat(sectionMarks7y) *
            previousMonthLess || 0;
        //console.log("7 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "8 Years") {
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);

        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks8y += parseFloat(question.marks) || 0;
        });
       
        totalMarks +=
          (1 / questionArrayLength) *
            parseFloat(sectionMarks8y) *
            previousMonthLess || 0;
        //console.log("8 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "9 Years") {
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);
        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks9y += parseFloat(question.marks) || 0;
        });
       
        totalMarks +=
          (1 / questionArrayLength) *
            parseFloat(sectionMarks9y) *
            previousMonthLess || 0;
        //console.log("9 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "10 Years") {
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);
        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks10y += parseFloat(question.marks) || 0;
        });
      
        totalMarks +=
          (1 / questionArrayLength) *
            parseFloat(sectionMarks10y) *
            previousMonthLess || 0;
        //console.log("10 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "11 Years") {
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);

        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks11y += parseFloat(question.marks) || 0;
        });
        
        totalMarks +=
          (1 / questionArrayLength) *
            parseFloat(sectionMarks11y) *
            previousMonthLess || 0;
        //console.log("11 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "12 Years") {
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);
        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks12y += parseFloat(question.marks) || 0;
        });
       
        totalMarks +=
          (1 / questionArrayLength) *
            parseFloat(sectionMarks12y) *
            previousMonthLess || 0;
        //console.log("12 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "13 Years") {
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);
        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks13y += parseFloat(question.marks) || 0;
        });
        
        totalMarks +=
          (1 / questionArrayLength) *
            parseFloat(sectionMarks13y) *
            previousMonthLess || 0;
        //console.log("13 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "15 Years") {
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);
        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks15y += parseFloat(question.marks) || 0;
        });
      
        totalMarks +=
          (1 / questionArrayLength) *
            parseFloat(sectionMarks15y) *
            previousMonthLess || 0;
        //console.log("15 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      }
    });
    //console.log("Total marks for All section : " + totalMarks.toFixed(2));
    setDamarks(totalMarks.toFixed(2))
    let DstScoreMarks = totalMarks.toFixed(2) * (100 / ageInMonths);
    //console.log("DstScoreMarks", DstScoreMarks);
    if (isNaN(DstScoreMarks)) {
      //console.log("if dst score nan");
      setDSTscore(0);
    }
    setDSTscore(DstScoreMarks.toFixed(2));
  };

  const fetchVSMSInfo = async () => {
    const result = await inventoryApi.fetchCaseHistoryResults(
      student.id,
      "VSMS"
    );
    countOfMarks(result);
    setVSMSInfo(result);
  };

  const countOfMarks = (fetchVSMSInfo) => {
    //console.log("vsms info", vsmsInfo);
    //console.log("student dob", student.dob);
    let age = null;
    let calculatedScore = 0;

    const dob = moment(student.dob, "YYYY-MM-DD");
    age = moment().diff(dob, "years");
    //console.log("age", age);
    setAge(age);
    age = moment().diff(dob, "years");
    let ageInMonths = ageDataValue * 12;
    //console.log("age", ageInMonths);
    let totalMarks = 0;
    const sumOfMarks = fetchVSMSInfo.filter((value) => {
      if (value.marks) totalMarks += parseFloat(value.marks);
    });
    //console.log("if call");
    if (typeof totalMarks === "number" && totalMarks !== 0) {
      let vsmsDataMarks = null;
      {
        vsmsData?.map((item) => {
          if (item.marks === totalMarks) {
            vsmsDataMarks = item.months;
          }
        });
      }
      if (vsmsDataMarks) {
        //console.log("VSMS IF CALL", vsmsDataMarks,  ageInMonths);
        calculatedScore = (parseFloat(vsmsDataMarks) / ageInMonths) * 100;
        //console.log("calculated socre");
        setscore(calculatedScore.toFixed(2));
      }
    } else {
      setscore(0);
    }
    //console.log("score ===", score);
    if (isNaN(score) || !isFinite(score)) {
      //console.log("is nan call");
      setscore(0);
    }
  };

  const gdtFetch = async () => {
    const qusResult = await inventoryApi.fetchCaseHistoryQuestions("GDT");
    const marksResult = await inventoryApi.fetchCaseHistoryResults(
      student.id,
      "GDT"
    );
    //console.log("marksResult", marksResult);
    if (qusResult) {
      const currentUrl = window.location.href;
      //console.log(currentUrl);
      for (const qusObj of qusResult) {
        const path = `/shapes_images/${qusObj.question}.png`;
        try {
          const response = await fetch(path);
          if (response.ok) {
            const blob = await response.blob();
            const dataURL = await new Promise((resolve) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.readAsDataURL(blob);
            });
            qusObj.path = dataURL.split(",")[1];
          } else {
            //console.log(`Failed to fetch image: ${response.status}`);
          }
        } catch (error) {
          //console.log(`Error loading image: ${error}`);
        }
        qusObj.marks = null;
        for (const result of marksResult) {
          if (result.casehistorytestid === qusObj.id) {
            qusObj.marks = result.marks;
          }
        }
      }
      setGDTInfo(qusResult);
    }
  };

  const fetchChildPhoto = async () => {
    try {
      const files = await inventoryApi.fetchStudentPhoto(student.id);
      //console.log("files:", files);
      if (files && files.length > 0) {
        const arr = [];

        const file = files[0];
        if (
          file.filetype === "jpg" ||
          file.filetype === "jpeg" ||
          file.filetype === "png"
        ) {
          const result = await inventoryApi.downloadFiles(file.id);

          const base64Data = await getDataUrl(result);
          //console.log("download filed ", base64Data);
          file["body"] = base64Data;
        } else {
          file["body"] = "";
        }
        arr.push(file);

        setBody([...arr]);
      } else {
        setBody([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDataUrl = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <div
      className="d-flex justify-content-center border"
      style={{ fontSize: "10px" }}
    >
      <table style={{ width: "100%" }}>
        <tr>
          <td style={{ border: "None", textAlign: "center" }}>
            <h5>{studentInfo["servicecategoryname"]}</h5>
            <h6>{studentInfo.location}</h6>
            <h6>Rajasthan </h6>
          </td>
        </tr>
      </table>
      <hr />

      <table style={{ width: "100%" }}>
        <tr>
          <td style={{ border: "None", textAlign: "center" }}>
            <h6>Case History Report </h6>
          </td>
        </tr>
      </table>
      <hr />

      {/* Student Detail  */}
      <table>
        <tr>
          <td style={{ width: "400px", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b>Student Information</b></h4>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table>

      {/* <table width="100%">
        <tr style={{ border: "none" }}>
          <td>
            <table>
              <thead>
                {header.map((item) => (
                  <tr key={item.prop} style={{ border: "none" }}>
                    <td>{item.title} </td>
                    {studentInfo ? (
                      <td> - {studentInfo[item.prop]}</td>
                    ) : (
                      <td>-</td>
                    )}
                  </tr>
                ))}
              </thead>
            </table>
          </td>
     

          
          <td style={{ border: "None", textAlign: "center" }}>
            {body.map((item, index) => (
              <div>
                {item.body && (
                  <img
                    src={item.body}
                    style={{ border: "5px solid #555", width: "200px" }}
                  />
                )}
              </div>
            ))}
          </td> 

        </tr>
      </table>*/}

      <table style={{ fontSize: "15px" }}>
        <tr>
          <td
            style={{ width: "50%", height: "30px", border: "1px solid gray" }}
          >
            Name : {studentInfo.name}{" "}
          </td>
          <td style={{ width: "50%", border: "1px solid gray" }} rowspan="4">
            {body.map((item, index) => (
              <div>
                {item.body && (
                  <img
                    src={item.body}
                    style={{
                      border: "5px solid #555",
                      width: "150px",
                      height: "150px",
                      marginLeft: 100,
                    }}
                  />
                )}
              </div>
            ))}
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>
            Father Name : {studentInfo.fathername}{" "}
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Phone : {studentInfo.phone}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Age : {studentInfo.age}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Adhar Card : {studentInfo.adharcard}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Problem : {studentInfo.problem}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Street : {studentInfo.street}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>City : {studentInfo.city}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>State : {studentInfo.state}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Country : {studentInfo.country}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Pincode : {studentInfo.pincode}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Phone : {studentInfo.phone}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Address : {studentInfo.address}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Gender : {studentInfo.gender}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Reffer By : {studentInfo.refferby}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Remark : {studentInfo.remark}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>DOB : {moment(studentInfo.dob).format("YYYY-MM-DD")}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Status : {studentInfo.status}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Mother Tongue : {studentInfo.mothertongue}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Udid : {studentInfo.udid}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Religion : {studentInfo.religion}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Category : {studentInfo.category}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Education : {studentInfo.education}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>School Name : {studentInfo.schoolname}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Local Address : {studentInfo.localaddress}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Mobile : {studentInfo.mobile}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Family Phone : {studentInfo.familyphone}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Area : {studentInfo.area}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Service Area : {studentInfo.servicearea}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Service Category : {studentInfo.servicecategoryname}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Dropout Reason : {studentInfo.dropoutreason}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Dropout Date : {moment(studentInfo.dropoutdate).format("YYYY-MM-DD")}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>
            Child Registration Date :{" "}
            {moment(studentInfo.Childregistrationdate).format("YYYY-MM-DD")}
          </td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Landmark : {studentInfo.landmark}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Ward : {studentInfo.ward}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Permanent Landmark : {studentInfo.permlandmark}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Permanent Ward : {studentInfo.permward}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Permanent Street : {studentInfo.permstreet}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Permanent City : {studentInfo.permcity}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Permstate : {studentInfo.permstate}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Permanent Country : {studentInfo.permcountry}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Permanent Pincode : {studentInfo.permpincode}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>User type : {studentInfo.usertype}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>User Role : {studentInfo.userrole}</td>
        </tr>

        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>
            {" "}
            Refer To Staff Name :
            {studentInfo?.refertostaff?.map((item, index) => (
              <span key={item.value}>
                {item.label}
                {index !== studentInfo.refertostaff.length - 1 ? ", " : ""}
              </span>
            ))}
          </td>

          <td style={{ border: "1px solid gray",height: "30px" }}>Refer to Staff Name : {studentInfo.referetostaffname}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Service Category Name : {studentInfo.servicecategoryname}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Createdbyname : {studentInfo.permstreet}</td>
        </tr>
      </table>

      {/* <table>
        <tr>
          <td style={{ width: "400px", border: "None" }}>
            <h5 style={{ fontSize: "15px" }}> Parent Information</h5>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table> */}

      <table style={{ fontSize: "15px" }}>
        <tr>
          <td style={{ width: "325px", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}>
              {" "}
              <b>Parent Information</b>
            </h4>
          </td>
          <td style={{ width: "350px", border: "None" }}></td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "40px" }}>
            Father Name : {ParentInfo.fathername}{" "}
          </td>
          <td style={{ border: "1px solid gray",height: "30px" }}>
            Father Education : {ParentInfo.fathereducation}
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Father Occupation : {ParentInfo.fatheroccupation}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Status : {studentInfo.phone}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Mother Name : {ParentInfo.mothername} </td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Mother Income : {ParentInfo.motherincome}</td>
        </tr>

        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Father Occupation : {ParentInfo.fatheroccupation}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Father Income : {ParentInfo.fatherincome}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Mother Occupation : {ParentInfo.phone}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Mother Education : {ParentInfo.mothereducation}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Family Income : {ParentInfo.fatherincome}</td>
        </tr>

        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Sociale Eonomic Status : {ParentInfo.socialeconomicstatus}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>BPL Holder : {ParentInfo.bplcardholder}</td>
        </tr>
      </table>

      {/* <table>
        <thead>

          {parentHeaders.map((item, index) =>

            <tr key={item.prop} style={{ border: "none" }}>
              <td>{item.title}</td>
            </tr>
          )}

        </thead>
      </table> */}

      <table style={{ fontSize: "15px" }}>
        <tr>
          <td style={{ width: "400px", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}><b>Personal Information</b> </h4>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table>

      <table style={{ fontSize: "15px",border: "1px solid gray",height: "30px"  }}>
        <tr>
          <td style={{ width: "50%" }}>
            Complations : {personalInfo.complations}{" "}
          </td>
          <td style={{ width: "53%",border: "1px solid gray",height: "30px"  }}>
            Respondedby : {personalInfo.respondedby}
          </td>
        </tr>
        <tr>
          <td style={{ width: "50%",border: "1px solid gray",height: "30px"  }}>
            Motherage Conception : {personalInfo.motherageconception}{" "}
          </td>
          <td style={{ width: "40%",border: "1px solid gray",height: "30px"  }}>
            Fatherage Conception : {personalInfo.fatherageconception}
          </td>
        </tr>
        <tr>
          <td style={{ width: "60%",border: "1px solid gray",height: "30px"  }}>
            Mother Nutrition Pregnancy : {personalInfo.mothernutritionpregnancy}{" "}
          </td>
          <td style={{ width: "40%",border: "1px solid gray",height: "30px"  }}>
            Fetal Movement : {personalInfo.fetalmovement}
          </td>
        </tr>
        <tr>
          <td style={{ width: "60%",border: "1px solid gray",height: "30px"  }}>
            Medicine Pregnancy : {personalInfo.medicinepregnancy}{" "}
          </td>
          <td style={{ width: "40%",border: "1px solid gray",height: "30px"  }}>
            Motherantental Checkup : {personalInfo.motherantentalcheckup}
          </td>
        </tr>
        <tr>
          <td style={{ width: "60%" ,border: "1px solid gray",height: "30px"  }}>Birth Cry : {personalInfo.birthcry} </td>
          <td style={{ width: "40%",border: "1px solid gray",height: "30px"  }}>
            Birth Weight : {personalInfo.birthweight}
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}> First Milk Given : {personalInfo.firstmilkgiven}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Delivery At : {personalInfo.deliveryat}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Cordaround Neck : {personalInfo.cordaroundneck} </td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Baby Color : {personalInfo.bobycolor}</td>
        </tr>

        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Delivery Breach : {personalInfo.deliverybreach}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Baby Active : {personalInfo.babyactive}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Baby Suffered Problem : {personalInfo.babysufferedproblem}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Baby Immunize : {personalInfo.babyimmunize}</td>
        </tr>

        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Family History : {personalInfo.familyhistory}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Neck Holding : {personalInfo.motherrecongnition}</td>
        </tr>

        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Mother Recongnition : {personalInfo.socialeconomicstatus}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Smile : {personalInfo.smile}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Sitting : {personalInfo.sitting}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Standing : {personalInfo.standing}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Walking : {personalInfo.walking}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Babbling : {personalInfo.babbling}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Firstward Phase : {personalInfo.firstwardphase}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Toward phase : {personalInfo.twowardphase}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Toilet Control : {personalInfo.toiletcontrol}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Imitate Sounds : {personalInfo.imitatesounds}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Vowel Consonant Sounds : {personalInfo.vowelconsonantsounds}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Exchange Sounds : {personalInfo.exchangesounds}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Pay Attention Name : {personalInfo.payattentionname}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Play Speekboo : {personalInfo.playspeekboo}</td>
        </tr>

        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Repetitive Babbling : {personalInfo.repetitivebabbling}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Gestures Words : {personalInfo.gestureswords}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Vocal Communication : {personalInfo.vocalcommunication}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Under Stand Names : {personalInfo.understandnames}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Respond Firmno : {personalInfo.respondfirmno}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Mimic Actions : {personalInfo.mimicactions}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>School History : {personalInfo.schoolhistory}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Physical Impairment : {personalInfo.physicalimpairment}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Behavioral Problems : {personalInfo.behavioralproblems}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Medical History : {ParentInfo.fatherincome}</td>
        </tr>
      </table>

      <table style={{ fontSize: "15px" }}>
        <tr>
          <td style={{ width: "330px", border: "None", }}>
            <h4 style={{ fontSize: "17px" }}> <b>Assessment Information</b></h4>
          </td>
          <td style={{border: "None" ,width: "350px"}}></td>
        </tr>
        <tr>
          <td style={{border: "1px solid gray",height: "30px" }}>Motor : {studentAssessment.motor} </td>
          <td style={{ border: "1px solid gray",height: "30px" }}>
            Self Help : {studentAssessment.selfhelp}
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Communication : {studentAssessment.communication}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Academics : {studentAssessment.academics}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Socialization: {studentAssessment.socialization} </td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Education Status : {studentAssessment.educationstatus}</td>
        </tr>

        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Vocational Status : {studentAssessment.vocationalstatus}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}>Primary Diagnosis : {studentAssessment.primarydiagnosis}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray",height: "30px" }}>Required Documents: {studentAssessment.requireddocuments}</td>
          <td style={{ border: "1px solid gray",height: "30px" }}></td>
          
        </tr>
      </table>

      {/* <table>
        <thead>
          {personalInfoHeader.map((item) => (
            <tr key={item.prop} style={{ border: "none" }}>
              <td>{item.title} </td>
              {personalInfo ? (
                <td> - {personalInfo[item.prop]}</td>
              ) : (
                <td> - </td>
              )}
            </tr>
          ))}
        </thead>
      </table> */}
      {/* Assessment Info  */}
      {/* <table>
        <tr>
          <td style={{ width: "400px", border: "None" }}>
            <h5 style={{ fontSize: "15px" }}> Assessment Information</h5>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table> */}

      {/* <table>
        <thead>
          {AssessmentHeaders.map((item) => (
            <tr key={item.prop} style={{ border: "none" }}>
              <td>{item.title} </td>
              {personalInfo.length > 0 ? (
                <td> - {studentAssessment[item.prop]}</td>
              ) : (
                <td>-</td>
              )}
            </tr>
          ))}
        </thead>
      </table> */}

      <table>
        <tr>
          <td style={{ width: "400px", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b>DST Information</b> </h4>
          </td>
          <td style={{ width: "100px", border: "None",backgroundColor: "#DFE0E1" }}>
            DA  : {damarks}%
          </td>
          <td style={{ width: "100px", border: "None",backgroundColor: "#DFE0E1" }}>
            Total Score : {dstScore}%
          </td>
        </tr>
      </table>

      <table class="table table-bordered" style={{ fontSize: "15px" }}>
        <thead>
          <tr>
            <td style={{ width: "10%", backgroundColor: "#DFE0E1",border: "1px solid gray",height: "30px"}}>
              <b>No.</b>
            </td>
            <td style={{ width: "80%", backgroundColor: "#DFE0E1",border: "1px solid gray",height: "30px" }}>
              <b>Question</b>
            </td>
            <td style={{ width: "10%", backgroundColor: "#DFE0E1",border: "1px solid gray",height: "30px" }}>
              <b>Marks</b>
            </td>
          </tr>
        </thead>
        <tbody>
          {dstInfo?.map((item, index) => (
            <div>
              <tr key={index}>
                <td style={{ border: "1px solid gray",height: "30px" }}>{index + 1}</td>

                <td style={{ border: "1px solid gray",height: "30px" }}>{item.question}</td>
                <td style={{ border: "1px solid gray",height: "30px" }}>{item.marks}</td>
              </tr>
            </div>
          ))}
        </tbody>
      </table>

      <table>
        <tr>
          <td style={{ width: "400px", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}><b> VSMS Information </b></h4>
          </td>
          <td style={{ width: "100px", border: "None",backgroundColor : '#DFE0E1' }}>
            Total Score : {score}%
          </td>
        </tr>
      </table>

      <table class="table table-bordered" style={{ fontSize: "15px" }}>
        <thead>
          <tr>
            <td style={{ width: "10%", backgroundColor: "#DFE0E1",border: "1px solid gray",height: "30px" }}>
              <b>No.</b>
            </td>
            <td style={{ width: "80%", backgroundColor: "#DFE0E1",border: "1px solid gray",height: "30px" }}>
              <b>Question</b>
            </td>
            <td style={{ width: "10%", backgroundColor: "#DFE0E1",border: "1px solid gray",height: "30px" }}>
              <b>Marks</b>
            </td>
          </tr>
        </thead>
        <tbody>
          {vsmsInfo?.map((item, index) => (
            <div>
              <tr key={index}>
                <td style={{ border: "1px solid gray",height: "30px" }}>{index + 1}</td>
                <td style={{ border: "1px solid gray",height: "30px" }}>{item.question}</td>
                <td style={{ border: "1px solid gray",height: "30px" }}>{item.marks}</td>
              </tr>
            </div>
          ))}
        </tbody>
      </table>

      <table>
        <tr>
          <td style={{ width: "400px", border: "None" }}>
            <h5 style={{ fontSize: "15px" }}> GDT Information</h5>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table>

      <table class="table table-bordered">
        <thead>
          <tr>
            <td style={{ width: "10%", backgroundColor: "#DFE0E1",border: "1px solid gray",height: "30px" }}>
              <b>No.</b>
            </td>
            <td style={{ width: "80%", backgroundColor: "#DFE0E1",border: "1px solid gray",height: "30px" }}>
              <b>Question</b>
            </td>
            <td style={{ width: "10%", backgroundColor: "#DFE0E1",border: "1px solid gray",height: "30px" }}>
              <b>Marks</b>
            </td>
          </tr>
        </thead>
        <tbody>
          {gdtInfo?.map((item, index) => (
            <tr key={index}>
              <td style={{ border: "1px solid gray",height: "30px" }}>{index + 1}</td>
              <td style={{ textAlign: "center",border: "1px solid gray",height: "30px" }}>
                <img
                  style={{ width: "40px", height: "30px" }}
                  src={`data:image/png;base64,${item.path}`}
                />
              </td>
              <td style={{ border: "1px solid gray",height: "30px" }}>{item.marks}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CaseHistory;
