import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import inventoryApi from "../api/inventoryApi";
import { useNavigate } from "react-router-dom";

import Form from "react-bootstrap/Form";
import * as constants from "../constants/CONSTANT";
import fileDownload from 'js-file-download';
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import axios from "axios";

const QuestionList = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
 
  const [filterData, setFilterData] = useState([]);
  

  let [filterObj, setFilterObj] = useState({});

  useEffect(() => {

    async function init() {
      const result = await inventoryApi.fetchQuestions();
      if (result) {
        let tempBody = result.map((data, index) => ({
          ...data,
          index: index + 1,
        }));
        setBody(tempBody);
        setFilterData(tempBody);
       
      } else {
        setBody([]);
        setFilterData([]);
      }
      setFilterObj({...filterObj, ['assessment'] : 'FACP'});

     
    }

    init();
  }, []);


  useEffect(() => {
    doFilterData();
  }, [filterObj]);

  const editQuestion = (row) => {
    //console.log("row", row);
    navigate(`/questions/${row.row.id}/e`, { state: row });
  };

  const viewPdf = async (row) => {
    const result = await inventoryApi.downloadFiles(row.row.fileid);
    console.log('result:', row.row.filetitle);
    if(result){
      fileDownload(result, decodeURIComponent(row.row.filetitle));
    }
  };

 const onFilterSelection = (event) => {
    setFilterObj({...filterObj, [event.target.name] : event.target.value});

 }

 const doFilterData = (event) => {
  
  let tempData = body.filter((item) => {
    //console.log(item, filterObj);
    if(!filterObj.assessment || filterObj.assessment === '' || item.assessment === filterObj.assessment){
        return item;
    }
  });
  
  tempData = tempData.filter((item) =>{
    if(!filterObj.category || filterObj.category === '' || item.category === filterObj.category){
        return item;
    }
  });

  tempData = tempData.filter((item) =>{
    if(!filterObj.section || filterObj.section === '' || item.section === filterObj.section){
        return item;
    }
  });

  setFilterData(tempData);
  

 }

 

  const header = [
    { title: "Questions", prop: "question", isFilterable: true },
    {
      title: "PDF",
      prop: "id",
      cell: (row) => (
        <Button
          className="btn-sm"
          variant="link"
          onClick={() => viewPdf({ row })}
        >
          View PDF
        </Button>
      ),
    },
    {
      title: "Actions",
      prop: "id",
      cell: (row) => (
        <Button className="btn-sm mx-2" onClick={() => editQuestion({ row })}>
          <i className="fa-regular fa-pen-to-square"></i>
        </Button>
      ),
    },
  ];

  const labels = {
    beforeSelect: " ",
  };

  const addQuestions = () => {
    navigate(`/questions/e`);
  };

  return (
    <>
      <Row className="g-0">
        <Col lg={5} className="mx-2">
          <Link className="nav-link">
            Assesments <i className="fa-solid fa-chevron-right"></i> Show All
            Question
          </Link>
        </Col>
        <Col lg={10} className="m-lg-4">
          {filterData ? (
            <DatatableWrapper
              body={filterData}
              headers={header}
              rowIndexColumn
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 20,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              <Row className="mb-4">
                <Col
                  xs={12}
                  lg={2}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Filter />
                </Col>
                <Col
                  lg={2}
                  className="d-flex flex-col justify-content-start align-items-start"
                >
                  <PaginationOptions labels={labels} />
                </Col>

                {/* <Col
                  xs={12}
                  lg={2}
                  className="d-flex flex-col justify-content-start align-items-start"
                  style={{ paddingTop: "24px" }}
                >
                  <Form.Select
                    aria-label="Enter Select option"
                    name="assessment"
                    onChange={onFilterSelection}
                  >
                    <option value=''>--Select --</option>
                    <option value="FACP">FACP</option>
                    
                  </Form.Select>
                </Col> */}
                <Col
                  xs={12}
                  lg={2}
                  className="d-flex flex-col justify-content-start align-items-start"
                  style={{ paddingTop: "24px" }}
                >
                  <Form.Select
                    
                    name="category"
                    onChange={onFilterSelection}
                  >
                    <option value=''>--Select --</option>
                    <option value="Pre-Primary">Pre-Primary</option>
                    <option value="Primary 1st">Primary 1st</option>
                    <option value="Primary 2nd">Primary 2nd</option>
                    <option value="Secondary">Secondary</option>
                    <option value="Pre-Vocational 1st">
                      Pre-Vocational 1st
                    </option>
                    <option value="Pre-Vocational 2nd">
                      Pre-Vocational 2nd
                    </option>
                    <option value="Care Group">Care Group</option>
                  </Form.Select>
                </Col>
                <Col
                  xs={12}
                  lg={2}
                  className="d-flex flex-col justify-content-start align-items-start"
                  style={{ paddingTop: "24px" }}
                >
                  <Form.Select
                    aria-label="Enter Select option"
                    name="section"
                    onChange={onFilterSelection}
                  >
                    <option value=''>--Select --</option>
                    <option value="Personal">Personal</option>
                    <option value="Social">Social</option>
                    <option value="Academy">Academy</option>
                    <option value="Vocational">Vocational</option>
                    <option value="Recreational">Recreational</option>
                  </Form.Select>
                </Col>

                <Col
                  xs={12}
                  sm={6}
                  lg={2}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Button
                    className="btn-sm"
                    variant="outline-primary"
                    onClick={() => addQuestions(true)}
                  >
                    {" "}
                    Create Questions
                  </Button>
                </Col>
              </Row>

              <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            ""
          )}
        </Col>
        <Col lg={2}></Col>
      </Row>
    </>
  );
};

export default QuestionList;