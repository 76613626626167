import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PubSub from 'pubsub-js';
import inventoryApi from "../api/inventoryApi";


const PhysioAssessment = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [studentData, setStudentData] = useState(location.state ? location.state : {});
    const [psyoAssessment, setPsyoAssessment] = useState({});

    const handleChange = (e) => {
        setPsyoAssessment({ ...psyoAssessment, [e.target.name]: e.target.value });
    };



    useEffect(() => {
        if (studentData?.studentid) {
            setPsyoAssessment(studentData)
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('PhysioAssessment Json >>>', psyoAssessment);
        let result = {};
        if (psyoAssessment.id) {
            console.log('save call', psyoAssessment)
            result = await inventoryApi.savePhysioAssess(psyoAssessment);
            console.log('save psyo result', result);
            PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record update successfully' });
            navigate(`/students/${psyoAssessment.studentid}`, {
                state: psyoAssessment,
            });

        } else {
            delete psyoAssessment.success;
            delete psyoAssessment.message;
            psyoAssessment.studentid = studentData.id;
            console.log('psyoAssessment else call', psyoAssessment);
            result = await inventoryApi.createPhysioAssess(psyoAssessment);
            console.log('create physio result', result)
            PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record Create successfully' });
            if (result) {
                navigate(`/students/${result.studentid}`, { state: result });
            }
        }
    };

    const handleCancel = async() => {
        console.log('PhysioAssessment',PhysioAssessment);
        if (PhysioAssessment) {
            console.log('if call',PhysioAssessment)
            navigate(`/students/${PhysioAssessment.studentid}`, { state: PhysioAssessment});
          }else{
            console.log('else',studentData)
            navigate(`/students/${studentData.id}`, { state: studentData });
          }
    }


    return (
        <>
            <Container id="one">
                <Row className="view-form-header align-items-center mx-2 aslamBari">
                    <Col lg={6}>
                        <h4>Physiotherapy Assessment</h4>
                    </Col>
                    <Col lg={6} className="d-flex justify-content-end">
                        <Button className="btn-sm mx-2" onClick={handleSubmit}>Save</Button>
                        <Button className="btn-sm" variant="danger" onClick={handleCancel}>
                            Cancel
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col lg={6}>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">Posture</Form.Label>
                            <Form.Select
                                // required
                                name="posture"
                                value={psyoAssessment.posture}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Lying">Lying</option>
                                <option value="Sitting">Sitting</option>
                                <option value="Standing">Standing</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    {/* <Col lg={6}>
                        <Form.Group className="mx-3 mt-2" controlId="dfdgo">
                            <Form.Label className="form-view-label form-label">
                                Abnormal Gait
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                name="abngait"
                                value={psyoAssessment.abngait}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col> */}
                </Row>

                <Row>
                    <Col lg={6}>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">Gait</Form.Label>
                            <Form.Select
                                // required
                                name="gait"
                                value={psyoAssessment.gait}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Stance phase">Stance phase</option>
                                <option value="Swing phase">Swing phase</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label"> Gait Direction </Form.Label>
                            <Form.Select
                                // required
                                name="gaitdirection"
                                value={psyoAssessment.gaitdirection}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Right">Right</option>
                                <option value="Left">Left</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group className="mx-3 mt-2" controlId="dfdgo">
                            <Form.Label className="form-view-label form-label">
                                Abnormal Gait
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                name="abngait"
                                value={psyoAssessment.abngait}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group className="mx-3 mt-2" controlId="dfdgo">
                            <Form.Label className="form-view-label form-label">
                                Walking Aid Used
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                name="walkingaid"
                                value={psyoAssessment.walkingaid}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="dfdgo">
                            <Form.Label className="form-view-label form-label">
                                Muscle Tone
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                name="muscletone"
                                value={psyoAssessment.muscletone}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">
                                Deep Tendon Reflex
                            </Form.Label>
                            <Form.Select
                                // required
                                name="deeptendonreflex"
                                value={psyoAssessment.deeptendonreflex}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Biceps">Biceps </option>
                                <option value="Triceps">Triceps </option>
                                <option value="Knee jerk">Knee jerk </option>
                                <option value="TA jerk">TA jerk </option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-3 mt-2" controlId="dfdgo">
                            <Form.Label className="form-view-label form-label">
                                Involuntary Movement
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                name="involuntarymovement"
                                value={psyoAssessment.involuntarymovement}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>



                <Row>
                    <Col lg={6}>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">
                                Postural balance
                            </Form.Label>
                            <Form.Select
                                // required
                                name="posturalbalance"
                                value={psyoAssessment.posturalbalance}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="One Leg Stance">One Leg Stance</option>
                                <option value="Heel to toe Stand">Heel to toe Stand</option>


                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">
                                Postural balance direction
                            </Form.Label>
                            <Form.Select
                                // required
                                name="posturalbalancedirection"
                                value={psyoAssessment.posturalbalancedirection}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Right">Right</option>
                                <option value="Left">Left</option>

                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                <Col lg={12} className="section-header my-3">
                    Joint Rom and Muscle Strength (Upper Extremity)
                </Col>

                <Row>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">Shoulder</Form.Label>
                            <Form.Select
                                // required
                                name="upperextremityshoulder"
                                value={psyoAssessment.upperextremityshoulder}
                                onChange={handleChange}
                            >
                                <option value="Select">--Select--</option>
                                <option value="F">F </option>
                                <option value="E">E </option>
                                <option value="Ab">Ab </option>
                                <option value="Ad">Ad </option>
                                <option value="Ir">Ir </option>
                                <option value="Er">Er</option>

                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">M.M.T (grade)</Form.Label>
                            <Form.Select
                                // required
                                name="upperextremityshouldermmt"
                                value={psyoAssessment.upperextremityshouldermmt}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Right">Right</option>
                                <option value="Left">Left</option>

                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">Arom/Prom</Form.Label>
                            <Form.Select
                                // required
                                name="upperextremityshoulderap"
                                value={psyoAssessment.upperextremityshoulderap}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Right">Right</option>
                                <option value="Left">Left</option>

                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">Elbow</Form.Label>
                            <Form.Select
                                // required
                                name="upperextremityelbow"
                                value={psyoAssessment.upperextremityelbow}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="F/su.">F/su. </option>
                                <option value="E/pro.">E/pro. </option>

                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">M.M.T (grade)</Form.Label>
                            <Form.Select
                                // required
                                name="upperextremityelbowmmt"
                                value={psyoAssessment.upperextremityelbowmmt}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Right">Right</option>
                                <option value="Left">Left</option>

                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">Arom/Prom</Form.Label>
                            <Form.Select
                                // required
                                name="upperextremityelbowap"
                                value={psyoAssessment.upperextremityelbowap}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Right">Right</option>
                                <option value="Left">Left</option>

                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">Wrist</Form.Label>
                            <Form.Select
                                // required
                                name="upperextremitywrist"
                                value={psyoAssessment.upperextremitywrist}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="F">F </option>
                                <option value="E">E </option>
                                <option value="Ab">Ab </option>
                                <option value="Ad">Ad </option>

                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">M.M.T (grade)</Form.Label>
                            <Form.Select
                                // required
                                name="upperextremitywristmmt"
                                value={psyoAssessment.upperextremitywristmmt}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Right">Right</option>
                                <option value="Left">Left</option>

                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">Arom/Prom</Form.Label>
                            <Form.Select
                                // required
                                name="upperextremitywristap"
                                value={psyoAssessment.upperextremitywristap}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Right">Right</option>
                                <option value="Left">Left</option>

                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                <Col lg={12} className="section-header my-3">
                    Lower Extremity
                </Col>
                <Row>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">Hip</Form.Label>
                            <Form.Select
                                // required
                                name="upperextremityhip"
                                value={psyoAssessment.upperextremityhip}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="F">F </option>
                                <option value="E">E </option>
                                <option value="Ab">Ab </option>
                                <option value="Ad">Ad </option>
                                <option value="Ir">Ir </option>
                                <option value="Fr">Er</option>

                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">M.M.T (grade)</Form.Label>
                            <Form.Select
                                // required
                                name="upperextremityhipmmt"
                                value={psyoAssessment.upperextremityhipmmt}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Right">Right</option>
                                <option value="Left">Left</option>

                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">Arom/Prom</Form.Label>
                            <Form.Select
                                // required
                                name="upperextremityhipap"
                                value={psyoAssessment.upperextremityhipap}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Right">Right</option>
                                <option value="Left">Left</option>

                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">Knee</Form.Label>
                            <Form.Select
                                // required
                                name="upperextremityknee"
                                value={psyoAssessment.upperextremityknee}
                                onChange={handleChange}
                            >
                                <option value="Select">--Select--</option>
                                <option value="F">F </option>
                                <option value="E">E </option>

                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">M.M.T (grade)</Form.Label>
                            <Form.Select
                                // required
                                name="upperextremitykneemmt"
                                value={psyoAssessment.upperextremitykneemmt}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Right">Right</option>
                                <option value="Left">Left</option>

                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">Arom/Prom</Form.Label>
                            <Form.Select
                                // required
                                name="upperextremitykneeap"
                                value={psyoAssessment.upperextremitykneeap}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Right">Right</option>
                                <option value="Left">Left</option>
                                {/* <option value="Female">Sitting</option>
              <option value="Others">Standing</option> */}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">Ankle</Form.Label>
                            <Form.Select
                                // required
                                name="upperextremityankle"
                                value={psyoAssessment.upperextremityankle}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Df./In">Df./In. </option>
                                <option value="Pf./Ev">Pf./Ev. </option>
                                {/* <option value="Female">Sitting</option>
              <option value="Others">Standing</option> */}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">M.M.T (grade)</Form.Label>
                            <Form.Select
                                // required
                                name="upperextremityanklemmt"
                                value={psyoAssessment.upperextremityanklemmt}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Right">Right</option>
                                <option value="Left">Left</option>
                                {/* <option value="Female">Sitting</option>
              <option value="Others">Standing</option> */}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">Arom/Prom</Form.Label>
                            <Form.Select
                                // required
                                name="upperextremityankleap"
                                value={psyoAssessment.upperextremityankleap}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Right">Right</option>
                                <option value="Left">Left</option>
                                {/* <option value="Female">Sitting</option>
              <option value="Others">Standing</option> */}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">
                                Musculoskeletal Deformity
                            </Form.Label>
                            <Form.Select
                                // required
                                name="musculoskeletaldeformity"
                                value={psyoAssessment.musculoskeletaldeformity}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Shoulder">Shoulder</option>
                                <option value="Elbow">Elbow</option>
                                <option value="Wrist">Wrist</option>
                                <option value="Hip">Hip</option>
                                <option value="Knee">Knee</option>
                                <option value="Ancle">Ancle</option>
                                <option value="Spinal">Spinal</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">
                                Developmental Reflexes
                            </Form.Label>
                            <Form.Select
                                // required
                                name="developmentalreflexes"
                                value={psyoAssessment.developmentalreflexes}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Primitive reflexes">Primitive reflexes</option>
                                <option value="ATNR/STNR">ATNR/STNR</option>
                                <option value="Protective reactions">
                                    Protective reactions
                                </option>
                                <option value="Babinski sign">Babinski sign</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                <Col lg={12} className="section-header my-3">
                    Gross motor functions (current condition)
                </Col>
                <Row>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">Last GMF</Form.Label>
                            <Form.Select
                                // required
                                name="lastgmf"
                                value={psyoAssessment.lastgmf}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Sitting">Sitting</option>
                                <option value="Standing">Standing</option>
                                <option value="Standing on one leg">Standing on one leg</option>
                                <option value="Walking">Walking</option>
                                <option value="Running">Running</option>
                                <option value="Happing">Happing</option>
                                <option value="Climbing up">Climbing up</option>
                                <option value="Climbing down">Climbing down</option>
                                <option value="Throwing">Throwing</option>
                                <option value="Catching">Catching </option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">Initial GMF</Form.Label>
                            <Form.Select
                                // required
                                name="initialgmf"
                                value={psyoAssessment.initialgmf}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Supine">Supine</option>
                                <option value="Prone">Prone</option>
                                <option value="Coming to sitting">Coming to sitting</option>
                                <option value="Rolling">Rolling</option>
                                <option value="Crawling">Crawling</option>
                                <option value="Kneeling">Kneeling</option>
                                <option value="Kneel standing">Kneel standing</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                <Col lg={12} className="section-header my-3">
                    Fine motor functions
                </Col>
                <Row>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">Grip</Form.Label>
                            <Form.Select
                                // required
                                name="fmfgrip"
                                value={psyoAssessment.fmfgrip}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Cylindrical">Cylindrical</option>
                                <option value="Spherical">Spherical</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">Prehension</Form.Label>
                            <Form.Select
                                // required
                                name="fmfprehension"
                                value={psyoAssessment.fmfprehension}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Tip to pad">Tip to pad</option>
                                <option value="Pad to pad">Pad to pad</option>
                                <option value="Tip to tip">Tip to tip</option>
                                <option value="Hook">Hook</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                <Col lg={12} className="section-header my-3">
                    Sensory Assessment
                </Col>
                <Row>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">
                                Superficial sensation
                            </Form.Label>
                            <Form.Select
                                // required
                                name="superficialsensation"
                                value={psyoAssessment.superficialsensation}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Pain">Pain</option>
                                <option value="Touch">Touch</option>
                                <option value="Temperature">Temperature</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">
                                Deep sensation
                            </Form.Label>
                            <Form.Select
                                // required
                                name="deepsensation"
                                value={psyoAssessment.deepsensation}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Joint position sense">
                                    Joint position sense
                                </option>
                                <option value="Vibration sense">Vibration sense</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mx-3 mt-2" controlId="formBasicGenderType">
                            <Form.Label className="form-view-label">
                                Coritical sensation
                            </Form.Label>
                            <Form.Select
                                // required
                                name="corticalsensation"
                                value={psyoAssessment.corticalsensation}
                                onChange={handleChange}
                            >
                                <option>--Select--</option>
                                <option value="Two point discrimination">
                                    Two point discrimination
                                </option>
                                <option value="Stereognosis">Stereognosis</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default PhysioAssessment;